import React, { useState, useEffect } from 'react';
import './ConfirmationModal.css';

const ConfirmationModalv1 = ({ isOpen, onClose, onConfirm, isClosing, text = '' }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle opening and closing animation when the modal is opened or closed
    useEffect(() => {
        if (isOpen) {
            setIsModalOpen(true);
        } else if (!isOpen && isModalOpen) {
            setIsModalOpen(false); // Delay closing to allow the closing animation
            // setTimeout(() => {
            //     setIsModalOpen(false); // Delay closing to allow the closing animation
            // }, 300); // Match the duration of the closing animation
        }
    }, [isOpen, isModalOpen]);

    if (!isOpen && !isModalOpen) return null;

    return (
        <div className={`modal-overlay ${isClosing ? 'closing' : ''}`}>
            <div className={`modal-content ${isClosing ? 'closing' : ''}`}>
                <h2>Terve ?</h2>
                <p>{text}</p>
                <div className="modal-actions">
                    <button className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button className="confirm-btn" onClick={onConfirm}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModalv1;
