// CalibrationData.js

const calibrationData = {
        "-1": {
            "125": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "250": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-24.5"
                }
            ],
            "500": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-22"
                }
            ],
            "750": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-28"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-28"
                }
            ],
            "1000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-26"
                }
            ],
            "1500": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "2000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-23"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-24"
                }
            ],
            "3000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-22"
                }
            ],
            "4000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-26"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-26"
                }
            ],
            "5000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "6000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-29"
                }
            ],
            "8000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-25"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-25"
                }
            ],
            "10000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "12000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "14000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "16000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ]
        },
        "1": {
            "125": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-20"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-20"
                }
            ],
            "250": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-25.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-25.5"
                }
            ],
            "500": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-27"
                }
            ],
            "750": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-27"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-27"
                }
            ],
            "1000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-26.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-26.5"
                }
            ],
            "1500": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "2000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-24"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-24"
                }
            ],
            "3000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-22"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-22"
                }
            ],
            "4000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-28.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-28.5"
                }
            ],
            "5000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "6000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-29"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-29"
                }
            ],
            "8000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-24.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-24.5"
                }
            ],
            "10000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "12000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "14000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ],
            "16000": [
                {
                    "dbLevel": "-10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "-5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "0",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "5",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "10",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "15",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "20",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "25",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "30",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "35",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "40",
                    "calibrationFactor": "21"
                },
                {
                    "dbLevel": "45",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "50",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "55",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "60",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "65",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "70",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "75",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "80",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "85",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "90",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "95",
                    "calibrationFactor": "-19.5"
                },
                {
                    "dbLevel": "100",
                    "calibrationFactor": "-19.5"
                }
            ]
        }
    }

export default calibrationData;