import React, { useState, useEffect } from 'react';
import './ModalWrapper.css';

const ModalWrapper = ({ isOpen, isClosing, children, className = ``, invokeOutSideProp = { close: false }, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle opening and closing animation when the modal is opened or closed
    useEffect(() => {
        if (isOpen) {
            setIsModalOpen(true);
        } else if (!isOpen && isModalOpen) {
            setIsModalOpen(false); // Delay closing to allow the closing animation
        }
    }, [isOpen, isModalOpen]);


    const handleOutsideClick = (e) => {
        // Check if the user clicked outside of the modal content
        if (invokeOutSideProp.close && e.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };

    if (!isOpen && !isModalOpen) return null;

    return (
        <div className={`modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${isClosing ? 'closing' : ''}`}  onClick={handleOutsideClick}>
            <div className={`bg-white rounded-lg shadow-lg max-w-xl min-w-[28%] w-full p-6 modal-content ${isClosing ? 'closing' : ''}`}  onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>

    );
};

export default ModalWrapper;
