import React, { useState, useEffect, useRef } from 'react';
import { GirlWithHeadPhoneIcon, GirlWithHeadPhoneIconLeftEar, GirlWithHeadPhoneIconRightEar } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import KuulokkeidenHeader from './KuulokkeidenHeader';
import KuulokkeidenInstruction from './KuulokkeidenInstruction';
import KuulokkeidenCompletionPage from './KuulokkeidenCompletionPage';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { audioObject } from '../../../Functions/AudioClass';
import { lists } from '../DIN/DINData';
import isElectron from '../../../Functions/isElectron';
import ConfirmationModalv1 from '../../Modals/ConfirmationModal/ConfirmationModalv1';
import FullPageLoader from '../../../utils/Loader/FullPageLoader';

const KuulokkeidenTestaaminen = () => {



    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [decibelIndex, setDecibelIndex] = useState(0);
    const decibelIndexRef = useRef(0);
    const currentIndexRef = useRef(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [kuulokkeidenCompleted, setKuulokkeidenCompleted] = useState(false);
    console.log({ currentIndex, decibelIndex });


    const audioContextRef = useRef(null);
    const digitBuffersRef = useRef([]);
    const leftDigitGainNodeRef = useRef(null); // to control left channel noise
    const rightDigitGainNodeRef = useRef(null); // to control right channel noise
    const [currentChannel, setCurrentChannel] = useState(null);
    const currentChannelRef = useRef(null);

    const digitSourceRef = useRef(null);
    const [currentTripletIndex, setCurrentTripletIndex] = useState(0);
    const [isTestRunning, setIsTestRunning] = useState(false);
    const isTestRunningRef = useRef(false);
    let selectedList = useSelector((state) => state.dinState.numberSeriesSetting);
    const [isPlaying, setIsPlaying] = useState(false); //for controlling the animation
    const [showModal, setShowModal] = useState(false); // State for controlling the modal
    const [isClosing, setIsClosing] = useState(false); // State to handle closing animation
    const confirmModalTextRef = useRef('You Are Using True Hear Browser Version');

    const settingRef = useRef({
        headPhoneLeftEarSetting: useSelector((state) => state.dinState.headPhoneLeftEarSetting),
        headPhoneRightEarSetting: useSelector((state) => state.dinState.headPhoneRightEarSetting),
        speakerLeftEarSetting: useSelector((state) => state.dinState.speakerLeftEarSetting),
        speakerRightEarSetting: useSelector((state) => state.dinState.speakerRightEarSetting),
        leftEarCalibrationSetting: useSelector((state) => state.dinState.leftEarCalibrationSetting),
        rightEarCalibrationSetting: useSelector((state) => state.dinState.rightEarCalibrationSetting),
        soundSourceSetting: useSelector((state) => state.dinState.soundSourceSetting)
    });

    /* calibration Configuration */

    let headPhoneLeftEarSetting = useSelector((state) => state.dinState.headPhoneLeftEarSetting);
    let headPhoneRightEarSetting = useSelector((state) => state.dinState.headPhoneRightEarSetting);
    let speakerLeftEarSetting = useSelector((state) => state.dinState.speakerLeftEarSetting);
    let speakerRightEarSetting = useSelector((state) => state.dinState.speakerRightEarSetting);
    let soundSourceSetting = useSelector((state) => state.dinState.soundSourceSetting);

    let leftCalibrationValue = soundSourceSetting === 'headPhone' ? headPhoneLeftEarSetting : speakerLeftEarSetting;
    let rightCalibrationValue = soundSourceSetting === 'headPhone' ? headPhoneRightEarSetting : speakerRightEarSetting;


    console.log({ setting: settingRef.current, digitBuffers: digitBuffersRef.current });
    /* Digits Inital Phase */
    const [isTestLoaded, setIsTestLoaded] = useState(false);


    /* Functions to handle modal smooth closing */
    const handleModalClose = () => {
        setIsClosing(true);  // Start the closing animation
        setTimeout(() => {
            setIsClosing(false);
            setShowModal(false); // Actually close the modal after the animation
            // startTest('left', 0);
        }, 300); // 300ms matches the animation duration
    };

    const handleConfirm = () => {
        setIsClosing(true);  // Start the closing animation
        setTimeout(() => {
            setIsClosing(false);
            setShowModal(false);  // Close the modal
            // Perform your confirm action here
            console.log('Confirmed action');
            // startTest('left', 0);

        }, 300);  // 300ms delay for smooth transition
    };


    /* Function Handle Next */
    const handle_Seuraava = async (e) => {
        if (currentIndex < uiData.length - 1) {
            setIsLoaded(false); // Reset animation state for the next UI
            /* Update the states */
            const newcurrentIndex = currentIndex + 1;
            setCurrentIndex(newcurrentIndex);
            currentIndexRef.current = newcurrentIndex;
            /* Check if the test is already running */
            if (isTestRunning === true)
                return toast.info("Test is already running");
            startTest(uiData[newcurrentIndex].channel, 0, 0);
        } else {
            // Mark the test as completed
            setKuulokkeidenCompleted(true);
        }
    };
    /* Handle function back */
    const handle_Takaisin = async (e) => {
        if (currentIndex > 0) {
            const newcurrentIndex = currentIndex - 1;
            setCurrentIndex(newcurrentIndex);
            currentIndexRef.current = newcurrentIndex;
        } else {
            navigate('/TestLandingPage');
        }
    };

    const handle_Enkuule = async (e) => {
        try {
            // console.log('Could not hear');
            toast.info('Could not hear increasing the DB..');
            const newDecibelIndex = decibelIndex + 1;
            setDecibelIndex(newDecibelIndex);
            decibelIndexRef.current = newDecibelIndex;
            if (newDecibelIndex > 3)
                return handleKylläKuule();
            playTriplet(currentTripletIndex, uiData[currentIndexRef.current].channel, newDecibelIndex);
            // playTriplet(0, uiData[currentIndex].channel,);
        } catch (error) {
            console.log(`[-] Error handle_enkuule`, error.message);
            return toast.error("Something went wrong....");
        }
    };

    /* handle hear */
    const handleKylläKuule = (e) => {
        try {
            // if (isTestLoaded === false)
            //     return toast.info('test is not loaded');
            if (currentIndex < uiData.length - 1) {
                setIsLoaded(false); // Reset animation state for the next UI
                /* Update the states */
                const newcurrentIndex = currentIndex + 1;
                setCurrentIndex(newcurrentIndex);
                currentIndexRef.current = newcurrentIndex;

                /* Check if the test is already running */
                startTest(uiData[newcurrentIndex].channel, 0, 0);
            } else {
                // Mark the test as completed
                setKuulokkeidenCompleted(true);
                setIsTestRunning(false);
                setIsPlaying(false);
                isTestRunningRef.current = false;
                pauseDigit();
            }
        } catch (error) {
            return toast.error(error.message);
        }
        // navigate('/Kuulokkeidenright');
    };



    /* pauseDigit */
    const pauseDigit = () => {
        try {
            if (digitSourceRef.current) {
                digitSourceRef.current.stop();
                console.log('Digit stopped playing');
                toast.info('Digit Stopped Playing');
            }
            if (leftDigitGainNodeRef.current) {
                leftDigitGainNodeRef.current.gain.value = 0;
                leftDigitGainNodeRef.current.disconnect();
                console.log('[+] Resetting left Digit Gain');
            }
            if (rightDigitGainNodeRef.current) {
                rightDigitGainNodeRef.current.gain.value = 0;
                rightDigitGainNodeRef.current.disconnect();
                console.log(`[+] Resetting Right Digit Gain`);
            }
        } catch (error) {
            console.log(error.message);
            toast.error(error.message);
        }
    };

    /* Play the digit function for now this is for muutumaton*/
    const playTriplet = (index, channel = 'both', decibelIndex = 0) => {
        console.log(`[+] This Is playTripletAdaptive Index:${index} Channel:${channel} DecibelIndex:${decibelIndex} decibelIndexRef:${decibelIndexRef.current}`);
        if (digitBuffersRef.current[index]) {
            if (digitSourceRef.current) {
                console.log(`[+] digitSourceRef.current found:`);
                digitSourceRef.current.stop();
            }
            pauseDigit();

            // Default decibel value
            const speechDB = audioObject.defaultDB + audioObject.calculateArithmeticSum(decibelIndex, 5, 0); // Base decibel level
            const leftCalibration = leftCalibrationValue || 0; // Calibration for left ear
            const rightCalibration = rightCalibrationValue || 0; // Calibration for right ear

            // Calculate gain values considering calibration
            const leftDecibels = speechDB + leftCalibration;
            const rightDecibels = speechDB + rightCalibration;
            /* Gain values */
            const leftGainValue = audioObject.calculateGainFromDecibelsWithThreshold(leftDecibels);
            const rightGainValue = audioObject.calculateGainFromDecibelsWithThreshold(rightDecibels);

            digitSourceRef.current = audioContextRef.current.createBufferSource();
            digitSourceRef.current.buffer = digitBuffersRef.current[index];

            const digitSplitter = audioContextRef.current.createChannelSplitter(2);
            const digitMerger = audioContextRef.current.createChannelMerger(2);

            digitSourceRef.current.connect(digitSplitter);


            if (channel === 'left' || channel === 'both') {
                digitSplitter.connect(leftDigitGainNodeRef.current, 0); // Connect left channel
                leftDigitGainNodeRef.current.connect(digitMerger, 0, 0);
                leftDigitGainNodeRef.current.gain.value = leftGainValue; // Set gain explicitly
            }

            if (channel === 'right' || channel === 'both') {
                digitSplitter.connect(rightDigitGainNodeRef.current, 1); // Connect right channel
                rightDigitGainNodeRef.current.connect(digitMerger, 0, 1);
                rightDigitGainNodeRef.current.gain.value = rightGainValue; // Set gain explicitly
            }


            console.log(`[+] Playing TripletAdaptive Mode speechDB:${speechDB} leftChannel:${leftGainValue} rightChannel:${rightGainValue}`);
            toast.info(`[+] This Is playTripletAdaptive Index:${index} Channel:${channel} DecibelIndex:${decibelIndex} decibelIndexRef:${decibelIndexRef.current} speechDB:${speechDB} leftChannel:${leftGainValue} rightChannel:${rightGainValue}`);
            digitMerger.connect(audioContextRef.current.destination);

            digitSourceRef.current.onended = () => {
                console.log('[+] Finished playing tripletAdaptive, waiting for user input...');
            };

            digitSourceRef.current.start(0);
        }
    };

    /* This is the current version of the test */
    const startTest = (channel = 'both', index = 0, decibelIndex = 0) => {
        if (!audioContextRef.current || digitBuffersRef.current.length === 0) {
            console.log('Audio context or buffers not ready.');
            return;
        }
        try {
            toast.info(`[+] Test  Started startest channel:${channel} index:${index} decibelIndex:${decibelIndex}`);
            toast.success(`You will hear digit from Sound Source ${channel}`);
            console.log(`[+] Test  Started startest channel:${channel} index:${index} decibelIndex:${decibelIndex}`);
            /* Set the index and running state */
            setCurrentTripletIndex(index);
            setIsTestRunning(true);
            isTestRunningRef.current = true;
            setIsPlaying(true);
            /* Update the channel */
            setCurrentChannel(channel);
            currentChannelRef.current = channel;
            /* Set the decibel index for increase and decrease of the volume */
            decibelIndexRef.current = decibelIndex;
            setDecibelIndex(decibelIndex);
            playTriplet(index, channel, decibelIndex);
        } catch (error) {
            console.log(`[+] Something went wrong in start test:`, error.message);
            return toast.error('something went wrong ...');
        }
    };


    // UI data for the different steps
    const uiData = [
        {
            src: GirlWithHeadPhoneIcon,
            channel: 'both',
            btn1Text: 'Takaisin',
            btn2Text: 'Seuraava',
            btn1Function: handle_Takaisin,
            btn2Function: handle_Seuraava,
            completion: false,
            textContent: `Aseta vieressä olevat kuulokkeet korvillesi siten, että sininen kuuloke
            peittää vasemman korvasi ja punainen kuuloke oikean korvasi. Saat lisää
            ohjeita seuraavassa vaiheessa.`
        },
        {
            src: GirlWithHeadPhoneIconLeftEar,
            channel: 'left',
            btn1Text: 'En Kuule',
            btn2Text: 'Kyllä kuulen',
            btn1Function: handle_Enkuule,
            btn2Function: handleKylläKuule,
            completion: false,
            textContent: `Varmistetaan laitteiden toimivuus. 
            Teidän pitäisi nyt kuulla vasemmalla korvalla puhetta. Jos et kuule puhetta, paina “x” -painiketta.`
        },
        {
            src: GirlWithHeadPhoneIconRightEar,
            channel: 'right',
            btn1Text: 'En Kuule',
            btn2Text: 'Kyllä kuulen',
            btn1Function: handle_Enkuule,
            btn2Function: handleKylläKuule,
            completion: false,
            textContent: `Varmistetaan laitteiden toimivuus. 
            Teidän pitäisi nyt kuulla oikealla korvalla puhetta. Jos et kuule puhetta, paina “x” -painiketta.`
        },
    ];

    const currentUI = uiData[currentIndex];


    /* Load the noise and the number list */
    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        // Create gain nodes for the noise
        leftDigitGainNodeRef.current = audioContextRef.current.createGain();
        rightDigitGainNodeRef.current = audioContextRef.current.createGain();


        // Load digit audio files for the selected list
        const digitPromises = lists[selectedList].map((digit, index) =>
            fetch(require(`../../../assets/16Bit/${selectedList}/${digit}.wav`))
                .then(response => response.arrayBuffer())
                .then(buffer => audioContextRef.current.decodeAudioData(buffer))
                .then(decodedBuffer => {
                    digitBuffersRef.current[index] = decodedBuffer;
                    console.log(`Digit ${digit} loaded and decoded`);

                })
        );

        Promise.all(digitPromises).then(() => {
            console.log('All digit audio files loaded and ready to play');
            /* Remove this later in production */
            setTimeout(() => {
                setIsTestLoaded(true);
            }, 1200);
        });

        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []); // Reload when selectedList changes


    useEffect(() => {
        // Trigger the animation when the component is mounted
        if (uiData[currentIndex].completion) {
            setIsLoaded(true);
        }
    }, [currentIndex]);

    /* This will check for electron environment and will start the test automatically if pressed next */
    useEffect(() => {
        if (isTestLoaded && !isElectron())
            setShowModal(true); // Open modal if not in Electron
        else {
            if (isTestLoaded && isElectron()) {
                setShowModal(true);
                confirmModalTextRef.current = 'You Are Using Truehear Desktop Version';
                // setTimeout(() => {
                //     startTest('left', 0);
                // }, 4000);
                // toast.info("Test Will Start in 4 second");
            }
        }

    }, [isTestLoaded]);

    if (isTestLoaded === false)
        return <FullPageLoader />;

    // Conditional rendering based on the completion status
    if (kuulokkeidenCompleted) {
        return <KuulokkeidenCompletionPage />;
    }

    return (
        <div className='h-screen bg-gray-100 p-0 overflow-y-auto'>
            <div className='flex flex-col items-center gap-y-2 mt-[4%]'>
                <div>
                    <KuulokkeidenHeader headerText='Kuulokkeiden sovitus' />
                </div>
                <div className='text-center'>
                    <KuulokkeidenInstruction className='text-3xl md:text-4xl lg:text-6xl text-[#344054] font-bold' textContent={
                        `Pue kuulokkeet 
                        itsellesi`
                    } />
                </div>
                <div className='text-center mt-4'>
                    <KuulokkeidenInstruction textContent={currentUI.textContent} />
                </div>
                <div className='flex flex-col justify-center items-center mt-1 flex-grow relative z-10'>
                    <img
                        src={currentUI.src}
                        className='max-w-full h-auto max-h-[50vh] object-contain relative'
                        alt="Girl with Headphones"
                    />
                    {/* <div className='relative w-full top-[-14%] sm:top-[-14%] md:top-[-15%] lg:top-[-6.5%]  min-w-[100%] md:min-w-[55%] lg:min-w-[150%]
                    '>
                        <div className='w-full '>
                            <div className='text-sm gap-x-4 flex py-2  px-6 bg-white text-black rounded-md justify-center'>
                                <button className='bg-white text-gray-800 py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out border border-gray-300 hover:bg-gray-400' onClick={currentUI.btn1Function}>{currentUI.btn1Text}</button>
                                <button className='bg-purple-600 transition-colors duration-300 ease-out hover:bg-purple-800 text-white py-3 px-6 rounded-lg' onClick={currentUI.btn2Function}>{currentUI.btn2Text}</button>
                            </div>
                        </div>
                    </div> */}

                    <div className='w-full min-w-[100%] md:min-w-[55%] lg:min-w-[150%]'>
                        <div className='w-full '>
                            <div className='text-sm gap-x-4 flex py-2  px-6 bg-white text-black rounded-md justify-center'>
                                <button className='bg-white text-gray-800 py-3 px-6 rounded-lg transition-colors duration-300 ease-in-out border border-gray-300 hover:bg-gray-400' onClick={currentUI.btn1Function}>{currentUI.btn1Text}</button>
                                <button className='bg-purple-600 transition-colors duration-300 ease-out hover:bg-purple-800 text-white py-3 px-6 rounded-lg' onClick={currentUI.btn2Function}>{currentUI.btn2Text}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModalv1
                isOpen={showModal && false}
                isClosing={isClosing} // Pass down the closing state
                onClose={handleModalClose} // Smooth close on cancel
                onConfirm={handleConfirm} // Smooth close on confirm
                text={confirmModalTextRef.current}
            />
            <ToastContainer />
        </div>
    );
};

export default KuulokkeidenTestaaminen;
