// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import fi from './locales/fi/translation.json';
import fr from './locales/fr/translation.json';

// Get the language from localStorage or default to 'fi'
const storedLanguage = localStorage.getItem('language') || 'fi';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        fi: { translation: fi },
        fr: { translation: fr },
    },
    lng: storedLanguage, // Use the stored language or default to 'fi'
    fallbackLng: 'fi', // Fallback to Finnish if the selected language isn't available
    interpolation: {
        escapeValue: false,
    },
});

// Update language in localStorage when it's changed in the app
i18n.on('languageChanged', (lng) => {
    console.log(`[+] Language Changed:`, lng);
    localStorage.setItem('language', lng);
});

export default i18n;
