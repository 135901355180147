// Helper function to check if the value is valid JSON
const isValidJSON = (value) => {
    try {
        JSON.parse(value);
        return true;
    } catch (e) {
        return false;
    }
};


export default isValidJSON;