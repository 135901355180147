import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCompensationFactor } from '../../Audiogram/fetchParam';
import isElectron from "../../../../Functions/isElectron";
import calibrationData from './CalibrationData'; // Import the CalibrationData.js file
import { toast, ToastContainer } from 'react-toastify'; // Toast notifications

/// Play sound functionality
let audioContext = new (window.AudioContext || window.webkitAudioContext)(); 
let currentSrc; 

function playWave(hz, db, calib, side) {
  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }

  if (currentSrc) {
    currentSrc.stop();
    currentSrc.disconnect();
  }

  let oscillator = audioContext.createOscillator();
  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

  let gainNode = audioContext.createGain();
  let dbMultiplier = 10 ** ((db + calib - 80) / 20);

  console.log({dbMultiplier})
  gainNode.gain.value = dbMultiplier;

  let panner = audioContext.createStereoPanner();
  panner.pan.setValueAtTime(side, audioContext.currentTime);

  oscillator.connect(gainNode);
  gainNode.connect(panner);
  panner.connect(audioContext.destination);

  oscillator.start();
  currentSrc = oscillator;
}

function playWaveComp(hz, db, calib, comp, side) {
  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }

  if (currentSrc) {
    currentSrc.stop();
    currentSrc.disconnect();
  }

  let oscillator = audioContext.createOscillator();
  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

  let gainNode = audioContext.createGain();
  let dbMultiplier = 10 ** ((db + calib + comp - 80) / 20);

  console.log({dbMultiplier})
  gainNode.gain.value = dbMultiplier;

  let panner = audioContext.createStereoPanner();
  panner.pan.setValueAtTime(side, audioContext.currentTime);

  oscillator.connect(gainNode);
  gainNode.connect(panner);
  panner.connect(audioContext.destination);

  oscillator.start();
  currentSrc = oscillator;
}

function stopSound() {
  if (currentSrc) {
    currentSrc.stop();
    currentSrc.disconnect();
    currentSrc = null;
  }
}

const CalibrationBox = ({ refreshDataAfterSave, onSelectChange, fetchCalibrationData }) => {
  const [side, setSide] = useState('1'); // Default to Right (1)
  const [frequency, setFrequency] = useState('1000'); // Default to 1000Hz
  const [dbLevel, setDbLevel] = useState('30'); // Default db level
  const [calibrationFactor, setCalibrationFactor] = useState('-1');

  // Handle side and frequency change
  useEffect(() => {
    onSelectChange(side, frequency);
  }, [side, frequency, onSelectChange]);

  // Load calibration data when side, frequency, or dbLevel changes
  useEffect(() => {
    const loadCalibrationData = async () => {
      try {
        let data;
        if (isElectron() && window.electron.loadAudiometryCalibration) {
          // Fetch calibration data from Electron storage
          const response = await window.electron.loadAudiometryCalibration();
          if (response.success) {
            data = response.data;
          } else {
            console.error('Failed to load calibration data from Electron.');
          }
        } else {
          // Browser environment
          // Try to fetch data from localStorage
          let storedData = localStorage.getItem('calibrationData');
          if (storedData) {
            data = JSON.parse(storedData);
          } else {
            // If no data in localStorage, load from CalibrationData.js
            data = calibrationData;
            // Save the data to localStorage for future use
            localStorage.setItem('calibrationData', JSON.stringify(calibrationData));
          }
        }

        // Now set the calibrationFactor based on the loaded data
        if (data && data[side] && data[side][frequency]) {
          const dbLevelData = data[side][frequency].find(item => item.dbLevel === dbLevel);
          if (dbLevelData) {
            setCalibrationFactor(dbLevelData.calibrationFactor);
          } else {
            // If no specific dbLevel data, set calibrationFactor to '0'
            setCalibrationFactor('0');
          }
        } else {
          // No data available, set default calibrationFactor
          setCalibrationFactor('0');
        }
      } catch (error) {
        console.error('Error loading calibration data:', error);
        // Set default calibrationFactor in case of error
        setCalibrationFactor('0');
      }
    };

    loadCalibrationData();
  }, [side, frequency, dbLevel]);

  // Handle saving calibration factor for a single value to server
  const handleSaveSingleToServer = async () => {
    if (side && frequency && dbLevel && calibrationFactor) {
      const data = {
        side,
        frequency,
        dbLevel,
        calibrationFactor,
      };

      try {
        // Save to server in web environment
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/save`, data);
        if (fetchCalibrationData) {
          fetchCalibrationData(side, frequency);  // Re-fetch the calibration data after saving

          toast.success('Calibration saved successfully!');
        }
      } catch (error) {
        console.error("Error saving calibration data to server:", error);
      }
    }
  };

  // Handle saving calibration factor for all dB levels to server
  const handleSaveAllToServer = async () => {
    if (side && frequency && calibrationFactor) {
      const data = { side, frequency, calibrationFactor };
      try {
        // Save to server in web environment
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/saveAll`, data);
        if (fetchCalibrationData) {
          fetchCalibrationData(side, frequency);
          toast.success('Calibration saved successfully!');
        }
      } catch (error) {
        console.error('Error saving calibration data to server:', error);
      }
    }
  };

  // Functions to save locally (Electron or localStorage)
  const handleSaveSingleToLocal = async () => {
    if (side && frequency && dbLevel && calibrationFactor) {
      const data = {
        side,
        frequency,
        dbLevel,
        calibrationFactor,
      };

      try {
        if (isElectron() && window.electron.saveAudiometryCalibration) {
          // Save locally in Electron environment
          const response = await window.electron.saveAudiometryCalibration({ ...data, saveAll: false });
          if (response.success) {
            console.log("Calibration data saved locally via Electron.");
            toast.success('Calibration saved successfully locally!');
            refreshDataAfterSave(); // Refresh data after saving
          } else {
            console.error("Failed to save calibration data via Electron.");
          }
        } else {
          // Save to localStorage in browser environment
          // Get existing calibration data from localStorage
          const existingData = JSON.parse(localStorage.getItem('calibrationData')) || {};

          if (!existingData[side]) {
            existingData[side] = {};
          }
          if (!existingData[side][frequency]) {
            existingData[side][frequency] = [];
          }

          // Update or add the calibration data
          const index = existingData[side][frequency].findIndex(item => item.dbLevel === dbLevel);
          if (index >= 0) {
            existingData[side][frequency][index] = { dbLevel, calibrationFactor };
          } else {
            existingData[side][frequency].push({ dbLevel, calibrationFactor });
          }

          // Save back to localStorage
          localStorage.setItem('calibrationData', JSON.stringify(existingData));
          console.log("Calibration data saved to localStorage.");
          toast.success('Calibration saved successfully locally!');
        }
      } catch (error) {
        console.error("Error saving calibration data locally:", error);
      }
    }
  };

  const handleSaveAllToLocal = async () => {
    if (side && frequency && calibrationFactor) {
      const data = { side, frequency, calibrationFactor };

      try {
        if (isElectron() && window.electron.saveAudiometryCalibration) {
          // Save locally in Electron environment
          const response = await window.electron.saveAudiometryCalibration({ ...data, saveAll: true });
          if (response.success) {
            console.log("Calibration data saved locally via Electron.");
            toast.success('Calibration saved successfully locally!');
            refreshDataAfterSave(); // Refresh data after saving
          } else {
            console.error("Failed to save calibration data via Electron.");
          }
        } else {
          // Save to localStorage in browser environment
          // Get existing calibration data from localStorage
          const existingData = JSON.parse(localStorage.getItem('calibrationData')) || {};

          if (!existingData[side]) {
            existingData[side] = {};
          }

          // Update all dB levels with the calibration factor
          const dbLevels = ['-10', '-5', '0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100'];
          existingData[side][frequency] = dbLevels.map(dbLevel => ({ dbLevel, calibrationFactor }));

          // Save back to localStorage
          localStorage.setItem('calibrationData', JSON.stringify(existingData));
          console.log("Calibration data for all dB levels saved to localStorage.");
        }
      } catch (error) {
        console.error("Error saving calibration data locally:", error);
      }
    }
  };

  // Play sound functions remain the same
  const handlePlaySound = () => {
    // Set dbLevel and calibrationFactor to 0 if they are empty or NaN
    const finalDbLevel = dbLevel === '' || isNaN(dbLevel) ? 0 : parseFloat(dbLevel);
    const finalCalibrationFactor = calibrationFactor === '' || isNaN(calibrationFactor) ? 0 : parseFloat(calibrationFactor);

    // Play sound with parsed values
    playWave(parseFloat(frequency), finalDbLevel, finalCalibrationFactor, parseFloat(side));
  };

  // Play sound with compensation
  const handlePlayWithCalibration = async () => {
    // Set dbLevel and calibrationFactor to 0 if they are empty or NaN
    const finalDbLevel = dbLevel === '' || isNaN(dbLevel) ? 0 : parseFloat(dbLevel);
    const finalCalibrationFactor = calibrationFactor === '' || isNaN(calibrationFactor) ? 0 : parseFloat(calibrationFactor);
    const headphoneModel = 'DD65v2';

    console.log('Taajuus', parseFloat(frequency));
    console.log('kuulokemalli ', headphoneModel);

    const compensation = await getCompensationFactor(headphoneModel, parseFloat(frequency));

    console.log(`Compensation: ${compensation}`);

    // Play sound with parsed values
    playWaveComp(parseFloat(frequency), finalDbLevel, finalCalibrationFactor, compensation, parseFloat(side));
  };

  return (
    <div className="flex flex-col w-3/4">
      <h3 className="text-lg font-bold mb-4">Kalibrointi</h3>

      {/* Side Dropdown */}
      <div className="flex justify-between mb-2">
        <label htmlFor="sideSelect">Valitse puoli:</label>
        <select
          id="sideSelect"
          value={side}
          onChange={(e) => setSide(e.target.value)}
          className="border p-2 w-1/3"
        >
          <option value="-1">Vasen</option>
          <option value="1">Oikea</option>
        </select>
      </div>

      {/* Frequency Dropdown */}
      <div className="flex justify-between mb-2">
        <label htmlFor="frequencySelect">Taajuus:</label>
        <select
          id="frequencySelect"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
          className="border p-2 w-1/3"
        >
          <option value="125">125Hz</option>
          <option value="250">250Hz</option>
          <option value="500">500Hz</option>
          <option value="750">750Hz extra</option>
          <option value="1000">1000 Hz</option>
          <option value="1500">1500 Hz extra</option>
          <option value="2000">2000 Hz</option>
          <option value="3000">3000 Hz extra </option>
          <option value="4000">4000 Hz</option>
          <option value="5000">5000 Hz extra</option>
          <option value="6000">6000 Hz</option>
          <option value="8000">8000 Hz</option>
          <option value="10000">10000 Hz extra</option>
          <option value="12000">12000 Hz extra</option>
          <option value="14000">14000 Hz extra</option>
          <option value="16000">16000 Hz extra</option>
          {/* Add more frequencies as necessary */}
        </select>
      </div>

      {/* dB Level Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="dbLevelInput">dB taso:</label>
        <input
          type="text"
          id="dbLevelInput"
          value={dbLevel}
          onChange={(e) => setDbLevel(e.target.value)}
          className="border p-2 w-1/3"
        />
      </div>

      {/* Calibration Factor Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="calibrationFactorInput">Kalibrointiarvo:</label>
        <input
          type="text"
          id="calibrationFactorInput"
          value={calibrationFactor}
          onChange={(e) => setCalibrationFactor(e.target.value)}
          className="border p-2 w-1/3"
        />
      </div>

      {/* Sound Control Buttons */}
      <div className="flex justify-center gap-4 mb-4">
        <button
          onClick={handlePlaySound}
          className="bg-blue-500 text-white px-6 py-2 rounded-lg"
        >
          Play with calibration
        </button>
        <button
          onClick={stopSound}
          className="bg-red-500 text-white px-6 py-2 rounded-lg"
        >
          Stop Sound
        </button>
        <button
          onClick={handlePlayWithCalibration}
          className="bg-green-500 text-white px-6 py-2 rounded-lg"
        >
          Play with compensation
        </button>
      </div>

      {/* Save Buttons */}
      <div className="flex justify-center mt-2">
        <button onClick={handleSaveSingleToServer} className="bg-blue-500 text-white px-6 py-2 rounded-lg mr-2">
          Save Single Value to Server / Electron
        </button>
        <button onClick={handleSaveAllToServer} className="bg-green-500 text-white px-6 py-2 rounded-lg">
          Save All Values to Server / Electron
        </button>
      </div>
      <div className="flex justify-center mt-2">
        <button onClick={handleSaveSingleToLocal} className="bg-blue-500 text-white px-6 py-2 rounded-lg mr-2">
          Single value to browser LocalStorage 
        </button>
        <button onClick={handleSaveAllToLocal} className="bg-green-500 text-white px-6 py-2 rounded-lg">
          All Values to browser LocalStorage
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CalibrationBox;
