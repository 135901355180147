import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Help_Icon,
  BackgroundImageProm
} from "../../../assets/Icons";

function KuuloKyselyLandingPage() {
  const navigate = useNavigate();

  // Navigation handlers
  const handleStartClick = () => {
    navigate("/KuuloKysely");
  };

  const handleCancelClick = () => {
    navigate("/TestLandingPage");
  };

  const handleHelpClick = () => {
    navigate("/HelpPage"); // Ensure this route exists
  };

  return (
    <div
      className="h-screen bg-gray-100 flex flex-col"
      style={{
        backgroundImage: `url(${BackgroundImageProm})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >
      {/* Top Left Bar: Peruuta Button and Help Icon */}
      <div className="fixed top-4 left-4 flex items-center space-x-4 z-30">
        {/* "Peruuta" Button with ArrowLeft Icon */}
        <button
          onClick={handleCancelClick}
          className="flex items-center space-x-2"
        >
          <span className="flex items-center gap-3 px-3 py-3 lg:px-6 lg:py-4 lg:text-lg font-semibold bg-white rounded-xl border border-gray-300 shadow-sm text-slate-700 hover:bg-purple-400 transition-all duration-200">
            <img src={ArrowLeft} className="w-6 h-6" alt="Arrow Left" />
            Peruuta
          </span>
        </button>

        {/* Help Icon Button 
        <button onClick={handleHelpClick} className="flex items-center">
          <img
            src={Help_Icon}
            className="w-15 h-15 lg:w-20 lg:h-20"
            alt="Help Icon"
          />
        </button> */}
      </div>

      {/* Start Button Positioned 100px Down and Right from Center */}
      <div className="absolute inset-0 flex items-center justify-center">
        <button
          onClick={handleStartClick}
          className="flex items-center gap-3 px-3 py-3 lg:px-6 lg:py-4 lg:text-lg  font-semibold bg-purple-600 rounded-xl shadow-sm text-white hover:bg-purple-800 transition-all duration-200"
          style={{
            transform: "translate(100px, 100px)"
          }}
        >
          Aloita kuulokysely
        </button>
      </div>
    </div>
  );
}

export default KuuloKyselyLandingPage;
