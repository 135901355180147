import React, { useEffect, useRef, useState } from 'react';
import { UserIcon } from '../../../assets/Icons';
import UserDisplaySection from '../../Global/UserDisplaySection';
import GlobalNavbar from '../../../utils/GlobalNavbar';
import ContentWrapper from '../../../utils/ContentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isElectron from '../../../Functions/isElectron';
import { setAdaptiveSetting, setDigitFolderNameSetting, setEarsSetting, setHeadPhoneLeftEarSetting, setHeadPhoneRightEarSetting, setLeftEarCalibrationSetting, setMuutumatonSetting, setNumberSeriesSetting, setRightEarCalibrationSetting, setSoundSourceSetting, setSpeakerLeftEarSetting, setSpeakerRightEarSetting, setSpeechVolumeSetting, setStereoSetting } from '../../../Slices/dinSettingSlice';
import { useTranslation } from 'react-i18next';

const UserDetailsPage = () => {
    const { t } = useTranslation(); // Use useTranslation hook
    const user = useSelector((state) => state.authState.user) || JSON.parse(localStorage.getItem('user'));
    console.log(`[+] GD:`, { true: true });
    const navigate = useNavigate();
    console.log(`[+] Userdetails:`, { user });
    const dispatch = useDispatch();

    // To hold default settings from file
    const [defaultSettings, setDefaultSettings] = useState(null);
    const defaultSettingsRef = useRef(null); // Reference to persist default settings

    console.log({ defaultSettings });
    // Default settings object for fallback
    const defaultSettingsFallback = {
        adaptive: 0,
        muutumaton: 1,
        ears: ['left'],
        speechVolume: 60,
        numberSeries: 'list1',
        leftEarCalibration: 0,
        rightEarCalibration: 0,
        firstEar: 'left',
        soundSource: 'headPhone',
        speakerLeftEarSetting: 0,
        speakerRightEarSetting: 0,
        headPhoneLeftEarSetting: 0,
        headPhoneRightEarSetting: 0,
        stereoSetting: false,
        digitFolderNameSetting: 'FinnishTriplets'

    };
    /* For DIN */
    const loadSettings = async () => {
        if (isElectron() && window.electron.isElectron && window.electron.loaddinSettings) {
            try {
                const response = await window.electron.loaddinSettings();
                if (response.success) {
                    let loadedSettings = response.data || {}; // Default to empty object if no data

                    // Merge loaded settings with fallback default settings to ensure all fields are present
                    const mergedSettings = {
                        ...defaultSettingsFallback, // Start with default settings
                        ...loadedSettings, // Override with any loaded settings
                        ears: loadedSettings.ears && loadedSettings.ears.length > 0 ? loadedSettings.ears : defaultSettingsFallback.ears // Handle ears array explicitly
                    };

                    // Update state and reference with merged settings
                    defaultSettingsRef.current = mergedSettings;
                    setDefaultSettings(mergedSettings);

                    // Dispatch loaded settings to Redux
                    dispatch(setAdaptiveSetting(mergedSettings.adaptive));
                    dispatch(setMuutumatonSetting(mergedSettings.muutumaton));
                    dispatch(setEarsSetting(mergedSettings.ears));
                    dispatch(setSpeechVolumeSetting(mergedSettings.speechVolume));
                    dispatch(setNumberSeriesSetting(mergedSettings.numberSeries));
                    dispatch(setLeftEarCalibrationSetting(mergedSettings.leftEarCalibration));
                    dispatch(setRightEarCalibrationSetting(mergedSettings.rightEarCalibration));
                    dispatch(setHeadPhoneLeftEarSetting(mergedSettings.headPhoneLeftEarSetting));
                    dispatch(setHeadPhoneRightEarSetting(mergedSettings.headPhoneRightEarSetting));
                    dispatch(setSpeakerLeftEarSetting(mergedSettings.speakerLeftEarSetting));
                    dispatch(setSpeakerRightEarSetting(mergedSettings.speakerRightEarSetting));
                    dispatch(setSoundSourceSetting(mergedSettings.soundSource));
                    dispatch(setStereoSetting(mergedSettings.stereoSetting));
                    dispatch(setDigitFolderNameSetting(mergedSettings.digitFolderNameSetting));
                    return console.info('Settings loaded successfully!');
                } else {
                    // Load default settings if no previous settings found
                    defaultSettingsRef.current = defaultSettingsFallback;
                    setDefaultSettings(defaultSettingsFallback);
                    console.info('No previous settings found. Loaded default settings.');
                }
            } catch (error) {
                console.error(error.message);
                console.error('Error loading settings from file.');
            }
        } else {
            console.info('[+] Skipping Load Settings as we are in browser environment');
        }
    };

    /* For audiogram */



    // Load settings from a file if running in Electron
    useEffect(() => {
        loadSettings();
        /* Import audio gram setting here */
    }, [dispatch]);



    return (user && <div className='min-h-screen bg-gray-100 flex flex-col items-center'>
        <GlobalNavbar />
        <div className='pt-4 flex justify-center flex-col px-4'>
            <UserDisplaySection user={user} bannerText={t('userDetails_welcome')} />
            <ContentWrapper>
                <div className='px-2 flex gap-x-1'>
                    <img src={UserIcon} className='size-4' alt='' />
                    <p className='text-sm text-[##344054] font-bold'>{t('userDetails_checkinfo')}</p>
                </div>
                <div className='px-2 mt-4'>
                    <p className='text-gray-700 mb-4 border-b-[1px] py-1 border-dashed border-gray-400'><strong>{t('userDetails_name')}:</strong> {user.firstName} {user.lastName}</p>
                    <p className='text-gray-700 mb-4 border-b-[1px] py-1 border-dashed border-gray-400'><strong>{t('userDetails_id')}:</strong> {user.socialSecurityNumber}</p>
                    <div className='flex gap-x-2'>
                        <button className='bg-purple-600 transition-colors duration-300 ease-out hover:bg-purple-800 text-white py-1 px-4 rounded-lg' onClick={(e) => navigate('/TestLandingPage')}>{t('userDetails_info_correct')}</button>
                        <button className='bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400' onClick={(e) => navigate('/EditloginPage')}>{t('userDetails_fix_info')}</button>
                    </div>
                </div>
            </ContentWrapper>

        </div>
    </div>
    );
};

export default UserDetailsPage;