import React, { useState, useCallback } from 'react';

const ModalComponent = ({ isOpen, onClose, onSave }) => {
    const [boothSearch, setBoothSearch] = useState('');
    const [selectedBooth, setSelectedBooth] = useState(null);
    console.log(`[+]MC`);
    // Booth data from Redux store or fetch from an API
    // const booths = useSelector((state) => state.boothData.booths);
    const [booths, setBooths] = useState([]);
    const handleBoothSelect = useCallback((booth) => {
        setSelectedBooth(booth);
    }, []);

    const handleSave = () => {
        if (selectedBooth) {
            onSave(selectedBooth); // Passing selected booth to parent
        } else {
            alert('Please select a booth.');
        }
    };

    if (!isOpen) return null; // Don't render the modal if it's not open

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto">
                <h2 className="text-2xl font-semibold text-gray-700">Find the booth</h2>
                <div className="mt-4">
                    {/* Booth Search */}
                    <label className="text-lg font-medium text-gray-600">Search Booth</label>
                    <input
                        type="text"
                        value={boothSearch}
                        onChange={(e) => setBoothSearch(e.target.value)}
                        className="p-2 mt-2 border border-gray-300 rounded-md w-full"
                        placeholder="Search booth by name"
                    />
                    <div className="max-h-48 overflow-y-auto mt-2">
                        {boothSearch.length > 0 && (
                            booths
                                .filter(booth => booth.name.toLowerCase().includes(boothSearch.toLowerCase()))
                                .map((booth) => (
                                    <div
                                        key={booth._id}
                                        onClick={() => handleBoothSelect(booth)}
                                        className={`cursor-pointer p-2 rounded-md ${selectedBooth === booth ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                                    >
                                        {booth.name}
                                    </div>
                                ))
                        )}
                    </div>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded-md">Cancel</button>
                    <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded-md">Save</button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ModalComponent);
