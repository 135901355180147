import React from 'react';
import { InfoIcon, LeftIcon } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import SegmentedColorBar from './SegmentedColorBar';
import { useTranslation } from 'react-i18next';

const DinResultPage = ({ l50l = -5.5, l50R = -8.4 }) => {
    /* Generate the segmentClass to highlight */
    const getSegmentClass = (l50) => {
        switch (true) {
            case l50 >= -4:
                return "highlight-segment-1"; // First Segment
            case l50 < -4 && l50 >= -6:
                return "highlight-segment-2"; // Second Segment
            case l50 < -6 && l50 >= -8:
                return "highlight-segment-3"; // Third Segment
            case l50 < -8 && l50 >= -10:
                return "highlight-segment-4"; // Fourth Segment
            default:
                return "highlight-segment-5"; // Final Segment
        }
    };
    /* For Left and right ear */
    const segmentClassL = getSegmentClass(l50l);
    const segmentClassR = getSegmentClass(l50R);
    /* Translation language */
    const { t } = useTranslation();
    const navigate = useNavigate();
    /* navigat to testlandingpage */
    const handlePeruuta = async () => {
        try {
            return navigate('/TestLandingPgage');
        } catch (error) {
        }
    };

    return (
        <div className="h-screen bg-gray-100 ">
            <div className='h-screen flex flex-col items-center overflow-auto'>
                {/* Navigation Center */}
                <div className='text-md font-bold flex gap-x-1 w-full p-4'>
                    <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-4 lg:px-6
                               2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1' onClick={(e) => { handlePeruuta(); }}>
                        <img src={LeftIcon} alt='' className='size-4' />
                        <button className='' >{t('dinResultPage_navigation_cancel')}</button>
                    </div>


                    <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-4 lg:px-19
                               2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1'
                        onClick={(e) => { }}  >
                        <img src={InfoIcon} alt='ℹ️' className='size-4' />
                        <button className='' >{t('dinResultPage_navigation_help')}</button>
                    </div>
                </div>
                {/* Contents */}
                <div className='flex flex-col py-10 gap-y-10 ' >
                    <div className='flex flex-col gap-y-6 '>
                        {/* Header */}
                        <p className='text-sm md:text-3xl lg:text-4xl font-bold px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4'>{t('dinResultPage_header')}</p>
                        <div className='px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4'>
                            <p className='text-sm md:text-1xl lg:text-[20px] text-gray-900'>{t('dinResultPage_results_summary_part1')} <span className='font-bold'>{t('dinResultPage_moderate_severe_hearing_loss_bold')}</span>{t('dinResultPage_hearing_loss')} </p>
                            <p className='text-sm md:text-1xl lg:text-[20px] text-gray-900'><span className='font-bold'>{t('dinResultPage_left_ear_bold')}</span> {t('dinResultPage_and')} <span className='font-bold'>{t('dinResultPage_significant_hearing_loss_bold')}</span> {t('dinResultPage_hearing_loss_right_ear')} <span className='font-bold'>  {t('dinResultPage_right_ear_bold_part1')}</span>  {t('dinResultPage_right_ear_bold_part2')}.</p>
                        </div>

                    </div>

                    <div className='flex gap-x-4 flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-y-4 justify-center items-center  '>
                        {/* Results left */}
                        <div className='p-4'>
                            <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col ">
                                <div className="relative flex justify-center items-center w-[360px] h-[360px] flex-col gap-y-4 ">
                                    <div>
                                        <p className='text-gray-900 '>{t('dinResultPage_test_result')}</p>
                                        <p className='text-sm md:text-1xl lg:text-2xl font-bold'>{t('dinResultPage_left_ear_result')}</p>
                                    </div>
                                    <div className='w-full flex justify-center'>
                                        <div className='flex-1' />
                                        <SegmentedColorBar segmentClass={segmentClassL} />
                                        {/* Text Labels for Each Segment */}
                                        <div className="space-y-4 ml-6">
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_significant_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_significant_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_severe_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_severe_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_mild_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_mild_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_normal_hearing_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_normal_hearing_second')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Results right*/}
                        <div className='p-4'>
                            <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col">
                                <div className="relative flex justify-center items-center w-[360px] h-[360px] flex-col gap-y-4">
                                    <div>
                                        <p className='text-gray-900'>{t('dinResultPage_test_result')}</p>
                                        <p className='text-sm md:text-1xl lg:text-2xl font-bold'>{t('dinResultPage_right_ear_result')}</p>
                                    </div>
                                    <div className='w-full flex justify-center'>
                                        <div className='flex-1' />
                                        <SegmentedColorBar segmentClass={segmentClassR} />
                                        {/* Text Labels for Each Segment */}
                                        <div className="space-y-4 ml-6">
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_significant_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_significant_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_severe_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_severe_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_moderate_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_mild_hearing_loss_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_mild_hearing_loss_second')}</span>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="text-sm text-gray-700">{t('dinResultPage_normal_hearing_first')}</span>
                                                <span className="text-sm text-gray-700">{t('dinResultPage_normal_hearing_second')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DinResultPage;
