import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, GirlWithHeadPhoneIconMain } from '../../../assets/Icons';
import KuulokkeidenHeader from './KuulokkeidenHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSoundTest } from '../../../Slices/soundSilce';
import { toast, ToastContainer } from 'react-toastify';
import { updateSoundTestStatus } from '../../../Functions/upDateSoundTestStatus';

const KuulokkeidenCompletionPage = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const soundTest = useSelector((state) => state.soundState.soundTest); // Access the soundTest array from Redux
    const soundTestIndex = useSelector((state) => state.soundState.soundTestIndex) || 0; // Access the current index

    const handleNext = () => {
        try {
            // // Use map to create a new array and update the specific test item
            // const updatedSoundTest = soundTest.map((test, index) =>
            //     index === soundTestIndex
            //         ? { ...test, completed: true }  // Create a new object with updated 'completed' flag
            //         : test
            // );

            // // Dispatch the updated soundTest array to Redux
            // dispatch(setSoundTest(updatedSoundTest));

            // Call the helper function to update the test status
            updateSoundTestStatus(soundTest, soundTestIndex, dispatch, setSoundTest);

            // if (isLoaded) {
            //     setIsLoaded(false);
            // }

            // Add animations or any logic before navigating
            // setTimeout(() => {
            //     setIsLoaded(true);
            // }, 1000);

            // After a delay, navigate to TestLandingPage
            setTimeout(() => {
                return navigate('/TestLandingPage');
            }, 3000);
            return toast.success('You Have completed test you will be soon directed to other tests');
        } catch (error) {
            console.log('handle next error ', error.message);
            return toast.error(error.message);
        }
    };


    useEffect(() => {
        // Trigger the animation when the component is mounted
        setIsLoaded(true);
    }, []);

    return (
        <div className='h-screen bg-gray-100 p-6 md:p-16 overflow-hidden'>
            <div className='flex flex-col items-center mt-[180px] gap-y-2'>
                <div>
                    <img src={CheckCircleIcon} alt='✅' className='size-4' />
                </div>
                <div>
                    <KuulokkeidenHeader className='text-[#363F72] text-sm' headerText='Kuulokkeiden sovitus' />
                </div>
                <div>
                    <p className='text-[#344054] font-bold text-6xl'>Valmis</p>
                </div>
                <div className='text-sm mt-2 gap-x-2 flex py-2 justify-center px-4 text-black rounded-md'>
                    <button className='bg-purple-600 transition-colors duration-300 ease-out hover:bg-purple-800 text-white text-lg py-2 px-5 rounded-lg' onClick={(e) => {
                        handleNext();
                    }}>Seuraava</button>
                </div>
                <div className='flex flex-col justify-center items-center mt-0 flex-grow relative'>
                    <img
                        src={GirlWithHeadPhoneIconMain}
                        className={`max-w-full h-auto max-h-[50vh] object-contain transition-transform duration-1000 ease-in-out ${isLoaded ? 'translate-y-[20%]' : '-translate-y-[140%]'}`}
                        alt="Girl with Headphones"
                    />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default KuulokkeidenCompletionPage;
