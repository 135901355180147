import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../Shared/DashboardWrapper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import isValidJSON from "../../Functions/IsValidJSON";
import { setSelectedPatient } from "../../Slices/selectedPatientSlice";
import { FaCalculator, FaChevronDown, FaChevronUp, FaChartBar } from 'react-icons/fa';
// import Plot from 'react-plotly.js';
import SpeechDBChart from "../Modules/LogisticeModule/SpeechDBChart";
import LogisticChart from "../Modules/LogisticeModule/LogisticChart";
import { curveFitLogistic, getAverageAnswers, getUniqueX, } from '../Modules/LogisticeModule/LogisticHelper';
import { useTranslation } from "react-i18next";
import LanguageSwitcherButton from "../LanguageSwitcher/LanguageSwicherButton";
//import { curveFitLogistic, getAverageAnswers, getUniqueX } from "./LogisticHelper";

const DinResult = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const tokenFromStorage = localStorage.getItem('token');
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);
    const [patients, setPatients] = useState([]);
    const selectedPatient = useSelector((state) => state.selectedPatientState.selectedPatient) || "";
    const [digitInNoiseArray, setDigitInNoiseArray] = useState([]);
    const dispatch = useDispatch();
    const [expandedSections, setExpandedSections] = useState({});
    const [dinTestResults, setDinTestResults] = useState([]);
    const [showAllMeasurements, setShowAllMeasurements] = useState(false);

    //let L50 = null; // Initialize L50 as null

    const toggleExpandSection = (index) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const fetchUsers = async (invoke = false) => {
        try {
            if (invoke === true && search === '')
                return;
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/patient/searchUsers?search=${search}&page=${page}&limit=${limit}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const { data } = response;
            if (data.status === true)
                setPatients(data.users);
        } catch (error) {
            console.log(`[+] Error FetchUsers:`, error.message);
        }
    };

    // Function to format test names
    const formatTestNamevold = (testName) => {
        let formattedName = testName.replace(/-/g, ' ');
        formattedName = formattedName.replace(/Round (\d+)/i, 'Kierros $1');
        formattedName = formattedName.replace(/right/i, 'Oikea');
        formattedName = formattedName.replace(/left/i, 'Vasen');
        formattedName = formattedName.replace(/Muutumaton/i, 'Muuttumaton');
        formattedName = formattedName.replace(/Adaptive/i, 'Adaptiivinen');
        return formattedName;
    };

    const formatTestName = (testName) => {

        let formattedName = testName.replace(/-/g, ' ');
        formattedName = formattedName.replace(/Round (\d+)/i, `${t('dinresultdashboard_round')} $1`);
        formattedName = formattedName.replace(/right/i, t('dinresultdashboard_right'));
        formattedName = formattedName.replace(/left/i, t('dinresultdashboard_left'));
        formattedName = formattedName.replace(/Muutumaton/i, t('dinresultdashboard_static'));
        formattedName = formattedName.replace(/Adaptive/i, t('dinresultdashboard_adaptive'));

        return formattedName;
    };


    useEffect(() => {
        fetchUsers();
    }, [page]);

    // After setting digitInNoiseArray
    useEffect(() => {
        if (digitInNoiseArray.length > 0) {
            const results = digitInNoiseArray.map((digit) => {
                const dateObj = new Date(digit.testStartTimestamp);
                const formattedDate = dateObj.toLocaleDateString();

                // Process each DIN test
                const dinTests = digit.questionHistory.map((history) => {
                    const totalQuestions = history.value.length;
                    const correctAnswers = history.value.filter((q) => q.correct).length;
                    const correctPercentage = ((correctAnswers / totalQuestions) * 100).toFixed(2);

                    let L50 = null;
                    let displayValue = `${correctPercentage}%`; // Use correctPercentage as displayValue

                    if (!history.name.toLowerCase().includes('muuttumaton')) {
                        const xData = history.value.map((question) => question.speechDB - 65);
                        const yData = history.value.map((question) => (question.correct ? 1 : 0));

                        const uniqueX = getUniqueX(xData);
                        const averageY = getAverageAnswers(xData, yData, uniqueX);

                        if (uniqueX.length >= 3) {
                            try {
                                const { L50: calculatedL50 } = curveFitLogistic(uniqueX, averageY);
                                L50 = calculatedL50;
                                displayValue = `${L50.toFixed(2)}`;
                            } catch (error) {
                                console.error('Error in logistic regression:', error);
                            }
                        } else {
                            console.warn(
                                `Not enough data points for logistic regression in test "${history.name}".`
                            );
                        }
                    }

                    return {
                        name: history.name.trim(),
                        displayValue,
                        totalQuestions,
                        correctAnswers,
                        correctPercentage,
                        L50,
                    };
                });

                const hasValidData = dinTests.some((test) => test.displayValue != null);

                if (!hasValidData) {
                    return null;
                }

                return {
                    dateObj,
                    formattedDate,
                    dinTests,
                };
            });

            const filteredResults = results.filter((result) => result !== null);

            // Sort by date descending (latest first)
            filteredResults.sort((a, b) => b.dateObj - a.dateObj);

            setDinTestResults(filteredResults);
        }
    }, [digitInNoiseArray]);



    const allDinTestNames = new Map();
    dinTestResults.forEach((result) => {
        result.dinTests.forEach((test) => {
            const normalizedTestName = test.name.trim().toLowerCase();
            if (!allDinTestNames.has(normalizedTestName)) {
                allDinTestNames.set(normalizedTestName, test.name.trim());
            }
        });
    });
    const dinTestNamesArray = Array.from(allDinTestNames.values());
    //dinTestNamesArray.sort();



    const handleViewDinResult = (patient) => {
        dispatch(setSelectedPatient(patient));
        setDigitInNoiseArray((patient.digitInNoise || []).slice().reverse()); // Create a shallow copy and reverse
    };

    useEffect(() => {
        setDigitInNoiseArray((selectedPatient.digitInNoise || []).slice().reverse()); // Create a shallow copy and reverse
    }, [selectedPatient]);

    return (
        <div className="min-h-screen flex flex-col lg:px-32 px-5 pt-0 lg:pt-0">
            {/* Header Section */}
            <div className="flex flex-col items-center lg:flex-row justify-between pt-16">
                {/* Left Side: "Takaisin" Button */}
                <button onClick={() => navigate('/Dashboard')} className='bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md px-6 py-3'>
                    {t('dinresultdashboard_back_button')}
                </button>


                {/* Center: Heading */}
                <h1 className="text-4xl font-extrabold text-gray-900 text-center tracking-tight">
                    {t('dinresultdashboard_heading')}
                </h1>
                {/* Right Side: Search Input and Button */}
                <div className="flex items-center gap-x-2">
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            dispatch(setSelectedPatient(''));
                            setDigitInNoiseArray([]);
                        }}
                        placeholder={t('dinresultdashboard_search_placeholder')}
                        className="p-3 w-64 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition-all"
                    />
                    <button
                        onClick={() => fetchUsers(true)}
                        className="bg-blue-600 text-white ml-2 px-5 py-2 rounded-lg hover:bg-blue-700 transition-all shadow-sm"
                    >
                        {t('dinresultdashboard_search_button')}
                    </button>
                    <LanguageSwitcherButton />
                </div>

            </div>


            {/* Toggle Button for Results Table */}
            {selectedPatient && dinTestResults.length > 0 && (
                <div className="mb-4"> <br></br>
                    <button
                        onClick={() => toggleExpandSection('results-table')}
                        className="flex items-center text-blue-600 font-semibold"
                    >
                        <span>{t('dinresultdashboard_toggle_table')}</span>
                        {expandedSections['results-table'] ? (
                            <FaChevronUp className="ml-2" />
                        ) : (
                            <FaChevronDown className="ml-2" />
                        )}
                    </button>

                    {/* Conditional Rendering of Results Table */}
                    {expandedSections['results-table'] && (
                        <div className="overflow-auto mt-4">
                            <table className="table-auto w-full border-collapse">
                                <thead className="bg-gray-100 border-b">
                                    <tr>
                                        <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_table_date')}</th>
                                        {dinTestNamesArray.map((testName, index) => (
                                            <th key={index} className="p-4 text-left text-gray-700">
                                                {formatTestName(testName)}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dinTestResults.map((result, index) => (
                                        <tr key={index} className="hover:bg-gray-50 transition-all">
                                            <td className="p-4 text-gray-600">{result.formattedDate}</td>
                                            {dinTestNamesArray.map((testName, idx) => {
                                                const test = result.dinTests.find((t) => t.name === testName);
                                                return (
                                                    <td key={idx} className="p-4 text-gray-600">
                                                        {test && test.displayValue != null ? test.displayValue : 'N/A'}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
            {/* Responsive table */}

            {!selectedPatient && (
                <div className="overflow-auto mt-10 h-[30vh] rounded-lg shadow-lg border border-gray-200 bg-white">
                    <table className="table-auto w-full border-collapse">
                        <thead className="bg-gray-100 border-b">
                            <tr>
                                <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_first_name')}</th>
                                <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_last_name')}</th>
                                <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_ssn')}</th>
                                <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_latest_measurement')}</th>
                                <th className="p-4 text-left text-gray-700">{t('dinresultdashboard_din_result')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {patients.map((patient) => (
                                <tr key={patient._id} className="hover:bg-gray-50 transition-all">
                                    <td className="p-4 text-gray-600">{patient.firstName}</td>
                                    <td className="p-4 text-gray-600">{patient.lastName}</td>
                                    <td className="p-4 text-gray-600">{patient.socialSecurityNumber}</td>
                                    <td className="p-4 text-gray-600">
                                        {patient.latestVisitation?.split('.')[0].split('T').join(' ') || t('dinresultdashboard_not_available')}
                                    </td>
                                    <td className="p-4">
                                        {patient.digitInNoise?.length > 0 ? (
                                            <button
                                                onClick={() => handleViewDinResult(patient)}
                                                className="bg-blue-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 transition-all w-full shadow-sm"
                                            >
                                                {t('dinresultdashboard_view')}
                                            </button>
                                        ) : (
                                            t('dinresultdashboard_na')
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}


            {/* Display digitInNoiseArray */}
            {selectedPatient && digitInNoiseArray.length > 0 ? (
                <div className="mt-10 h-[70vh] overflow-auto space-y-6 p-9">
                    {digitInNoiseArray.map((digit, index) => (
                        <div
                            key={digit._id}
                            className="bg-white shadow-lg rounded-xl hover:shadow-2xl transition-shadow p-8"
                        >
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                                {t('dinresultdashboard_din_test_result')} {index + 1}
                            </h2>
                            {/* Grid layout for the details */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 mb-6 text-gray-600">
                                <div>
                                    <strong className="text-gray-900">{t('dinresultdashboard_test_started')}:</strong>{' '}
                                    {digit.testStartTimestamp
                                        ? new Date(digit.testStartTimestamp).toLocaleString()
                                        : ''}
                                </div>
                                <div>
                                    <strong className="text-gray-900">{t('dinresultdashboard_test_ended')}:</strong>{' '}
                                    {digit.testEndTimestamp
                                        ? new Date(digit.testEndTimestamp).toLocaleString()
                                        : ''}
                                </div>
                            </div>


                            {/* Expandable Settings Section */}
                            <div className="mb-4">
                                <button
                                    onClick={() => toggleExpandSection(`settings-${index}`)}
                                    className="flex items-center text-blue-600 font-semibold"
                                >
                                    <span>{t('dinresultdashboard_test_parameters')}</span>
                                    <svg
                                        className={`w-5 h-5 ml-2 transition-transform ${expandedSections[`settings-${index}`] ? 'rotate-180' : 'rotate-0'}`}
                                        fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>

                                {expandedSections[`settings-${index}`] && (
                                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-1 ml-5 mt-4 text-gray-600 border-l-2 pl-4 border-gray-200">
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_adaptive_tests')}:</strong> {digit.settings?.adaptiveTest || ''}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_static_tests_completed')}:</strong> {digit.settings?.muutumatonTest || ''}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_left_calibration_value')}:</strong> {digit.settings?.leftCalibration || '0'}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_right_calibration_value')}:</strong> {digit.settings?.rightCalibration || '0'}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_number_list')}:</strong> {digit.settings?.numberList || ''}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_noise_level')} (dB):</strong> {digit.settings?.noiseDB || ''}
                                        </li>
                                        <li>
                                            <strong className="text-gray-900">{t('dinresultdashboard_side')}:</strong> {digit.settings?.earsSetting ? digit.settings.earsSetting.join(', ') : ''}
                                        </li>
                                    </ul>
                                )}
                            </div>


                            {/* Expandable questionHistory section */}
                            <div className="mb-4">
                                {/* <strong className="text-gray-900">DIN testit:</strong> */}
                                {digit.questionHistory.map((history, historyIndex) => {
                                    const totalQuestions = history.value.length;
                                    const correctAnswers = history.value.filter(question => question.correct).length;
                                    const correctPercentage = ((correctAnswers / totalQuestions) * 100).toFixed(2);
                                    // Prepare data for graph
                                    const speechDBData = history.value.map((question) => question.speechDB - 65);
                                    const questionNumbers = history.value.map((_, index) => index + 1);
                                    // Skip graph for specific name pattern
                                    const shouldRenderGraph = !history.name.includes('Muutumaton');
                                    /* For Logistic */
                                    const xData = history.value.map((question) => question.speechDB - 65);
                                    const yData = history.value.map((question) => question.correct ? 1 : 0);
                                    console.log({ totalQuestions, correctAnswers, speechDBData, questionNumbers, xData, yData });
                                    if (xData.length < 2)
                                        return null;

                                    return (
                                        <div key={history._id} className="mt-4">

                                            {/* Muutumaton results */}
                                            <div className="flex">
                                                <button
                                                    onClick={() => toggleExpandSection(`${index}-${historyIndex}`)}
                                                    className="flex items-center text-blue-600 font-semibold"
                                                >
                                                    <span>{history.name}</span>
                                                    {expandedSections[`${index}-${historyIndex}`] ? (
                                                        <FaChevronUp className="ml-2" />
                                                    ) : (
                                                        <FaChevronDown className="ml-2" />
                                                    )}

                                                    {/* Results: Total, Correct, and Percentage */}
                                                    <div className="flex items-center ml-4 text-gray-600">
                                                        <FaCalculator className="mr-1" />
                                                        <span>{totalQuestions} {t('dinresultdashboard_rounds')}</span>, &nbsp;
                                                        <span>{correctAnswers} {t('dinresultdashboard_correct')}</span>, &nbsp;
                                                        <span>{correctPercentage}%</span>, &nbsp;
                                                    </div>
                                                </button>

                                                {/* Graph Toggle Button */}
                                                {shouldRenderGraph && (
                                                    <button
                                                        onClick={() => toggleExpandSection(`graph-${index}-${historyIndex}`)}
                                                        className="flex items-center text-green-600 font-semibold ml-4"
                                                    >
                                                        <FaChartBar className="mr-2" />
                                                        <span>{t('dinresultdashboard_graph')}</span>
                                                        {expandedSections[`graph-${index}-${historyIndex}`] ? (
                                                            <FaChevronUp className="ml-2" />
                                                        ) : (
                                                            <FaChevronDown className="ml-2" />
                                                        )}
                                                    </button>
                                                )}
                                            </div>


                                            {/* Expandable Graph Section */}
                                            {expandedSections[`graph-${index}-${historyIndex}`] && shouldRenderGraph && (
                                                <div className="ml-6 mt-4">
                                                    <SpeechDBChart questionNumbers={questionNumbers} speechDBData={speechDBData} />
                                                    <LogisticChart
                                                        xData={xData}
                                                        yData={yData}
                                                        title="Logistic Fit "
                                                        buttonAction={() => { }}
                                                    />
                                                </div>
                                            )}

                                            {expandedSections[`${index}-${historyIndex}`] && (
                                                <div className="ml-6 mt-4 border-l-2 pl-4 border-gray-200">
                                                    <div className="overflow-auto">
                                                        <table className="w-full mt-2 border-collapse border border-gray-300 text-sm">
                                                            <thead>
                                                                <tr className="bg-gray-100">
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_question')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_correct')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_presentation_level')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_triplet_start')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_triplet_end')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_next_pressed')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_first_number')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_channel')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_test_name')}</th>
                                                                    <th className="border border-gray-300 p-2">{t('dinresultdashboard_number_list')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {history.value.map((question, questionIndex) => {
                                                                    const timeStarted = question.timeDigitStartedPlaying
                                                                        ? new Date(question.timeDigitStartedPlaying).getTime()
                                                                        : null;

                                                                    return (
                                                                        <tr key={question._id || questionIndex}>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {t('dinresultdashboard_question_label')} {questionIndex + 1}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {question.correct ? t('dinresultdashboard_yes') : t('dinresultdashboard_no')}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">{question.speechDB || ''}</td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {question.timeDigitStartedPlaying
                                                                                    ? new Date(question.timeDigitStartedPlaying).toISOString()
                                                                                    : ''}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {question.timeDigitStopPlaying && timeStarted !== null
                                                                                    ? `${new Date(question.timeDigitStopPlaying).getTime() - timeStarted} ms`
                                                                                    : ''}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {question.timeDigitAnswered && timeStarted !== null
                                                                                    ? `${new Date(question.timeDigitAnswered).getTime() - timeStarted} ms`
                                                                                    : ''}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {question.timeFirstNumberPressed && timeStarted !== null
                                                                                    ? `${new Date(question.timeFirstNumberPressed).getTime() - timeStarted} ms`
                                                                                    : ''}
                                                                            </td>
                                                                            <td className="border border-gray-300 p-2">{question.channel || ''}</td>
                                                                            <td className="border border-gray-300 p-2">{question.testName || ''}</td>
                                                                            <td className="border border-gray-300 p-2">
                                                                                {Array.isArray(question.numberList)
                                                                                    ? question.numberList.join(', ')
                                                                                    : question.numberList || ''}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="mt-10 text-gray-600">No digit noise results available</p>
            )}
        </div>
    );
};

export default DinResult;
