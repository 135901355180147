import React from "react";

const renderTextWithBreaks = (text) => {
    // Split by a delimiter (". " for sentences), or choose another as needed
    const lines = text.split('. ');
    return lines.map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index < lines.length - 1 && <br />}
        </React.Fragment>
    ));
};

export default renderTextWithBreaks;