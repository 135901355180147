import React, { useEffect, useState } from 'react';
import PractionerDashboardWrapper from './PractionerDashboardWrapper';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isValidJSON from '../../Functions/IsValidJSON';
import './Expandable.css';
import axios from 'axios';
import { setSelectedPatient } from '../../Slices/selectedPatientSlice';
import { setSelectedNavbarNavigationIndex } from '../../Slices/sideBarSlice';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { generateStats } from '../Modules/LogisticeModule/LogisticHelper';
import DigitInNoiseChart from '../Modules/LogisticeModule/DigitInNoiseChart';
import HierarchicalScatterPlot from '../Modules/LogisticeModule/HierarchicalScatterPlot';
import { data1, data2, testData } from './dummyData';
import { RightEarWithHearingAidIcon } from '../../assets/Icons';
import DualLineChartD3 from '../Modules/LogisticeModule/DualLineChartD3';
import AudiogramChart from '../Modules/LogisticeModule/AudiogramChart';

const PractionerSummaryDashboard = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const tokenFromStorage = localStorage.getItem('token');
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);
    const selectedPatient = useSelector((state) => state.selectedPatientState.selectedPatient) || "";
    const [patients, setPatients] = useState([]);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [isExpanding, setIsExpanding] = useState(false);
    const [isExpandingClosing, setIsExpandingClosing] = useState(false);
    const [digitInNoiseArray, setDigitInNoiseArray] = useState([]);
    const [stats, setStats] = useState(null);
    const [muutumatonData, setMuutumatonData] = useState([]);
    const [adaptiveData, setAdaptiveData] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    console.log({ digitInNoiseArray, stats, width });

    const openExpansion = (data = true) => {
        console.log(`1`);

        setIsExpanding(true);
        setExpanded(true);
        setTimeout(() => {
            setIsExpanding(false);
        }, 400); // Match the CSS animation duration
    };

    const closeExpansion = (data = true) => {
        console.log(`0`);
        setIsExpandingClosing(true);
        setTimeout(() => {
            setIsExpandingClosing(false);
            setExpanded(false);
        }, 400); // Match the CSS animation duration
    };


    const toggleExpand = (id) => {
        setExpanded(expanded === id ? null : id);
    };

    function convertToLocalTime(utcTimestamp, targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
        try {
            const date = new Date(utcTimestamp);
            const options = {
                timeZone: targetTimeZone, // Target timezone (defaults to local timezone)
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,
            };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        } catch (error) {
            return 'N/A';
        }
    }

    // Example Usage
    const utcTimestamp = "2024-10-28T07:34:37.247Z";
    const localTime = convertToLocalTime(utcTimestamp);
    console.log("Local Time:", localTime); // Automatically converts to your local timezone

    const finnishTime = convertToLocalTime(utcTimestamp, "Europe/Helsinki");
    console.log("Finnish Time:", finnishTime); // Conv

    /* Handle SelectedPatient */
    const handleSelectedPatient = (patient) => {
        console.log(patient);
        dispatch(setSelectedPatient(patient));
    };

    console.log({ patients });
    useEffect(() => {
        const fetchUsers = async (invoke = false) => {
            try {
                if (invoke === true && search === '') return;
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/patient/searchUsers?search=${search}&page=${page}&limit=${limit}`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const { data } = response;
                if (data.status === true) setPatients(data.users);
            } catch (error) {
                console.log(`[+] Error FetchUsers:`, error.message);
            }
        };

        fetchUsers();
    }, [page]);

    useEffect(() => {
        // Function to update the width
        const handleResize = () => setWidth(window.innerWidth);

        // Add event listener on component mount
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /* Check if there is selected patient or not */
    useEffect(() => {
        dispatch(setSelectedNavbarNavigationIndex(1));
        setDigitInNoiseArray((selectedPatient.digitInNoise || []).slice().reverse()); // Create a shallow copy and reverse
    }, []);
    // Usage in your component:
    useEffect(() => {
        try {
            const stats = generateStats(digitInNoiseArray);
            // Prepare separate datasets
            const muutumaton = stats.map(item => ({
                date: item.date,
                value: item.Muutumaton !== null ? parseFloat(item.Muutumaton) : null
            })).filter(item => item.value !== null); // Filter out null values

            const adaptive = stats.map(item => ({
                date: item.date,
                value: item.Adaptive !== null ? parseFloat(item.Adaptive) : null
            })).filter(item => item.value !== null); // Filter out null values

            setMuutumatonData(muutumaton);
            setAdaptiveData(adaptive);
        } catch (error) {
            console.error(`[+] Error:`, error.message);
            setMuutumatonData([]);
            setAdaptiveData([]);
        }
    }, [digitInNoiseArray]);

    if (!selectedPatient)
        return <Navigate to="/PractionerDashboard" replace />;

    return (
        <PractionerDashboardWrapper>
            <div className="px-5 py-5">
                {/* Larger screen */}
                <div className="hidden lg:flex xl:flex 2xl:flex overflow-auto h-[600px] md:h-full lg:h-full shadow-sm rounded-lg border  w-[470px] md:w-full lg:w-full ">
                    {/* Button to view more details about patient */}
                    <div className="border-b text-gray-600 w-full px-2 py-2 flex items-center">
                        <button
                            onClick={() => expanded === false ? openExpansion() : closeExpansion()}
                            className="w-full text-left p-4 bg-gray-100 font-semibold   flex items-center justify-between"
                        >
                            {selectedPatient.firstName} {selectedPatient.lastName}
                            {/* {expanded ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />} */}
                        </button>
                        <p className='font-bold'>{convertToLocalTime(selectedPatient.latestVisitation) || ''}</p>
                        {/* {(
                            <div className={`p-4 bg-white shadow rounded-lg text-sm toggleopen ${expanded !== false ? 'toggleopen' : 'toggleclose'} ${isExpanding ? 'toggle-expansion-opening' : ''} ${isExpandingClosing ? 'toggle-expansion-closing' : ''} `}

                            >
                                <p className=" font-semibold">{selectedPatient.firstName} {selectedPatient.lastName}</p>
                                <p className="text-gray-600">SSN: {selectedPatient.socialSecurityNumber}</p>
                                <p className="text-gray-600">ID: {selectedPatient._id}</p>
                                <p className="text-gray-600">Latest Visitation: {convertToLocalTime(selectedPatient.latestVisitation)}</p>
                            </div>
                        )} */}
                    </div>
                </div>


                {/*accordian list to display the use  */}
                <div className="flex flex-col lg:hidden md:flex xl:hidden 2xl:hidden space-y-4 p-0 overflow-auto ">
                    <div className="border-b text-gray-600  px-0 py-2">
                        <button
                            onClick={() => expanded === false ? openExpansion() : closeExpansion()}
                            className="w-full text-left p-4 bg-gray-100 font-semibold   flex items-center justify-between"
                        >
                            {selectedPatient.firstName} {selectedPatient.lastName}
                            {expanded ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
                        </button>
                        {(
                            <div className={`p-4 bg-white shadow rounded-lg text-sm toggleopen ${expanded !== false ? 'toggleopen' : 'toggleclose'} ${isExpanding ? 'toggle-expansion-opening' : ''} ${isExpandingClosing ? 'toggle-expansion-closing' : ''} `}

                            >
                                <p className=" font-semibold">{selectedPatient.firstName} {selectedPatient.lastName}</p>
                                <p className="text-gray-600">SSN: {selectedPatient.socialSecurityNumber}</p>
                                <p className="text-gray-600">ID: {selectedPatient._id}</p>
                                <p className="text-gray-600">Latest Visitation: {convertToLocalTime(selectedPatient.latestVisitation)}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="px-0 py-4 flex flex-col sm:flex-row md:flex-col lg:flex-row gap-x-3 gap-y-4 ">
                    {/* <div className={`w-[60vw] md:w-[60vw] lg:w-[60vw] overflow-auto p-4 rounded-lg h-[50vh] sm:h-[60vh] md:h-[60vh] lg:h-[80vh] border bg-white shadow-md `}>
                        <DigitInNoiseChart data={adaptiveData} label="Muutumaton" title="Muutumaton Test Results" />
                    </div> */}
                    {/* <Hi */}
                    {/* Plot  */}
                    <div className='bg-white rounded-lg w-[400px] md:w-full lg:w-[760px] overflow-auto flex flex-1 justify-center items-center'>
                        <HierarchicalScatterPlot data={testData} width={760} height={440} />
                    </div>

                    <div className='flex gap-x-1 flex-col text-gray-700 gap-y-2'>
                        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
                            <div className='flex flex-col border-[2px] border-gray-200 px-4 py-6 rounded-xl gap-y-4'>
                                <div>
                                    <p className='text-[#2E90FA]'>
                                        Keskiarvo
                                    </p>
                                    <p className='text-2xl font-bold'>
                                        Kuulontasapaino
                                    </p>
                                </div>
                                <div className='flex gap-x-2 text-sm'>
                                    <div className='flex gap-x-2 bg-blue-500 px-4 font-bold py-2 rounded-2xl items-center text-white'>
                                        <p className='bg-blue-800 px-[6px] rounded-xl'>V</p>
                                        <p>28 dB HL</p>
                                    </div>
                                    <div className='flex gap-x-2 bg-red-500 px-4 font-bold py-1 rounded-2xl items-center text-white'>
                                        <p className='bg-red-800 px-[6px] rounded-xl'>O</p>
                                        <p>25 DBHL</p>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row gap-x-1 border-[2px] border-gray-200 px-4 py-4 rounded-xl gap-y-4 text-gray-700 justify-between'>
                                <div className='flex flex-col justify-between  h-full'>
                                    <div>
                                        <p className='text-[#2E90FA]'>
                                            Keskiarvo
                                        </p>
                                        <p className='text-2xl font-bold'>
                                            Kuulolaite
                                        </p>
                                    </div>
                                    <p className='text-sm'>
                                        Päivitetty maaliskuu 2023
                                    </p>
                                </div>
                                <div className=''>
                                    <img src={RightEarWithHearingAidIcon} alt='RH' className='w-[120px] h-[120px]' />
                                </div>

                            </div>
                        </div>
                        <div className='bg-white rounded-lg  w-[400px]  lg:w-full overflow-auto '>
                            {/* Larger Screen Size chart */}
                            <div className='hidden md:flex lg:flex'>
                                <AudiogramChart
                                    data1={data1}
                                    data2={data2}
                                    width={600}
                                    height={300}
                                    label1="Left Ear"
                                    label2="Right Ear"
                                />
                            </div>
                            {/* Smaller Screen Size audio gram chart */}
                            <div className='flex md:hidden lg:hidden'>
                                <AudiogramChart
                                    data1={data1}
                                    data2={data2}
                                    width={390}
                                    height={340}
                                    label1="Left Ear"
                                    label2="Right Ear"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PractionerDashboardWrapper>
    );
};

export default PractionerSummaryDashboard;
