import React, { useState } from 'react';
import { FINFlagIcon, FRIcon, UKFlagIcon } from '../../assets/Icons';
import useLanguage from '../../hooks/useLanguage';

function LanguageSwitcher() {
    const { language, changeLanguage } = useLanguage();
    const [showDropdown, setShowDropdown] = useState(false);

    // Language options
    const languages = [
        { code: 'en', label: 'English', icon: UKFlagIcon },
        { code: 'fi', label: 'Finnish', icon: FINFlagIcon },
        { code: 'fr', label: 'French', icon: FRIcon },
        // Add more languages here as needed
    ];

    const handleLanguageChange = (langCode) => {
        changeLanguage(langCode);
        setShowDropdown(false); // Close the dropdown after selecting a language
    };

    return (
        <div className="relative">
            <div
                className='absolute z-40 right-16 top-1 cursor-pointer'
                onClick={() => setShowDropdown(!showDropdown)}
            >
                {/* Show current language flag */}
                <img
                    src={
                        languages.find((lang) => lang.code === language)?.icon || UKFlagIcon
                    }
                    className="size-16 rounded-lg"
                    alt={`${language} flag`}
                />

                {showDropdown && (
                    <div className="absolute top-14 right-2 mt-2 px-6 py-2  bg-white border border-gray-300 rounded-lg shadow-lg z-50 w-[200px] h-[210px] overflow-auto">
                        {languages.map((lang) => (
                            <div
                                key={lang.code}
                                className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200 gap-x-5"
                                onClick={() => handleLanguageChange(lang.code)}
                            >
                                <img src={lang.icon} alt={`${lang.label} flag`} className="" />
                                <span>{lang.label}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default LanguageSwitcher;
