import React, { useState, useEffect } from "react";
//import { AbortIcon, CornerDownRightIcon, CrossIcon, InfoIcon, PauseIcon, ReplayIcon, ResumeIcon, SoundIcon } from '../../../assets/Icons'; // Assuming SoundIcon is imported correctly
import QuestionTitle from "./QuestionTitle";
import RatingScale from "./RatingScale";
import NavigationBar from "./NavigationBar";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import isValidJSON from "../../../Functions/IsValidJSON";
import { useTranslation } from "react-i18next";


function KuuloKysely() {
  const { t } = useTranslation();
  const questions = [
    { id: 1, text: t("prom_1") },
    { id: 2, text: t("prom_2") },
    { id: 3, text: t("prom_3") },
    { id: 4, text: t("prom_4") },
    { id: 5, text: t("prom_5") },
    { id: 6, text: t("prom_6") },
    { id: 7, text: t("prom_7") },
    { id: 8, text: t("prom_8") },
    { id: 9, text: t("prom_9") },
    { id: 10, text: t("prom_10") },
    { id: 11, text: t("prom_11") },
    { id: 12, text: t("prom_12") },
    { id: 13, text: t("prom_13") },
    { id: 14, text: t("prom_14") },
    { id: 15, text: t("prom_15") },
    { id: 16, text: t("prom_16") }

  ];
  
  const answerDescriptions = {
    1: { 1: t('prom_answer_1_1'), 10: t('prom_answer_1_10') },
    2: { 1: t('prom_answer_2_1'), 10: t('prom_answer_2_10') },
    3: { 1: t('prom_answer_3_1'), 10: t('prom_answer_3_10') },
    4: { 1: t('prom_answer_4_1'), 10: t('prom_answer_4_10') },
    5: { 1: t('prom_answer_5_1'), 10: t('prom_answer_5_10') },
    6: { 1: t('prom_answer_6_1'), 10: t('prom_answer_6_10') },
    7: { 1: t('prom_answer_7_1'), 10: t('prom_answer_7_10') },
    8: { 1: t('prom_answer_8_1'), 10: t('prom_answer_8_10') },
    9: { 1: t('prom_answer_9_1'), 10: t('prom_answer_9_10') },
    10: { 1: t('prom_answer_10_1'), 10: t('prom_answer_10_10') },
    11: { 1: t('prom_answer_11_1'), 10: t('prom_answer_11_10') },
    12: { 1: t('prom_answer_12_1'), 10: t('prom_answer_12_10') },
    13: { 1: t('prom_answer_13_1'), 10: t('prom_answer_13_10') },
    14: { 1: t('prom_answer_14_1'), 10: t('prom_answer_14_10') },
    15: { 1: t('prom_answer_15_1'), 10: t('prom_answer_15_10') },
    16: { 1: t('prom_answer_16_1'), 10: t('prom_answer_16_10') }
  };
  

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedRating, setSelectedRating] = useState(null); // Track selected rating
  const [answers, setAnswers] = useState([]); // Store answers
  const [testStartTimestamp, setTestStartTimestamp] = useState(null); // Store test start time
  // const answersRef = useRef([])

  const tokenFromStorage = localStorage.getItem("token");
  const token =
    useSelector(state => state.authState.token) ||
    (tokenFromStorage && isValidJSON(tokenFromStorage)
      ? JSON.parse(tokenFromStorage)
      : null);

  // Set the test start time when the component is first mounted
  useEffect(() => {
    setTestStartTimestamp(new Date()); // Set test start time when the component mounts
  }, []);

  const nextQuestion = () => {
    if (selectedRating) {
      // Store the answer
      const updatedAnswers = [
        ...answers,
        {
          questionID: questions[currentQuestionIndex].id,
          answer: selectedRating
        }
      ];
      setAnswers(updatedAnswers);
      //answersRef.current = updatedAnswers

      if (currentQuestionIndex < questions.length - 1) {
        // Move to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedRating(null); // Reset rating for the next question
      }
    } else {
      toast.warning("Valitse .");
    }
  };

  // Submit all answers to the backend once all 15 questions are answered
  const submitAnswers = async answers => {
    const testEndTimestamp = new Date(); // Set the test end time when submitting
    const payload = {
      testStartTimestamp: testStartTimestamp, // Adjust as per your logic
      testEndTimestamp: testEndTimestamp, // Adjust as per your logic
      questionHistory: answers
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/questionnaire",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(payload)
        }
      );
      if (response.ok) {
        console.log("Answers submitted successfully");
        toast.success("Answers submitted successfully!");
      } else {
        throw new Error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error("Error submitting answers. Please try again.");
    }
  };

  const handleRatingSelect = rating => {
    setSelectedRating(rating);
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    //<main className="flex overflow-hidden flex-col items-center px-4 pt-4 pb-12 bg-gray-100">
    //  <section className="flex flex-col items-center mt-[468px] max-md:mt-10 max-md:max-w-full">
    <main className="h-screen bg-gray-100 overflow-y-auto flex flex-col justify-center items-center relative">
      <section className="inline-flex flex-col items-center gap-8 text-center w-full">
        <div className="box-border h-[20vh]flex justify-center items-center">
          <QuestionTitle text={currentQuestion.text} />
        </div>
        <div>
          <RatingScale
            //descriptions={answerDescriptions[currentQuestion.id]}
            onRatingSelect={handleRatingSelect}
            selectedRating={selectedRating}
          />
          {/* Descriptions Below Rating Scale */}
          <div className="flex justify-between w-full mt-8 px-0">
            <span className="text-xl text-gray-700">
              {answerDescriptions[currentQuestion.id][1]}
            </span>
            <span className="text-xl text-gray-700">
              {answerDescriptions[currentQuestion.id][10]}
            </span>
          </div>
        </div>
      </section>
      <div className="absolute center bottom-10">
        <NavigationBar
          onNext={nextQuestion}
          isNextEnabled={!!selectedRating}
          isLastQuestion={currentQuestionIndex === questions.length - 1}
          testName={"Kuulokysely"}
          currentQuestionIndex={currentQuestionIndex}
          totalQuestions={questions.length}
          submitAnswers={() =>
            submitAnswers([
              ...answers,
              { questionID: currentQuestion.id, answer: selectedRating }
            ])} // Ensure last answer is submitted
        />
      </div>
      <ToastContainer />
    </main>
  );
}

export default KuuloKysely;
