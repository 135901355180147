import React, { useEffect, useState } from 'react';
import { Lock_Icon } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setUser } from '../../../Slices/authSlice';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import isValidJSON from '../../../Functions/IsValidJSON';
import { useTranslation } from 'react-i18next';

const EditLoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const tokenFromStorage = localStorage.getItem('token');
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);

    const [loginInformation, setLoginInformation] = useState({
        firstName: '',
        lastName: '',
        idNumber: '',
        email: '',
        phoneNumber: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setLoginInformation({
            ...loginInformation,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, idNumber } = loginInformation;
        if (!firstName || !lastName || !idNumber) {
            //toast.error('ALL FIELDS REQUIRED', { position: 'top-right' });
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/patient/updateUser`,
                {
                    firstName,
                    lastName,
                    socialSecurityNumber: idNumber,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Replace `token` with the actual token variable
                    },
                }
            );


            const { data } = response;
            console.log({ data });
            if (data.status && data.status === false) {
                throw new Error(data.message || "Something went wrong");
            }
            // // Set the user and token in the Redux store
            dispatch(setUser(data.user));
            dispatch(setToken(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('token', JSON.stringify(data.token));
            //toast.success(data.message);
            // Ensure correct navigation after login
            navigate("/UserDetailsPage", { replace: true });
        } catch (error) {
            console.error("Error during login:", error.message);
            //toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setSubmitted(false);
        navigate('/UserDetailsPage');
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        console.log({ storedUser });
        console.log(storedUser === 'undefined');
        if (storedUser && storedUser !== 'undefined' && storedUser !== undefined && storedUser !== null) {
            const parsedUser = JSON.parse(storedUser);
            setLoginInformation({
                firstName: parsedUser.firstName || '',
                lastName: parsedUser.lastName || '',
                idNumber: parsedUser.idNumber || parsedUser.socialSecurityNumber || '',
                email: parsedUser.email || '',
                phoneNumber: parsedUser.phoneNumber || ''
            });
        }
    }, []);

    return (
        <div className='h-screen bg-gray-700 flex items-center justify-center'>
            <div className='bg-white p-6 rounded-lg shadow-lg w-80 relative'>
                <form onSubmit={handleSubmit}>
                    <ToastContainer />
                    <div className='flex justify-center'>
                        <img src={Lock_Icon} alt="Lock Icon" />
                    </div>
                    <h2 className='text-center text-xl text-[#101828] font-bold mt-4 mb-4'>
                        {t('editLogin_correct_your_info')}
                    </h2>
                    <p className='text-center mb-4'>{t('editLogin_fix_error_and_confirm')}</p>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>{t('editLogin_first_name')}</label>
                        <input
                            type='text'
                            name='firstName'
                            value={loginInformation.firstName}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder='Pekka'
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>{t('editLogin_last_name')}</label>
                        <input
                            type='text'
                            name='lastName'
                            value={loginInformation.lastName}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder='Potilas'
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>{t('editLogin_id_number')}</label>
                        <input
                            type='text'
                            name='idNumber'
                            value={loginInformation.idNumber}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder='020202-0202'
                        />
                        <small className='text-[#667085]'>{t('editLogin_id_placeholder')}</small>
                    </div>

                    <div className='flex flex-col mt-1'>
                        <button
                            type='submit'
                            className='w-full bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md'
                            disabled={loading}
                        >
                            {t('editLogin_confirm')}
                        </button>
                        <button
                            type='button'
                            className='transition-colors duration-300 ease-out w-full bg-gray-200 text-gray-700 p-2 rounded-md mt-2 hover:bg-gray-300'
                            onClick={handleCancel}
                        >
                            {t('editLogin_cancel')}
                        </button>
                    </div>
                </form>

                {submitted && (
                    <div className='mt-6'>
                        <h3 className='text-lg font-bold'>{t('editLogin_entered_info')}</h3>
                        <p><strong>{t('editLogin_first_name_display')}</strong> {loginInformation.firstName}</p>
                        <p><strong>{t('editLogin_last_name_display')}</strong> {loginInformation.lastName}</p>
                        <p><strong>{t('editLogin_id_number_display')}</strong> {loginInformation.idNumber}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditLoginPage;
