import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Help_Icon,
  BackgroundImageAudiogram
} from "../../../assets/Icons";

function AudiogramLandingPage() {
  const navigate = useNavigate();

  // Navigation handlers
  const handleStartClick = () => {
    navigate("/Audiogram2");
  };

  const handleCancelClick = () => {
    navigate("/TestLandingPage");
  };

  const handleHelpClick = () => {
    navigate("/HelpPage"); // Ensure this route exists
  };

  return (
    <div
      className="h-screen bg-gray-100 flex flex-col bg-cover bg-center bg-no-repeat sm:bg-contain"
      style={{
        backgroundImage: `url(${BackgroundImageAudiogram})`
      }}
    >
      {/* Top Left Bar: Peruuta Button and Help Icon */}
      <div className="fixed top-4 left-4 flex items-center space-x-4 z-30">
        {/* "Peruuta" Button with ArrowLeft Icon */}
        <button
          onClick={handleCancelClick}
          className="flex items-center space-x-2 "
        >
          <span className="flex items-center gap-3 px-3 py-3 lg:px-6 lg:py-4 lg:text-lg font-semibold bg-white rounded-xl border border-gray-300 shadow-sm text-slate-700 hover:bg-purple-400 transition-all duration-200">
            <img src={ArrowLeft} className="w-6 h-6" alt="Arrow Left" />
            Peruuta
          </span>
        </button>

        {/* Help Icon Button */}
        <button onClick={handleHelpClick} className="flex items-center">
          <img src={Help_Icon} className="w-20 h-20" alt="Help Icon" />
        </button>
      </div>

      {/* Start Button Positioned 100px Down and Right from Center */}
      <div className="absolute left-[-32px] lg:left-8 inset-0 flex items-center justify-center ">
        <button
          onClick={handleStartClick}
          className="flex items-center gap-3 px-3 py-3 lg:px-6 lg:py-4 text-sm lg:text-lg  font-semibold bg-purple-600 rounded-xl shadow-sm text-white hover:bg-purple-800 transition-all duration-200"
          style={{
            transform: "translate(100px, 100px)"
          }}
        >
          Siirry kuulokynnystestiin
        </button>
      </div>
    </div>
  );
}

export default AudiogramLandingPage;
