import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { setPractionerOpenSmallScreen, setSelectedNavbarNavigationIndex } from '../../Slices/sideBarSlice';
import { useNavigate } from 'react-router-dom';

const PractionerSharedNavbar = () => {
    const [menu, setMenu] = useState(false);
    const dispatch = useDispatch();
    const practionerOpen = useSelector((state) => state.sidebrState.practionerOpen);
    const practionerOpenSmallScreen = useSelector((state) => state.sidebrState.practionerOpenSmallScreen);
    const selectedNavbarNavigationIndex = useSelector((state) => state.sidebrState.selectedNavbarNavigationIndex);
    const selectedPatient = useSelector((state) => state.selectedPatientState.selectedPatient) || "";
    const navigate = useNavigate();
    console.log({ selectedPatient });
    /* Navbar links */
    const links = [
        { to: "Home", text: "Home" },
        { to: "Categories", text: "Categories" },
        { to: "Services", text: "Services" },
        { to: "More", text: "More" },
    ];
    // Array of navigation items with paths and indexes
    const navigations = [
        { name: 'Patients', path: '/PractionerDashboard', index: 0 },
        { name: 'Summary', path: '/PractionerSummaryDashboard', index: 1 },
        { name: 'DIN', path: '/PractionerDashboard', index: 2 },
        { name: 'Audiometry', path: '/PractionerDashboard', index: 3 }
    ];

    // Function to handle navigation click
    const handleNavigationClick = (index) => {
        try {
            if (!selectedPatient) return;

            const selectedNavigation = navigations[index]; // Get the selected navigation item

            dispatch(setSelectedNavbarNavigationIndex(index));
            navigate(selectedNavigation.path); // Navigate to the specific path
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = () => {
        if (practionerOpen === true)
            return;
        setMenu(!menu);
    };

    useEffect(() => {
        if (practionerOpen === true)
            setMenu(false);
    }, [practionerOpen]);

    return (
        <div className='relative z-40'>
            <div className='hidden lg:flex flex-col'>
                <div className='hidden lg:flex items-center justify-between px-2 py-4 '>
                    <div>
                        <p className='text-sm lg:text-5xl font-bold text-gray-700'>Dashboard</p>
                    </div>
                    <div className='cursor-pointer flex items-center gap-x-4'>
                        <button className='px-6 py-3  bg-blue-100  rounded-lg text-sm lg:text-[20px] text-blue-700 transition-all duration-300 transform hover:scale-105 hover:bg-blue-200 hover:text-blue-800'>
                            Luo Lähaete
                        </button>
                        <button className='px-6 py-3 text-sm lg:text-[20px] rounded-lg bg-blue-500 text-white transition-all duration-300 transform hover:scale-105 hover:bg-blue-600'>
                            Määrää kuulotesti
                        </button>
                    </div>
                </div>
                {/* Navigations */}
                <div className='px-3 text-2xl flex gap-x-6 cursor-pointer text-gray-500'>
                    {navigations.map((item, index) => (
                        <div
                            key={index}
                            className={`py-3 transition-all duration-300 transform hover:scale-105 ${selectedNavbarNavigationIndex === index
                                ? 'border-b-[2px] border-blue-500 text-blue-500'
                                : 'text-gray-500'
                                }`}
                            onClick={() => handleNavigationClick(index)}
                        >
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
                <div className='px-2'>
                    <div className='w-full border-b-[2px] border-gray-200' />
                </div>
            </div>

            {/* Menu Icon to display open the drop down in small screen */}
            <div className="lg:hidden flex justify-end items-center gap-1 px-4 ">
                <div className='flex items-center gap-x-2'>
                    <p className='text-3xl font-bold' onClick={(e) => dispatch(setPractionerOpenSmallScreen(!practionerOpenSmallScreen))}>Dashbaord</p>
                    {menu ? (
                        <AiOutlineClose
                            size={20}
                            onClick={handleChange}
                            className="text-lg size-8 cursor-pointer"
                        />
                    ) : (
                        <AiOutlineMenu
                            size={20}
                            onClick={handleChange}
                            className="text-lg size-8 cursor-pointer"
                        />
                    )}
                </div>
            </div>
            {/* The dropdown for small size */}
            <div
                className={` ${menu ? "translate-y-0" : "-translate-y-80"} 
        lg:hidden flex flex-col absolute bg-gray-200 text-black left-0  font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  pt-8 pb-4 px-4 gap-5 w-full h-fit transition-transform duration-300 rounded-xl`}
            >
                <ul className="">

                    {navigations.map((item, index) => (
                        <div
                            key={index}
                            className={`text-[16px] font-bold pt-[4px] transition-colors duration-300 ease-out hover:text-black cursor-pointer ${selectedNavbarNavigationIndex === index
                                ? 'border-b-[2px] border-blue-500 text-blue-500'
                                : 'text-gray-500'
                                }`}
                            onClick={() => handleNavigationClick(index)}
                        >
                            <p>{item.name}</p>
                        </div>
                    ))}

                </ul>

            </div>
        </div>
    );
};

export default PractionerSharedNavbar;