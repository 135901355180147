import { createSlice } from "@reduxjs/toolkit";
//import { HeadPhoneIcon, SpeakersIcon } from "../assets/Icons/";

import SpeakersIcon from '../assets/Icons/speaker.png'; // Import as image path
import HeadPhoneIcon from '../assets/Icons/headphones.png'; // Import as image path
import Edit from '../assets/Icons/edit.png'; // Import as image path


/* Making the soundTest Variable globlally accessible */
export const soundSlice = createSlice({
    name: "soundtest",
    initialState: {
        soundTestvold: [
            // ADD the new tests/names also to the MainSettingsPage
            { name: 'Hälypuhetesti', duration: '20 min', navigate: '/DinTestV6With1Speaker', completed: false, included: false, icon: SpeakersIcon },
            { name: 'Hälypuhetesti 2', duration: '20 min', navigate: '/DinTestV10Headphones', completed: false, included: true, icon: HeadPhoneIcon },
            { name: 'Hälypuhetestin kysely', duration: '3 min', navigate: '/QuestionnaireDIN', completed: false, included: true, icon: Edit },
            { name: 'Kuulokysely', duration: '5 min', navigate: '/KuuloKyselyLandingPage', completed: false, included: true, icon: Edit },
            { name: 'Kuulokyselyn kysely', duration: '3 min', navigate: '/QuestionnairePROM', completed: false, included: true, icon: Edit },
            { name: 'Kuulokkeiden sovitus', duration: '2 min', navigate: '/Kuulokkeiden', completed: false, included: true, icon: HeadPhoneIcon },
            { name: `Kuulokynnys`, duration: '15 min', navigate: '/AudiogramLandingPage', completed: false, included: true, icon: HeadPhoneIcon },
            { name: 'Kuulokynnyksen kysely', duration: '3 min', navigate: '/QuestionnaireAUD', completed: false, included: true, icon: Edit },
            { name: 'Loppukysely', duration: '5 min', navigate: '/QuestionnaireFINISH', completed: false, included: true, icon: Edit },
            { name: 'Slider kuulokynnys', duration: '5 min', navigate: '/SliderAudiogram2', completed: false, included: false, icon: HeadPhoneIcon },
            { name: 'Hälypuhetesti (Antifaasinen) ', duration: '20 min', navigate: '/HalyPuheTestiWithAntiPhasic', completed: false, included: false, icon: HeadPhoneIcon },
            //{ name: 'Smart Audiogram', duration: '20min', navigate: '/TestLandingPage', completed: false, included: true  },
        ],
        soundTest: [
            { name: 'soundSlice_emergencySpeechTest', duration: '20 min', navigate: '/DinTestV6With1Speaker', completed: false, included: false, icon: SpeakersIcon },
            { name: 'soundSlice_emergencySpeechTest2', duration: '20 min', navigate: '/DinTestV10Headphones', completed: false, included: true, icon: HeadPhoneIcon },
            { name: 'soundSlice_emergencySpeechSurvey', duration: '3 min', navigate: '/QuestionnaireDIN', completed: false, included: true, icon: Edit },
            { name: 'soundSlice_hearingSurvey', duration: '5 min', navigate: '/KuuloKyselyLandingPage', completed: false, included: true, icon: Edit },
            { name: 'soundSlice_hearingSurveySurvey', duration: '3 min', navigate: '/QuestionnairePROM', completed: false, included: true, icon: Edit },
            { name: 'soundSlice_headsetFitting', duration: '2 min', navigate: '/Kuulokkeiden', completed: false, included: true, icon: HeadPhoneIcon },
            { name: 'soundSlice_hearingThreshold', duration: '15 min', navigate: '/AudiogramLandingPage', completed: false, included: true, icon: HeadPhoneIcon },
            { name: 'soundSlice_hearingThresholdSurvey', duration: '3 min', navigate: '/QuestionnaireAUD', completed: false, included: true, icon: Edit },
            { name: 'soundSlice_finalSurvey', duration: '5 min', navigate: '/QuestionnaireFINISH', completed: false, included: true, icon: Edit },
            { name: 'soundSlice_sliderThreshold', duration: '5 min', navigate: '/SliderAudiogram2', completed: false, included: false, icon: HeadPhoneIcon },
            { name: 'soundSlice_antiphaseSpeechTest', duration: '20 min', navigate: '/HalyPuheTestiWithAntiPhasic', completed: false, included: false, icon: HeadPhoneIcon }
        ],
        soundTestIndex: null,
    },
    reducers: {
        setSoundTest: (state, action) => {
            state.soundTest = action.payload;
        },
        setSoundTestIndex: (state, action) => {
            state.soundTestIndex = action.payload;
        },
        // Add new reducers for including/excluding tests
        toggleTestInclusion: (state, action) => {
            const testName = action.payload;
            state.soundTest = state.soundTest.map((test) =>
                test.name === testName ? { ...test, included: !test.included } : test
            );
        },
        includeTest: (state, action) => {
            const testName = action.payload;
            state.soundTest = state.soundTest.map((test) =>
                test.name === testName ? { ...test, included: true } : test
            );
        },
        excludeTest: (state, action) => {
            const testName = action.payload;
            state.soundTest = state.soundTest.map((test) =>
                test.name === testName ? { ...test, included: false } : test
            );
        },
        /* Testing by completing it 
        completeRandomTest: (state) => {
            const incompleteTests = state.soundTest.filter(test => !test.completed);
            if (incompleteTests.length > 0) {
                const randomIndex = Math.floor(Math.random() * incompleteTests.length);
                const testToComplete = incompleteTests[randomIndex];
                const indexToUpdate = state.soundTest.findIndex(test => test.name === testToComplete.name);
                if (indexToUpdate !== -1) {
                    state.soundTest[indexToUpdate].completed = true;
                }
            }
        }*/
    },
});

// Action creators are generated for each case reducer function
export const { setSoundTest, setSoundTestIndex, toggleTestInclusion, includeTest, excludeTest, } = soundSlice.actions;

export default soundSlice.reducer;