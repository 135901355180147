// src/store/languageSlice.js
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n';


export const languageSlice = createSlice({
    name: 'language',
    initialState: { language: i18n.language || 'fi' }, // Default to Finnish if no language is set
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            i18n.changeLanguage(action.payload); // Also change language in i18next
        },
    },
});

export const { setLanguage } = languageSlice.actions;
// export default languageSlice.reducer;
