import React, { useState } from 'react';
import { FINFlagIcon, FRIcon, UKFlagIcon } from '../../assets/Icons';
import useLanguage from '../../hooks/useLanguage';

function LanguageSwitcherButton({ className = '' }) {
    const { language, changeLanguage } = useLanguage();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    // Language options
    const languages = [
        { code: 'en', label: 'English', icon: UKFlagIcon },
        { code: 'fi', label: 'Finnish', icon: FINFlagIcon },
        { code: 'fr', label: 'French', icon: FRIcon },
    ];

    const currentLanguage = languages.find((lang) => lang.code === language) || languages[0];

    const handleLanguageChange = (langCode) => {
        changeLanguage(langCode);
        setShowDropdown(false); // Close the dropdown after selecting a language
    };

    return (
        <div className={`relative ${className}`}>
            <div
                className='relative flex justify-center items-center cursor-pointer'
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown on click
            >
                <img
                    src={currentLanguage.icon}
                    className='size-16 transition-colors duration-300 ease-in-out hover:bg-gray-400 rounded-lg'
                    alt={`${currentLanguage.label} flag`}
                />
                {showTooltip && (
                    <div className='absolute mb-0 top-[101%] left-[50%] transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-md py-2 px-2 opacity-100 transition-opacity duration-300 ease-in-out'>
                        {currentLanguage.label}
                    </div>
                )}
            </div>

            {/* Dropdown Menu */}
            {showDropdown && (
                <div className="absolute top-10 right-0 mt-2 px-6 py-2 bg-white border border-gray-300 rounded-lg shadow-lg z-50 w-[200px] h-[210px] overflow-auto">
                    {languages.map((lang) => (
                        <div
                            key={lang.code}
                            className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200 gap-x-5"
                            onClick={() => handleLanguageChange(lang.code)}
                        >
                            <img src={lang.icon} alt={`${lang.label} flag`} />
                            <span>{lang.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LanguageSwitcherButton;
