import React, { useState } from 'react';
import { CornerDownRightIcon, Help_Icon, InfoIcon, NextIcon, SquareIcon} from '../../../assets/Icons'; // Assuming Icons are imported correctly
import { useNavigate } from 'react-router-dom';
import AbortPage from '../../Modals/ModalAbortTest'; // Assuming this is the abort modal component
import HelpPageModal from '../../Modals/ModalHelpPage'; // Assuming this is the help modal component
import { useDispatch, useSelector } from 'react-redux';
import { updateTestStatusByName } from '../../../Functions/upDateSoundTestStatus';
import { setSoundTest } from '../../../Slices/soundSilce';
import { useTranslation } from 'react-i18next';


function NavigationBar({onNext, isNextEnabled, pauseTest, submitAnswers, isLastQuestion, testName, currentQuestionIndex, totalQuestions }) {
  const navigate = useNavigate();
  const [isAbortModalOpen, setIsAbortModalOpen] = useState(false); // Manage abort modal visibility
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Manage help modal visibility
  const dispatch = useDispatch();
  const soundTest = useSelector((state) => state.soundState.soundTest);
  const {t}=useTranslation()

  // Handle click for the final action
  const handleFinalClick = () => { console.log(isLastQuestion)
    if (isLastQuestion) {
      submitAnswers(); // Submit the answers if this is the last question
      updateTestStatusByName(soundTest, testName, dispatch, setSoundTest, );
      navigate('/TestLandingPage')
    } else {
      onNext(); // Continue to the next question if it's not the last
    }
  };

  // Handle opening the abort modal and pausing the test
  const handleAbortClick = () => {
    setIsAbortModalOpen(true); // Show the abort modal
  };

  // Handle opening the help modal and pausing the test
  const handleHelpClick = () => {
    setIsHelpModalOpen(true); // Show the help modal
  };

  // Handle closing the abort modal
  const handleCloseAbortModal = () => {
    setIsAbortModalOpen(false); // Hide the abort modal
  };

  // Handle closing the help modal
  const handleCloseHelpModal = () => {
    setIsHelpModalOpen(false); // Hide the help modal
  };

  return (
    <>
  <nav className="hidden lg:flex overflow-hidden flex-col justify-center max-w-full bg-gray-100 rounded-xl border border-gray-300 border-solid h-[72px] w-[840px] md:w-[720px] sm:w-full max-sm:w-full max-md:mt-10">
<div className="flex flex-wrap gap-4 md:gap-10 justify-between items-center px-2 py-2 bg-white w-full " >
    
    {/* Left Buttons */}
    <div className="flex gap-2 items-center">

      {/* Keskeytä (Abort) Button */}
      <button
        onClick={handleAbortClick}
        className="flex items-center gap-3 md:gap-3 px-4 md:px-6 py-2 md:py-4 text-lg md:text-xl font-semibold whitespace-nowrap bg-white rounded-xl border border-gray-300 shadow-sm text-slate-700  hover:bg-gray-400 transition-all duration-200 max-md:w-1/2"
      >
        <img
          src={SquareIcon}
          alt=""
          className="object-contain w-6 h-6 aspect-square max-sm:w-5 max-sm:h-5"
        />
        <span className="text-lg md:text-xl font-semibold tracking-wider text-slate-700">
          {t('PromNavigationBar_Cancel')}
        </span>
      </button>

      {/* Apua (Help) Button */}
      <button
        onClick={handleHelpClick}
        className="flex items-center gap-3 md:gap-4 px-4 md:px-6 py-2 md:py-4 text-lg md:text-xl font-semibold whitespace-nowrap bg-white rounded-xl border border-gray-300 shadow-sm  hover:bg-gray-400 text-slate-700 transition-all duration-200 max-md:w-1/2"
      >
        <img
          src={InfoIcon}
          alt=""
          className="object-contain w-6 h-6 aspect-square max-sm:w-5 max-sm:h-5"
        />
        <span className="text-lg md:text-xl font-semibold tracking-wider text-slate-700">
          {t('PromNavigationBar_Help')}
        </span>
      </button>
    </div>

    {/* Current Question Display */}
    <div className="text-sm font-bold text-center text-black uppercase tracking-wider md:text-base max-sm:text-sm">
      {currentQuestionIndex + 1}/{totalQuestions}
    </div>

    {/* Seuraava (Next) Button */}
    <div className="flex gap-2 items-center">
      <button
        onClick={handleFinalClick}
        disabled={!isNextEnabled}
        className={`flex items-center gap-3 md:gap-4 px-4 md:px-6 py-2 md:py-4 text-lg md:text-xl font-semibold rounded-xl transition-all duration-200 max-md:w-full  hover:bg-purple-800 ${
          isNextEnabled ? 'bg-[#7E4AE7] text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}
      >
        <span className="text-lg md:text-xl font-semibold tracking-wider max-sm:text-sm">
          {isLastQuestion ? t('PromNavigationBar_EndSurvey') : t('PromNavigationBar_Next')}
        </span>
        <img
          src={CornerDownRightIcon}
          alt=""
          className="object-contain w-6 h-6 aspect-square max-sm:w-5 max-sm:h-5"
        />
      </button>
    </div>
  </div>
</nav>

{/* For smaller screen sizes this is the ui tailored Note:Logic for next button */}
  <div className='flex md:flex lg:hidden xl:hidden 2xl:hidden  justify-center items-center w-full px-0 py-2 z-20  '>
  <div className='grid grid-cols-4 gap-6 mt-0 bg-white py-3 px-4 rounded-2xl '>
    
  <button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-2xl flex justify-center items-center text-3xl font-bold transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-[6px_4px_1px_rgba(0,0,0,1),_0px_0px_4px_rgba(255,255,255,0.2)] border-2 text-gray-800'> <img src={SquareIcon} alt='p' onClick={handleAbortClick}></img></button>

<button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-2xl flex justify-center items-center text-3xl font-bold transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-[6px_4px_1px_rgba(0,0,0,1),_0px_0px_4px_rgba(255,255,255,0.2)] border-2 text-gray-800'> <img src={InfoIcon} alt='p' onClick={handleHelpClick}></img></button>


<div className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-2xl flex justify-center items-center text-[20px] font-bold transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-[6px_4px_1px_rgba(0,0,0,1),_0px_0px_4px_rgba(255,255,255,0.2)] border-2 text-gray-800'>     
    {currentQuestionIndex + 1}/{totalQuestions}


    </div>

        <button className={`w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-2xl flex justify-center items-center text-3xl font-bold transition-all duration-200 ease-out  shadow-[6px_4px_1px_rgba(0,0,0,1),_0px_0px_4px_rgba(255,255,255,0.2)] border-2 text-gray-800 ${
          isNextEnabled ? 'bg-[#7E4AE7] text-white transform hover:scale-105 active:scale-95' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`} 
        disabled={!isNextEnabled}
        
        > <img src={NextIcon} alt='p'  onClick={handleFinalClick}></img></button>

  </div>
  </div>

{/* AbortPage Modal */}
{isAbortModalOpen && <AbortPage onClose={handleCloseAbortModal}/>}

{/* HelpPage Modal */}
{isHelpModalOpen && <HelpPageModal onClose={handleCloseHelpModal}/>}
    </>
  );
}

export default NavigationBar;

