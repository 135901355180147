import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { logistic, curveFitLogistic, getUniqueX, getAverageAnswers } from "./LogisticHelper"; // Import helper functions





const LogisticChartD3 = ({ xData, yData, title = "Logistic Fit Data" }) => {
    const svgRef = useRef();
    const tooltipRef = useRef();

    useEffect(() => {
        // Define dimensions and margins
        const width = 600;
        const height = 400;
        const margin = { top: 60, right: 30, bottom: 60, left: 60 };

        // Clear existing content in SVG
        d3.select(svgRef.current).selectAll("*").remove();

        // Create SVG container
        const svg = d3
            .select(svgRef.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .style("overflow", "visible");

        const chart = svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // Tooltip setup
        const tooltip = d3.select('body')
            .append('div')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', '#ffffff')
            .style('border', '1px solid #ddd')
            .style('padding', '10px')
            .style('border-radius', '8px')
            .style('font-size', '13px')
            .style('box-shadow', '0px 4px 12px rgba(0, 0, 0, 0.2)')
            .style('color', '#333')
            .style('z-index', '10')
            .style('cursor', 'pointer');

        // Calculate the unique X values and average Y values
        const uniqueX = getUniqueX(xData);
        const avgY = getAverageAnswers(xData, yData, uniqueX);

        // Curve fitting
        const { logisticX, logisticY, L50, slope } = curveFitLogistic(uniqueX, avgY);

        // Define scales with extended range for x and y axes
        const xScale = d3.scaleLinear()
            .domain([d3.min(logisticX) - 1, d3.max(logisticX) + 1]) // Extend x-axis by 1 unit on both sides
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([-0.1, 1.1]) // Extend y-axis slightly below 0 and above 1
            .range([height, 0]);

        // Add grid lines
        chart.append("g")
            .attr("class", "grid")
            .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(""))
            .style("stroke-dasharray", "0")
            .style("stroke-opacity", 0.3);

        chart.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(""))
            .style("stroke-dasharray", "0")
            .style("stroke-opacity", 0.3);

        // Add X and Y axes
        chart.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(xScale));

        chart.append("g")
            .call(d3.axisLeft(yScale));

        // Plot logistic curve
        const lineGenerator = d3.line()
            .x(d => xScale(d.x))
            .y(d => yScale(d.y))
            .curve(d3.curveMonotoneX);

        const logisticData = logisticX.map((x, i) => ({ x, y: logisticY[i] }));

        chart.append("path")
            .datum(logisticData)
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2)
            .attr("d", lineGenerator);

        // Plot aggregated data points
        chart.selectAll("dot")
            .data(uniqueX.map((x, i) => ({ x, y: avgY[i] })))
            .enter()
            .append("circle")
            .attr("cx", d => xScale(d.x))
            .attr("cy", d => yScale(d.y))
            .attr("r", 5)
            .attr("fill", "red")
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                tooltip.style("visibility", "visible")
                    .text(`SNR: ${d.x.toFixed(2)} dB, Probability: ${(d.y * 100).toFixed(1)}%`);
            })
            .on("mousemove", (event) => {
                tooltip.style("top", (event.pageY - 10) + "px")
                    .style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", () => {
                tooltip.style("visibility", "hidden");
            });

        // Add title
        svg.append("text")
            .attr("x", (width + margin.left + margin.right) / 2)
            .attr("y", margin.top / 2)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("font-weight", "bold")
            .text(`${title} (L50: ${L50.toFixed(2)}) (Slope: ${slope.toFixed(2)})`);

        // Add X-axis label
        svg.append("text")
            .attr("x", (width + margin.left + margin.right) / 2)
            .attr("y", height + margin.top + margin.bottom - 10)
            .attr("text-anchor", "middle")
            .style("font-size", "12px")
            .text("SNR (dB)");

        // Add Y-axis label
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${margin.left - 40}, ${(height + margin.top + margin.bottom) / 2}) rotate(-90)`)
            .style("font-size", "12px")
            .text("Probability of Correct Identification");

        // Add legend
        svg.append("circle").attr("cx", width - 120).attr("cy", 40).attr("r", 6).style("fill", "red");
        svg.append("text").attr("x", width - 110).attr("y", 40).text("Aggregated Data Points").style("font-size", "12px").attr("alignment-baseline", "middle");

        svg.append("line").attr("x1", width - 120).attr("y1", 55).attr("x2", width - 110).attr("y2", 55).style("stroke", "orange").style("stroke-width", 2);
        svg.append("text").attr("x", width - 100).attr("y", 55).text("Logistic Fit").style("font-size", "12px").attr("alignment-baseline", "middle");

    }, [xData, yData, title]);

    return <svg ref={svgRef}></svg>;
};

export default LogisticChartD3;
