// src/components/Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';

function Home() {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguage();

  return (
    <div>
      <h1>{t('welcome')}</h1>
      <p>{t('description')}</p>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('fi')}>Finnish</button>
    </div>
  );
}

export default Home;
