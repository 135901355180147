import React, { useEffect, useRef, useState } from 'react';
import ButtonUtil from '../../../Global/ButtonUtil';
import { HeadPhoneIconv1, LeftIcon, PauseIcon, RightIcon } from '../../../../assets/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHeadPhoneLeftEarSetting,
  setHeadPhoneRightEarSetting,
  setSpeakerLeftEarSetting,
  setSpeakerRightEarSetting,
} from '../../../../Slices/dinSettingSlice';
import { toast, ToastContainer } from 'react-toastify';
import SettingsNavbar from '../../Settingspage/SettingsNavbar';
import isElectron from '../../../../Functions/isElectron';

const SpeakerCalibrationPage = () => {
  const type = 'speaker';
  const location = useLocation();
  const soundSource = 'speaker';
  const [defaultSettings, setDefaultSettings] = useState(location.state?.defaultSettings || {});
  const defaultDB = 65.0;
  const [leftRange, setLeftRange] = useState(0);
  const [rightRange, setRightRange] = useState(0);

  const [currentChannel, setCurrentChannel] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leftEarSetting = useSelector((state) =>
    type === 'headPhone' ? state.dinState.headPhoneLeftEarSetting : state.dinState.speakerLeftEarSetting
  );
  const rightEarSetting = useSelector((state) =>
    type === 'headPhone' ? state.dinState.headPhoneRightEarSetting : state.dinState.speakerRightEarSetting
  );

  const audioContextRef = useRef(null);
  const noiseSourceRef = useRef(null);
  const noiseBufferRef = useRef(null);
  const leftGainNodeRef = useRef(null);
  const rightGainNodeRef = useRef(null);

  const noise550ms = require('../../../../assets/Sounds/noise550ms.wav');

  const playNoise = (channel = 'both') => {
    pauseNoise();
    const leftDecibels = defaultDB + leftRange;
    const rightDecibels = defaultDB + rightRange;

    if (noiseBufferRef.current) {
      noiseSourceRef.current = audioContextRef.current.createBufferSource();
      noiseSourceRef.current.buffer = noiseBufferRef.current;
      noiseSourceRef.current.loop = true;

      const splitter = audioContextRef.current.createChannelSplitter(2);
      const merger = audioContextRef.current.createChannelMerger(2);

      noiseSourceRef.current.connect(splitter);

      if (channel === 'left' || channel === 'both') {
        splitter.connect(leftGainNodeRef.current, 0);
        leftGainNodeRef.current.connect(merger, 0, 0);
        leftGainNodeRef.current.gain.value = calculateGainFromDecibels(leftDecibels);
      }

      if (channel === 'right' || channel === 'both') {
        splitter.connect(rightGainNodeRef.current, 1);
        rightGainNodeRef.current.connect(merger, 0, 1);
        rightGainNodeRef.current.gain.value = calculateGainFromDecibels(rightDecibels);
      }

      merger.connect(audioContextRef.current.destination);
      noiseSourceRef.current.start(0);
      setCurrentChannel(channel);
    }
  };

  const pauseNoise = () => {
    try {
      if (noiseSourceRef.current) {
        noiseSourceRef.current.stop();
        noiseSourceRef.current = null;
        console.log('Background noise paused');
      }
      if (leftGainNodeRef.current) {
        leftGainNodeRef.current.gain.value = 0;
        console.log(`[+] Resetting LeftNoiseGainNode`);
        leftGainNodeRef.current.disconnect();
      }
      if (rightGainNodeRef.current) {
        rightGainNodeRef.current.gain.value = 0;
        console.log(`[+] Resetting RightNoiseGainNode`);
        rightGainNodeRef.current.disconnect();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const calculateGainFromDecibels = (decibels) => Math.pow(10, (decibels - 80) / 20);

  const handleLeftChange = (e) => {
    const value = e.target.value;

    if (value === '' || isNaN(value)) {
      setLeftRange(''); // Temporarily set the input to empty without updating gain
      return;
    }
    setLeftRange(parseFloat(e.target.value));
    if (currentChannel === 'left' || currentChannel === 'both') {
      leftGainNodeRef.current.gain.value = calculateGainFromDecibels(defaultDB + parseFloat(e.target.value));
    }
  };

  const handleRightChange = (e) => {
    const value = e.target.value;
    if (value === '' || isNaN(value)) {
      setRightRange(''); // Temporarily set the input to empty without updating gain
      return;
    }
    setRightRange(parseFloat(e.target.value));
    if (currentChannel === 'right' || currentChannel === 'both') {
      rightGainNodeRef.current.gain.value = calculateGainFromDecibels(defaultDB + parseFloat(e.target.value));
    }
  };

  const updateSettingEarRanges = async () => {
    try {
      let updatedDefaultData = {};
      const leftRangeValue = parseFloat(leftRange) || 0;
      const rightRangeValue = parseFloat(rightRange) || 0;

      const isHeadPhone = type === 'headPhone';

      if (isHeadPhone) {
        dispatch(setHeadPhoneLeftEarSetting(leftRangeValue));
        dispatch(setHeadPhoneRightEarSetting(rightRangeValue));
      } else {
        dispatch(setSpeakerLeftEarSetting(leftRangeValue));
        dispatch(setSpeakerRightEarSetting(rightRangeValue));
      }

      const earSettings = isHeadPhone
        ? { leftSetting: 'headPhoneLeftEarSetting', rightSetting: 'headPhoneRightEarSetting' }
        : { leftSetting: 'speakerLeftEarSetting', rightSetting: 'speakerRightEarSetting' };

      if (soundSource === type) {
        updatedDefaultData = {
          ...defaultSettings,
          [earSettings.leftSetting]: leftRangeValue,
          [earSettings.rightSetting]: rightRangeValue,
          leftEarCalibration: leftRangeValue,
          rightEarCalibration: rightRangeValue,
        };
      } else {
        updatedDefaultData = {
          ...defaultSettings,
          [earSettings.leftSetting]: leftRangeValue,
          [earSettings.rightSetting]: rightRangeValue,
        };
      }

      setDefaultSettings(updatedDefaultData);

      if (isElectron() && window.electron && window.electron.savedinSettings) {
        try {
          const response = await window.electron.savedinSettings(updatedDefaultData);
          if (response.success) {
            toast.success('Settings saved to file!');
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.log(error.message);
          toast.error('Error saving settings to file.');
        }
      } else {
        toast.info('[+] Skipping saving as we are in browser environment');
      }
      toast.success('Settings Updated');
    } catch (error) {
      console.error(`[+] Something went wrong in updateSettingEarRanges:`, error.message);
      toast.error('Something went wrong in updateSettingEarRanges');
    }
  };

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    leftGainNodeRef.current = audioContextRef.current.createGain();
    rightGainNodeRef.current = audioContextRef.current.createGain();

    fetch(noise550ms)
      .then((response) => response.arrayBuffer())
      .then((buffer) => audioContextRef.current.decodeAudioData(buffer))
      .then((decodedBuffer) => {
        noiseBufferRef.current = decodedBuffer;
        console.log('Background noise loaded and decoded');
      });

    const loadCalibrationSettings = async () => {
      if (isElectron() && window.electron && window.electron.loaddinSettings) {
        try {
          const response = await window.electron.loaddinSettings();
          if (response.success) {
            const settings = response.data;
            const isHeadPhone = type === 'headPhone';
            const leftSettingKey = isHeadPhone ? 'headPhoneLeftEarSetting' : 'speakerLeftEarSetting';
            const rightSettingKey = isHeadPhone ? 'headPhoneRightEarSetting' : 'speakerRightEarSetting';
            const leftValue = settings[leftSettingKey] || 0;
            const rightValue = settings[rightSettingKey] || 0;
            setLeftRange(leftValue);
            setRightRange(rightValue);
            setDefaultSettings(settings);
          } else {
            console.error('Failed to load settings:', response.message);
          }
        } catch (error) {
          console.error('Error loading settings:', error);
        }
      } else {
        setLeftRange(leftEarSetting || 0);
        setRightRange(rightEarSetting || 0);
      }
    };

    loadCalibrationSettings();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [leftEarSetting, rightEarSetting, type]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col p-6">
      <div className="flex flex-grow">
        <SettingsNavbar />

        <div className="flex-grow p-10 bg-white">
          <p className="text-[#344054] text-2xl font-bold">
            {type === 'headPhone' ? 'Kalibroi kuulokkeet' : 'Kalibroi kaiuttimet'} kohinan avulla 65dB
            tavoitevahvistukseen.
          </p>

          {/* Noise control buttons */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 pt-4">
            <div
              className="flex gap-x-1 bg-blue-600 text-white px-4 py-4 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer"
              onClick={pauseNoise}
            >
              <img src={PauseIcon} className="size-4" alt="Pause Icon" />
              <button>Pysayta Kohina</button>
            </div>
            <div
              className="flex gap-x-1 bg-blue-600 text-white px-4 py-4 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer"
              onClick={() => playNoise('left')}
            >
              <img src={LeftIcon} className="size-4" alt="Left Icon" />
              <button>Soita Kohina Vasemmalle</button>
            </div>
            <div
              className="flex gap-x-1 bg-blue-600 text-white px-4 py-4 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer"
              onClick={() => playNoise('right')}
            >
              <img src={RightIcon} className="size-4" alt="Right Icon" />
              <button>Soita Kohina Oikealle</button>
            </div>
            <div
              className="flex gap-x-1 bg-blue-600 text-white px-4 py-4 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer"
              onClick={() => playNoise('both')}
            >
              <img src={HeadPhoneIconv1} className="size-4" alt="Headphone Icon" />
              <button>Soita Kohina Molemmille</button>
            </div>
          </div>

          {/* Left ear volume control */}
          <div className="mt-14">
            <label className="text-[#344054] font-semibold">Vasemman Kanavan Vahvistus</label>
            <div className="flex items-center gap-4 mt-2">
              <input
                type="range"
                min="-100"
                max="20"
                step="0.1"
                value={leftRange}
                onChange={handleLeftChange}
                className="w-full md:w-3/4"
              />
              <input
                type="number"
                min="-100"
                max="20"
                step="0.1"
                value={leftRange}
                onChange={handleLeftChange}
                className="w-full md:w-1/4 p-2 border rounded-md"
              />
            </div>
          </div>

          {/* Right ear volume control */}
          <div className="mt-6">
            <label className="text-[#344054] font-semibold">Oikean Kanavan Vahvistus</label>
            <div className="flex items-center gap-4 mt-2">
              <input
                type="range"
                min="-100"
                max="20"
                step="0.1"
                value={rightRange}
                onChange={handleRightChange}
                className="w-full md:w-3/4"
              />
              <input
                type="number"
                min="-100"
                max="20"
                step="0.1"
                value={rightRange}
                onChange={handleRightChange}
                className="w-full md:w-1/4 p-2 border rounded-md"
              />
            </div>
          </div>

          {/* Save calibration button */}
          <div className="mt-4" onClick={updateSettingEarRanges}>
            <button className="bg-blue-600 text-white px-4 py-4 hover:bg-purple-900 rounded-md">
              Tallenna Kalibrointi
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SpeakerCalibrationPage;
