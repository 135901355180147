import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DigitInNoiseChart = ({ data, label, title }) => {
    const [fontSize, setFontSize] = useState(14);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 640) {
                setFontSize(10);
            } else if (window.innerWidth < 1024) {
                setFontSize(12);
            } else {
                setFontSize(14);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const chartData = {
        labels: data.map(item => item.date),
        datasets: [
            {
                label,
                data: data.map(item => item.value),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(54, 162, 235, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.4, // Smooth line
                fill: true,
                showLine: true,
            }
        ]
    };

    // Calculate min and max values for y-axis
    const yValues = data.map(item => item.value);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#333',
                    font: { size: fontSize, weight: '600' },
                    padding: 15,
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                padding: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                titleFont: { size: fontSize, weight: 'bold' },
                bodyFont: { size: fontSize - 2 },
            },
            title: {
                display: true,
                text: title,
                color: '#333',
                font: { size: fontSize + 4, weight: 'bold' },
                padding: { top: 10, bottom: 10 },
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Date',
                    color: '#333',
                    font: { size: fontSize, weight: 'bold' },
                },
                ticks: {
                    color: '#666',
                    maxRotation: 45,
                    minRotation: 0,
                    font: { size: fontSize - 2 },
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Value',
                    color: '#333',
                    font: { size: fontSize, weight: 'bold' },
                },
                ticks: {
                    color: '#666',
                    font: { size: fontSize - 2 },
                },
                min: minY - 1,
                max: maxY + 1
            }
        }
    };

    return (
            <Line data={chartData} options={options} />
    );
};

export default DigitInNoiseChart;
