import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonUtil from '../../Global/ButtonUtil';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTestInclusion, setSoundTest } from '../../../Slices/soundSilce';
import SettingsNavbar from './SettingsNavbar';
import isElectron from '../../../Functions/isElectron';
import { toast, ToastContainer } from 'react-toastify';
import { defaultSoundTests } from './defaultSoundTests';
import { useTranslation } from 'react-i18next';

const MainSettingsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const soundTests = useSelector((state) => state.soundtest.soundTest);

    const mergeSoundTests = (defaultTests, loadedTests) => {
        const mergedTests = defaultTests.map((defaultTest) => {
            const loadedTest = loadedTests.find((test) => test.name === defaultTest.name);
            return loadedTest ? { ...defaultTest, ...loadedTest } : defaultTest;
        });
        const additionalTests = loadedTests.filter(
            (loadedTest) => !defaultTests.some((defaultTest) => defaultTest.name === loadedTest.name)
        );
        return [...mergedTests, ...additionalTests];
    };

    useEffect(() => {
        const loadSettings = async () => {
            let loadedSettings = [];
            if (isElectron() && window.electron && window.electron.loadGeneralSettings) {
                try {
                    const response = await window.electron.loadGeneralSettings();
                    loadedSettings = response.success && response.data.soundTest ? response.data.soundTest : [];
                } catch (error) {
                    console.error('Error loading general settings:', error);
                }
            } else {
                const settingsData = localStorage.getItem('generalSettings');
                if (settingsData) {
                    const parsedData = JSON.parse(settingsData);
                    loadedSettings = parsedData.soundTest || [];
                }
            }
            const mergedSoundTests = mergeSoundTests(defaultSoundTests, loadedSettings);
            dispatch(setSoundTest(mergedSoundTests));
        };

        loadSettings();
    }, [dispatch]);

    const saveSettings = async () => {
        const settingsData = { soundTest: soundTests };
        if (isElectron() && window.electron && window.electron.saveGeneralSettings) {
            try {
                const response = await window.electron.saveGeneralSettings(settingsData);
                toast.success(response.success ? t('mainSettings_settingsSavedSuccess') : t('mainSettings_settingsSavedError'));
            } catch (error) {
                toast.error(t('mainSettings_settingsSavedError'));
            }
        } else {
            try {
                localStorage.setItem('generalSettings', JSON.stringify(settingsData));
                toast.success(t('mainSettings_settingsSavedSuccess'));
            } catch (error) {
                toast.error(t('mainSettings_settingsSavedError'));
            }
        }
    };

    return (
        <div className="flex h-screen p-6">
            <SettingsNavbar />
            <div className="w-3/5 p-10 bg-white">
                <h2 className="text-2xl font-bold mb-4">{t('mainSettings_settingsTitle')}</h2>
                <p>{t('mainSettings_categoryPrompt')}</p>
                <br />
                <p>{t('mainSettings_selectTests')}</p>
                <br />
                <div className='h-[50vh] overflow-auto'>

                    {soundTests.map((test) => {
                        console.log(`${test.name} : ${t(test.name)}`);
                        return (
                            <div key={test.name} className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={test.included || false}
                                    onChange={() => dispatch(toggleTestInclusion(test.name))}
                                />
                                <ButtonUtil icon={test.icon} handleClick={() => navigate(test.navigate)} text={t(test.name)} />
                            </div>
                        );
                    })}
                </div>

                <button
                    onClick={saveSettings}
                    className="bg-blue-500 text-white px-4 py-2 mt-4 rounded"
                >
                    {t('mainSettings_saveSettings')}
                </button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MainSettingsPage;
