// CompensationFrequencyDataBox.js

import React from 'react';

const frequencies = [
  '125',
  '250',
  '500',
  '750',
  '1000',
  '2000',
  '3000',
  '4000',
  '6000',
  '8000',
];

const CompensationFrequencyDataBox = ({ selectedModel, compensationData }) => {
  const modelData = compensationData.headphoneModel?.[selectedModel]?.frequencies || {};

  return (
    <div className="border border-gray-300 p-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Tallennetut tasot taajuuksittain</p>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">?</button>
      </div>
      <div className="space-y-2">
        {selectedModel ? (
          <ul>
            {frequencies.map(freq => (
              <li key={freq}>
                Frequency: {freq} Hz, Compensation Factor: {modelData[freq]?.compensationFactor || '0'}
              </li>
            ))}
          </ul>
        ) : (
          <p>Valitse kuulokemalli nähdäksesi kalibrointiarvot.</p>
        )}
      </div>
    </div>
  );
};

export default CompensationFrequencyDataBox;
