// defaultSoundTests.js

import HeadPhoneIcon from '../../../assets/Icons/headphones.png';
import SpeakersIcon from '../../../assets/Icons/speaker.png';
import Edit from '../../../assets/Icons/edit.png';
/* Fall back updated for language support */
export const defaultSoundTests = [
  { name: 'soundSlice_emergencySpeechTest', duration: '20 min', navigate: '/DinTestV6With1Speaker', completed: false, included: false, icon: SpeakersIcon },
  { name: 'soundSlice_emergencySpeechTest2', duration: '20 min', navigate: '/DinTestV6Headphones', completed: false, included: true, icon: HeadPhoneIcon },
  { name: 'soundSlice_emergencySpeechSurvey', duration: '3 min', navigate: '/QuestionnaireDIN', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_hearingSurvey', duration: '5 min', navigate: '/KuuloKyselyLandingPage', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_hearingSurveySurvey', duration: '3 min', navigate: '/QuestionnairePROM', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_headsetFitting', duration: '2 min', navigate: '/Kuulokkeiden', completed: false, included: true, icon: HeadPhoneIcon },
  { name: 'soundSlice_hearingThreshold', duration: '15 min', navigate: '/AudiogramLandingPage', completed: false, included: true, icon: HeadPhoneIcon },
  { name: 'soundSlice_hearingThresholdSurvey', duration: '3 min', navigate: '/QuestionnaireAUD', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_finalSurvey', duration: '5 min', navigate: '/QuestionnaireFINISH', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_sliderThreshold', duration: '5 min', navigate: '/SliderAudiogram2', completed: false, included: false, icon: HeadPhoneIcon },
  { name: 'soundSlice_antiphaseSpeechTest', duration: '20 min', navigate: '/HalyPuheTestiWithAntiPhasic', completed: false, included: false, icon: HeadPhoneIcon }
  // Add any additional default tests here
];