import React, { useEffect, useState } from 'react';
import PractionerDashboardWrapper from './PractionerDashboardWrapper';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isValidJSON from '../../Functions/IsValidJSON';
import './Expandable.css';
import axios from 'axios';
import { setSelectedPatient } from '../../Slices/selectedPatientSlice';
import { setSelectedNavbarNavigationIndex } from '../../Slices/sideBarSlice';


const PractionerDashboard = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const tokenFromStorage = localStorage.getItem('token');
  const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);
  const [patients, setPatients] = useState([]);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(null);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isExpandingClosing, setIsExpandingClosing] = useState(false);

  const openExpansion = (id) => {
    setIsExpanding(true);
    setExpanded(id);
    setTimeout(() => {
      setIsExpanding(false);
    }, 400); // Match the CSS animation duration
  };

  const closeExpansion = (id) => {
    setIsExpandingClosing(true);
    setTimeout(() => {
      setIsExpandingClosing(false);
      if (id === expanded)
        setExpanded(null);
      else
        openExpansion(id);
    }, 400); // Match the CSS animation duration
  };


  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  function convertToLocalTime(utcTimestamp, targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    try {
      const date = new Date(utcTimestamp);
      const options = {
        timeZone: targetTimeZone, // Target timezone (defaults to local timezone)
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return 'N/A';
    }
  }

  // Example Usage
  const utcTimestamp = "2024-10-28T07:34:37.247Z";
  const localTime = convertToLocalTime(utcTimestamp);
  console.log("Local Time:", localTime); // Automatically converts to your local timezone

  const finnishTime = convertToLocalTime(utcTimestamp, "Europe/Helsinki");
  console.log("Finnish Time:", finnishTime); // Conv

  /* Handle SelectedPatient */
  const handleSelectedPatient = (patient) => {
    try {
      console.log(patient);
      dispatch(setSelectedPatient(patient));
      navigate('/PractionerSummaryDashboard');
    } catch (error) {
      console.log(error);
    }

  };

  console.log({ patients });
  const fetchUsers = async (invoke = false) => {
    try {
      if (invoke === true && search === '') return;
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/patient/searchUsers?search=${search}&page=${page}&limit=${limit}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const { data } = response;
      if (data.status === true) setPatients(data.users);
    } catch (error) {
      console.log(`[+] Error FetchUsers:`, error.message);
    }
  };
  useEffect(() => {

    fetchUsers();
  }, [page]);
  useEffect(() => { dispatch(setSelectedNavbarNavigationIndex(0)); }, []);
  return (
    <PractionerDashboardWrapper>
      <div className="px-4 py-4">
        <div className="mt-5 lg:mt-0 py-2 flex flex-col gap-y-2 md:flex-row lg:flex-row gap-x-2">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search patients..."
            className="px-2 py-3 border border-gray-300 focus:outline-none focus:border-blue-500 rounded-lg"
          />
          <button
            onClick={() => fetchUsers(true)}
            className="bg-blue-500 text-white ml-0 px-4 py-3  hover:bg-blue-600 focus:outline-none focus:border-blue-500 rounded-xl"
          >
            Search Patients
          </button>
        </div>
        <div className="hidden md:flex lg:flex xl:flex 2xl:flex overflow-auto h-[600px] md:h-full lg:h-full shadow-lg rounded-lg border w-[470px] md:w-full lg:w-full">
          <table className="min-w-full bg-gray-50 cursor-pointer">
            <thead>
              <tr className="w-full bg-gray-100 border-b">
                <th className="text-left py-3 px-4 font-semibold text-gray-700">firstName</th>
                <th className="text-left py-3 px-4 font-semibold text-gray-700">lastName</th>
                <th className="text-left py-3 px-4 font-semibold text-gray-700">socialSecurityNumber</th>
                <th className="text-left py-3 px-4 font-semibold text-gray-700">LatestVisitation</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 transition-all border-b"
                  onClick={(e) => handleSelectedPatient(patient)}
                >
                  <td className="py-3 px-4 text-gray-600">{patient.firstName}</td>
                  <td className="py-3 px-4 text-gray-600">{patient.lastName}</td>
                  <td className="py-3 px-4 text-gray-600">{patient.socialSecurityNumber}</td>
                  <td className="py-3 px-4 text-gray-600">{convertToLocalTime(patient.latestVisitation)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Card based layout */}
        {/* <div className="flex flex-col lg:hidden md:hidden xl:hidden 2xl:hidden space-y-4 p-4 h-[450px] overflow-auto">
          {patients.map((patient, index) => (
            <div key={index} className="p-4 bg-white shadow rounded-lg text-sm">
              <p className=" font-semibold">{patient.firstName} {patient.lastName}</p>
              <p className="text-gray-500">SSN: {patient.socialSecurityNumber}</p>
              <p className="text-gray-500">Latest Visitation: {patient.latestVisitation}</p>
            </div>
          ))}

        </div> */}

        {/*accordian list  */}
        <div className="flex flex-col lg:hidden md:hidden xl:hidden 2xl:hidden space-y-4 p-4 h-[450px] overflow-auto">
          {patients.map((patient, index) => (
            <div key={index} className="border-b text-gray-600">
              <button
                onClick={() => expanded === null ? openExpansion(index) : closeExpansion(index)}
                className="w-full text-left p-4 bg-gray-100 font-semibold"
              >
                {patient.firstName} {patient.lastName}
              </button>
              {expanded === index && (
                <div className={`p-4 bg-white shadow rounded-lg text-sm ${expanded !== null ? 'toggleopen' : 'toggleclose'} ${isExpanding ? 'toggle-expansion-opening' : ''} ${isExpandingClosing ? 'toggle-expansion-closing' : ''}`}
                  onClick={(e) => handleSelectedPatient(patient)}
                >
                  <p className=" font-semibold">{patient.firstName} {patient.lastName}</p>
                  <p className="text-gray-600">SSN: {patient.socialSecurityNumber}</p>
                  <p className="text-gray-600">Latest Visitation: {convertToLocalTime(patient.latestVisitation)}</p>
                </div>
              )}
            </div>
          ))}
        </div>




        <div className="flex items-center justify-between mt-4">
          <button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            disabled={page === 1}
            className={`px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold ${page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
              }`}
          >
            Previous
          </button>
          <span className="text-gray-600">Page {page}</span>
          <button
            onClick={() => setPage((prev) => prev + 1)}
            className="px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600"
          >
            Next
          </button>
        </div>
      </div>
    </PractionerDashboardWrapper>
  );
};

export default PractionerDashboard;
