// src/hooks/useLanguage.js
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../Slices/languageSlice';
import { useEffect } from 'react';
import i18n from '../i18n';

const useLanguage = () => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.languageState.language);

    // Determine the initial language based on localStorage or fallback to 'fi'
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        const initialLanguage = storedLanguage || 'fi'; // Default to 'fi' if no stored language

        i18n.changeLanguage(initialLanguage); // Set initial language for i18next
        dispatch(setLanguage(initialLanguage)); // Set initial language in Redux store
    }, [dispatch]);

    const changeLanguage = (lang) => {
        switch (lang) {
            case 'fi':
            case 'fr':
            case 'en':
                i18n.changeLanguage(lang); // Change language in i18next
                dispatch(setLanguage(lang)); // Update language in Redux store
                localStorage.setItem('language', lang); // Save language to localStorage
                break;
            default:
                console.warn(`Unsupported language code: ${lang}`);
        }
    };

    return { language, changeLanguage };
};

export default useLanguage;
