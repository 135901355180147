import React from 'react';

const ContentWrapper = ({ children, className = "" }) => {
    return (
        <div className={`bg-white px-4 py-6  rounded-2xl mt-6 ${className}`}>
            {children}
        </div>
    );
};

export default ContentWrapper;