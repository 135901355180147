import React, { useEffect, useRef } from 'react';

const Firsttest = () => {
  const audioContextRef = useRef(null);
  const oscillatorRef = useRef(null);
  const pannerRef = useRef(null);

  const startSound = (panValue) => {
    // Close any existing AudioContext
    if (audioContextRef.current) {
      stopSound();
    }

    // Create a new AudioContext
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    const audioContext = audioContextRef.current;

    // Create an oscillator node
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine'; // Sine wave
    oscillator.frequency.setValueAtTime(250, audioContext.currentTime); // A4 note

    // Create a gain node
    const gainNode = audioContext.createGain();
    gainNode.gain.setValueAtTime(0.5, audioContext.currentTime); // Volume

    // Create a stereo panner node
    const panner = audioContext.createStereoPanner();
    panner.pan.setValueAtTime(panValue, audioContext.currentTime); // Set pan value

    // Connect the nodes: Oscillator -> Gain -> Panner -> Destination
    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContext.destination);

    // Start the oscillator
    oscillator.start();

    // Save references to stop later
    oscillatorRef.current = oscillator;
    pannerRef.current = panner;
  };

  const stopSound = () => {
    if (oscillatorRef.current) {
      oscillatorRef.current.stop();
      oscillatorRef.current.disconnect();
      oscillatorRef.current = null;
    }

    if (pannerRef.current) {
      pannerRef.current.disconnect();
      pannerRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      stopSound();
    };
  }, []);

  return (
    <div>
      <h1>Sine Wave Player</h1>
      <button onClick={() => startSound(-1)}>Play Left Speaker</button>
      <button onClick={() => startSound(0)}>Play Center (Both Speakers)</button>
      <button onClick={() => startSound(1)}>Play Right Speaker</button>
      <button onClick={stopSound}>Stop Sound</button>
    </div>
  );
};

export default Firsttest;
