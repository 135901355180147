import React from 'react';

const SegmentedColorBar = ({ segmentClass = 'highlight-segment-1' }) => {
    return (
        <div className="h-[280px] w-[68px] relative">
            {/* First Color Segment */}
            <div className="h-1/5 bg-red-600 rounded-t-full border-l-[2.5px] border-r-[2.5px] border-t-[2.5px] border-black relative flex justify-center items-center ">
                {segmentClass === 'highlight-segment-1' && (
                    <>
                        <div className="absolute left-[-50px] top-1/2 w-20 h-[1px] bg-black"></div>
                        <div className="h-[8px] w-[8px] rounded-full bg-black"></div>
                    </>
                )}
            </div>
            {/* Second Color Segment */}
            <div className="h-1/5 bg-orange-500 border-l-[2.5px] border-r-[2.5px] border-black relative flex justify-center items-center">
                {segmentClass === 'highlight-segment-2' && (
                    <>
                        <div className="absolute left-[-50px] top-1/2 w-20 h-[1px] bg-black"></div>
                        <div className="h-[8px] w-[8px] rounded-full bg-black"></div>
                    </>
                )}
            </div>
            {/* Third Color Segment */}
            <div className="h-1/5 bg-yellow-400 border-l-[2.5px] border-r-[2.5px] border-black relative flex justify-center items-center">
                {segmentClass === 'highlight-segment-3' && (
                    <>
                        <div className="absolute left-[-50px] top-1/2 w-20 h-[1px] bg-black"></div>
                        <div className="h-[8px] w-[8px] rounded-full bg-black"></div>
                    </>
                )}
            </div>
            {/* Fourth Color Segment */}
            <div className="h-1/5 bg-purple-500 border-l-[2.5px] border-r-[2.5px] border-black relative flex justify-center items-center">
                {segmentClass === 'highlight-segment-4' && (
                    <>
                        <div className="absolute left-[-50px] top-1/2 w-20 h-[1px] bg-black"></div>
                        <div className="h-[8px] w-[8px] rounded-full bg-black"></div>
                    </>
                )}
            </div>
            {/* Fifth Color Segment */}
            <div className="h-1/5 bg-indigo-400 rounded-b-full border-l-[2.5px] border-r-[2.5px] border-b-[2.5px] border-black relative flex justify-center items-center">
                {segmentClass === 'highlight-segment-5' && (
                    <>
                        <div className="absolute left-[-50px] top-1/2 w-20 h-[1px] bg-black"></div>
                        <div className="h-[8px] w-[8px] rounded-full bg-black"></div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SegmentedColorBar;
