// SettingsBox.js

import React, { useEffect, useState } from 'react';
import axios from 'axios'; // For making API requests
import SettingsNavbar from './SettingsNavbar';
import { toast, ToastContainer } from 'react-toastify'; // Toast notification library
import isElectron from '../../../Functions/isElectron'; // Function to check if the app is running in Electron

const SettingsBox = () => {
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const [latestSettings, setLatestSettings] = useState(null);
const [allSettings, setAllSettings] = useState([]);
const [selectedSettingId, setSelectedSettingId] = useState('');
const [responseTime, setResponseTime] = useState('5000'); // Default to 5000 ms
const [restart, setRestart] = useState(false);
const [mode, setMode] = useState('0');
const [rounds, setRounds] = useState('2');
const [noticeEnabled, setNoticeEnabled] = useState(false);
const [noticeLevels, setNoticeLevels] = useState({
notice1000: '20',
notice125: '20',
notice2000: '20',
notice250: '20',
notice4000: '20',
notice500: '20',
notice8000: '20',
});
const [selectedFrequency, setSelectedFrequency] = useState('125');
const [headphoneModel, setHeadphoneModel] = useState('DD65v2');

useEffect(() => {
loadSettings(); // Load settings from Electron or localStorage on mount
fetchAllSettings(); // Load all settings from server if needed
}, []);

const loadSettings = async () => {
if (isElectron() && window.electron.isElectron) {
    try {
    const response = await window.electron.loadAudiometrySettings();
    if (response.success) {
        const settings = response.data;

        setResponseTime(settings.responseTime ?? '5000');
        setRestart(settings.restart ?? false);
        setMode(settings.mode ?? '0');
        setRounds(settings.rounds ?? '2');
        setNoticeEnabled(settings.noticeEnabled ?? false);
        setNoticeLevels(
        settings.noticeLevels ?? {
            notice1000: '20',
            notice125: '20',
            notice2000: '20',
            notice250: '20',
            notice4000: '20',
            notice500: '20',
            notice8000: '20',
        }
        );
        setSelectedFrequency(settings.selectedFrequency ?? '125');
        setHeadphoneModel(settings.headphoneModel ?? 'DD65v2');

        toast.success('Local audiometry settings loaded successfully!');
    } else {
        toast.error('Failed to load audiometry settings');
    }
    } catch (error) {
    console.error('Error loading audiometry settings:', error);
    toast.error('Error loading audiometry settings');
    }
} else {
    // Not running in Electron, attempt to load settings from localStorage
    try {
    const storedSettings = localStorage.getItem('audiometrySettings');
    if (storedSettings) {
        const settings = JSON.parse(storedSettings);
        setResponseTime(settings.responseTime);
        setRestart(settings.restart);
        setMode(settings.mode);
        setRounds(settings.rounds);
        setNoticeEnabled(settings.noticeEnabled);
        setNoticeLevels(settings.noticeLevels);
        setSelectedFrequency(settings.selectedFrequency);
        setHeadphoneModel(settings.headphoneModel);
        toast.success('Settings loaded from local storage!');
    } else {
        // No settings in localStorage, fetch from API
        toast.info('No local settings found, loading settings from API...');
        await fetchLatestSettings();
    }
    } catch (error) {
    console.error('Error loading settings from local storage:', error);
    toast.error('Error loading settings from local storage.');
    // Fallback to loading from API
    await fetchLatestSettings();
    }
}
};

const fetchLatestSettings = async () => {
try {
    const response = await axios.get(`${apiUrl}/AudiometrySettings/latest`);
    console.log('Latest settings response:', response); // Add this line
    if (response.data.success) {
    const settings = response.data.data;
    setLatestSettings(settings);
    // Update individual state variables with the fetched data
    setResponseTime(settings.responseTime);
    setRestart(settings.restart);
    setMode(settings.mode);
    setRounds(settings.rounds);
    setNoticeEnabled(settings.noticeEnabled);
    setNoticeLevels(settings.noticeLevels);
    setSelectedFrequency(settings.selectedFrequency);
    setHeadphoneModel(settings.headphoneModel);
    }
} catch (error) {
    console.error('Error fetching latest settings:', error);
}
};

const fetchAllSettings = async () => {
try {
    const response = await axios.get(`${apiUrl}/AudiometrySettings/all`);
    console.log('All settings response:', response); // Add this line
    if (response.data.success) {
    const settings = response.data.data;

    setAllSettings(response.data.data);
    }
} catch (error) {
    console.error('Error fetching all settings:', error);
}
};

const handleSettingChange = async (event) => {
const selectedId = event.target.value;
setSelectedSettingId(selectedId);

// Fetch and load the selected setting by its ID
if (selectedId) {
    try {
    const response = await axios.get(`${apiUrl}/AudiometrySettings/${selectedId}`);
    if (response.data.success) {
        const settings = response.data.data;
        setLatestSettings(settings);
        // Update individual state variables with the fetched data
        setResponseTime(settings.responseTime);
        setRestart(settings.restart);
        setMode(settings.mode);
        setRounds(settings.rounds);
        setNoticeEnabled(settings.noticeEnabled);
        setNoticeLevels(settings.noticeLevels);
        setSelectedFrequency(settings.selectedFrequency);
        setHeadphoneModel(settings.headphoneModel);
    }
    } catch (error) {
    console.error('Error fetching selected setting:', error);
    }
}
};

// Delete selected setting
const handleDeleteSetting = async () => {
if (!selectedSettingId) {
    toast.error('No setting selected to delete.');
    return;
}

try {
    await axios.delete(`${apiUrl}/AudiometrySettings/${selectedSettingId}`);
    toast.success('Setting deleted successfully.');

    // Fetch latest settings and update the UI after deletion
    fetchAllSettings();
    fetchLatestSettings();
} catch (error) {
    console.error('Error deleting the selected setting:', error);
    toast.error('Failed to delete the setting.');
}
};

// Suggested response times for the input
const responseTimeSuggestions = [1000, 2000, 3000, 4000, 5000, 6000];

const handleSaveSettings = async () => {
const settingsData = {
    responseTime: responseTime ?? '5000',
    restart: restart ?? false,
    mode: mode ?? '0',
    rounds: rounds ?? '2',
    noticeEnabled: noticeEnabled ?? false,
    noticeLevels: noticeLevels ?? {
    notice1000: '20',
    notice125: '20',
    notice2000: '20',
    notice250: '20',
    notice4000: '20',
    notice500: '20',
    notice8000: '20',
    },
    selectedFrequency: selectedFrequency ?? '125',
    headphoneModel: headphoneModel ?? 'DD65v2',
};

console.log('Settings data to save:', settingsData);

if (isElectron() && window.electron.isElectron) {
    try {
    // Save settings locally via Electron
    const response = await window.electron.saveAudiometrySettings(settingsData);
    if (response.success) {
        toast.success('Settings saved locally!');
    } else {
        toast.error('Failed to save settings locally.');
    }
    } catch (error) {
    console.error('Error saving settings locally:', error);
    toast.error('Error saving settings locally.');
    }
} else {
    // Fallback to saving via API if not in Electron
    try {
    await axios.post(`${apiUrl}/AudiometrySettings/create`, settingsData);
    toast.success('Settings saved to server!');
    } catch (error) {
    console.error('Error saving settings via API:', error);
    toast.error('Failed to save settings to server.');
    }
}
};

// New function to save settings to local storage
const handleSaveToLocalStorage = () => {
const settingsData = {
    responseTime: responseTime ?? '5000',
    restart: restart ?? false,
    mode: mode ?? '0',
    rounds: rounds ?? '2',
    noticeEnabled: noticeEnabled ?? false,
    noticeLevels: noticeLevels ?? {
    notice1000: '20',
    notice125: '20',
    notice2000: '20',
    notice250: '20',
    notice4000: '20',
    notice500: '20',
    notice8000: '20',
    },
    selectedFrequency: selectedFrequency ?? '125',
    headphoneModel: headphoneModel ?? 'DD65v2',
};

try {
        localStorage.setItem('audiometrySettings', JSON.stringify(settingsData));
        toast.success('Settings saved to local storage!');
    } catch (error) {
        console.error('Error saving settings to local storage:', error);
        toast.error('Failed to save settings to local storage.');
    }
};

return (
<div className="min-h-screen bg-gray-100 flex p-6">
    <div className="flex ">
    <SettingsNavbar />
    <div className="flex-grow p-10 bg-white">
        <div className="settings-box border border-gray-300 p-4 rounded-md">
        <h3 className="text-lg font-bold mb-2">Asetukset</h3>

        {/* Dropdown to load previous settings */}
        <div className="flex justify-between mb-2">
            <label htmlFor="settingsDropdown">Load previous settings:</label>
            <select
            id="settingsDropdown"
            value={selectedSettingId}
            onChange={handleSettingChange}
            className="border p-2 w-1/3"
            >
            <option value="">Select saved settings</option>
            {allSettings.map((setting) => (
                <option key={setting._id} value={setting._id}>
                {new Date(setting.createdAt).toLocaleString()} {/* Format date as needed */}
                </option>
            ))}
            </select>
        </div>

        <div className="flex justify-end mb-4">
            <button
            onClick={handleDeleteSetting}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
            >
            Delete Selected Setting
            </button>
        </div>

        {/* Vastausaika Input with Suggestions */}
        <div className="flex justify-between mb-2">
            <label htmlFor="responseTime" className="mr-2">
            Vastausaika (ms):
            </label>
            <input
            type="text"
            list="responseTimeSuggestions"
            id="responseTime"
            value={responseTime}
            onChange={(e) => setResponseTime(e.target.value)}
            className="border p-2 w-1/3"
            />
            <datalist id="responseTimeSuggestions">
            {responseTimeSuggestions.map((time) => (
                <option key={time} value={time} />
            ))}
            </datalist>
        </div>

        {/* Restart Checkbox */}
        <div className="flex justify-between mb-2">
            <label htmlFor="restart">1000 Hz aloita alusta:</label>
            <input
            type="checkbox"
            id="restart"
            checked={restart}
            onChange={(e) => setRestart(e.target.checked)}
            />
        </div>

        {/* Mode Input */}
        <div className="flex justify-between mb-2">
            <label htmlFor="mode">Testausjärjestys 0 = O + V:</label>
            <input
            type="text"
            id="mode"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className="border p-2 w-1/3"
            />
        </div>

        {/* Rounds Input */}
        <div className="flex justify-between mb-2">
            <label htmlFor="rounds">Saman dB tason testaus/Taajuus:</label>
            <input
            type="text"
            id="rounds"
            value={rounds}
            onChange={(e) => setRounds(e.target.value)}
            className="border p-2 w-1/3"
            />
        </div>

        {/* Notice Checkbox */}
        <div className="flex justify-between mb-2">
            <label htmlFor="noticeEnabled">Ilmoita potilaalle huonosta kuulosta:</label>
            <input
            type="checkbox"
            id="noticeEnabled"
            checked={noticeEnabled}
            onChange={(e) => setNoticeEnabled(e.target.checked)}
            />
        </div>

        {/* Headphone Model */}
        <div className="flex justify-between mb-2">
            <label htmlFor="headphoneModel">Kuulokemalli:</label>
            <input
            type="text"
            id="headphoneModel"
            value={headphoneModel}
            onChange={(e) => setHeadphoneModel(e.target.value)}
            className="border p-2 w-1/3"
            />
        </div>

        {/* Save Buttons */}
        <div className="flex justify-center mt-4 space-x-4">
            <button
            onClick={handleSaveSettings}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
            Save to Server / Electron locally
            </button>
            <button
            onClick={handleSaveToLocalStorage}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
            >
            Save to Local Storage
            </button>
        </div>
        </div>
    </div>
    </div>
    <ToastContainer /> {/* Toast notifications */}
</div>
);
};

export default SettingsBox;
