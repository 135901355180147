import React from 'react';
import './Loader.css';

const NormalTextLoader = ({ text = "Loading..." }) => {
    return (
        <div className="flex items-center justify-center">
            <div className="text-2xl font-medium animate-blink text-green-500">
                {text}
            </div>
        </div>
    );
};

export default NormalTextLoader;
