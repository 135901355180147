import React from 'react';

const KuulokkeidenInstruction = ({ className = `text-[#344054] text-[12px] md:text-base lg:text-base`, textContent = `` }) => {
    // Split the text into an array of lines
    const textLines = textContent.split('\n');
    return (
        textLines.map((line, index) => (
            <p key={index} className={`${className}`}>
                {line.trim()}
            </p>
        ))
    );
};

export default KuulokkeidenInstruction;