import React from 'react';


// Needed, do not remove


const Modal = ({ children, className = `fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50` }) => {
    return (
        <div className={`${className}`}>
            {children}
        </div>
    );
};

export default Modal;