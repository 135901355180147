import React from 'react';
import PractionerSidebar from './PractionerSideBar';
import PractionerSharedNavbar from './PractionerSharedNavbar';

const PractionerDashboardWrapper = ({ children }) => {
    return (
        <div className='flex relative bg-gray-100 h-[300vh] overflow-auto'>
            {/* Sidebar */}
            <div>
                <div className='py-4'></div>
                <PractionerSidebar />
            </div>
            {/* Main Content */}
            <div className='flex-1 '>
                {/* Shared Navbar */}
                <div className='py-4'></div>
                <PractionerSharedNavbar/>
                <div >{children}</div>
            </div>
        </div>
    );
};

export default PractionerDashboardWrapper;