import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonUtil from '../../Global/ButtonUtil';
import { HeadPhoneIconv1, HomeIcon, SpeakersIcon, TestIconv1 } from '../../../assets/Icons';
import { LogOutIconv1 } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import LanguageSwitcherButton from '../../LanguageSwitcher/LanguageSwicherButton';

const SettingsNavbar = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation hook


    const handle_Logout = (e) => {
        if (localStorage.getItem('user'))
            localStorage.removeItem('user');
        if (localStorage.getItem('token'))
            localStorage.removeItem('token');
        return navigate('/');
    };

    return (
        <div className=" bg-gray-400 p-5 shadow-md">
            <div className="mb-5 font-bold h-[80vh] overflow-auto px-4">
                <div className="lex flex-col space-y-3">
                    {/* Here you can put the login information or user avatar */}

                    <p></p>
                    <LanguageSwitcherButton className='bg-white rounded-lg' />
                    <ButtonUtil
                        icon={LogOutIconv1}
                        handleClick={handle_Logout}
                        text={t('settings_navbar_logout_main_menu')}
                    />
                    <ButtonUtil
                        icon={HomeIcon}
                        handleClick={() => navigate('/LandingPage')}
                        text={t('settings_navbar_logout_kela_card')}
                    />
                    <p>{t('settings_navbar_settings')}</p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/MainSettings')}
                        text={t('settings_navbar_select_tests')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/DINSettingsPage')}
                        text={t('settings_navbar_din_settings')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramSettingsPage')}
                        text={t('settings_navbar_audiogram_settings')}
                    />
                    <p>{t('settings_navbar_calibration')}</p>
                    <ButtonUtil
                        icon={HeadPhoneIconv1}
                        handleClick={() => navigate('/HeadPhoneCalibrationPage')}
                        text={t('settings_navbar_din_calibration_headphones')}
                    />
                    <ButtonUtil
                        icon={SpeakersIcon}
                        handleClick={() => navigate('/SpeakerCalibrationPage')}
                        text={t('settings_navbar_din_calibration_speakers')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramCalibration')}
                        text={t('settings_navbar_audiogram_calibration')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramCompensationMain')}
                        text={t('settings_navbar_headphone_retspl')}
                    />
                    <p>{t('settings_navbar_results')}</p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/Dashboard')}
                        text={t('settings_navbar_measurement_results')}
                    />
                    <p>{t('settings_navbar_ui_design')}</p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/PractionerUi')}
                        text={t('settings_navbar_clinician_view')}
                    />

                </div>
            </div>
        </div>
    );
};

export default SettingsNavbar;