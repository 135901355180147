import React from 'react';
import { useNavigate } from 'react-router-dom';

const HalyPuheTestiWithAntiPhasic = () => {
    const navigate = useNavigate();

    return (
        <div className='h-screen bg-gray-100'>
            <div className='h-screen flex flex-col justify-between items-center gap-y-2 '>
                <div className='flex flex-col justify-center items-center gap-y-2 flex-grow'>
                    <div>
                        <h1 className='text-sm sm:text-sm md:text-xl lg:text-2xl font-bold text-[#363F72]'>kuulotesti</h1>
                    </div>
                    <div>
                        <h1 className='text-3xl sm:text-3xl md:text-4xl lg:text-7xl font-bold text-[#344054] '>Hälypuhetesti</h1>
                    </div>
                    <div className='text-[#344054] mt-4 text-sm sm:text-sm  md:text-2xl lg:text-2xl'>
                        <div>
                            <h1 className='text-center'>Seuraavassa testissä selvitetämme, kuinka hyvin kuulet hankalissa </h1>
                        </div>
                        <div>
                            <h1 className='text-center'>kuunteluolosuhteissa ja taustahälyssä.</h1>
                        </div>
                    </div>
                    <div className='text-[#344054] mt-2 text-sm sm:text-sm  md:text-2xl lg:text-2xl'>
                        <div className='pl-1'>
                            <h1 className='text-center'>Kuulet nauhalta kohinaa ja sanan “Numerot” jota seuraa kolmen numeron sarja.</h1>
                        </div>
                        <div>
                            <h1 className='text-center'>Tehtävänäsi on kirjoittaa kuulemasi numerot. Jos et saa jostain numerosta selvää </h1>
                        </div>
                        <div>
                            <h1 className='text-center'>paina “?” painiketta.</h1>
                        </div>
                    </div>

                    <div className='text-[#344054] mt-2 text-sm sm:text-sm  md:text-2xl lg:text-2xl'>
                        <div >
                            <h1 className='text-center'>Kirjoita numerot kuulemisjärjestyksessä.</h1>
                        </div>
                    </div>
                    <div className='text-[#344054] mt-2 text-sm sm:text-sm  md:text-2xl lg:text-2xl'>
                        <div >
                            <h1 className='text-center'>Voit harjoitella ensin.</h1>
                        </div>
                    </div>
                </div>
                
                <div className='flex flex-col justify-center items-center mt-0 w-full mb-10'>
                    <div className='border-t-[2px] min-w-[45%] md:min-w-[45%] lg:min-w-[45%]'>
                        <div className='text-sm gap-x-2 flex py-2 justify-center px-4 bg-white text-black rounded-md'>
                            <div className='text-md font-bold'>
                                <button className='bg-white text-gray-800 border border-gray-300 py-4 px-6 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400  ' onClick={e => { return navigate('/TestLandingPage') }}>Peruuta</button>
                            </div>
                            <div className='text-md font-bold'>
                                <button className='bg-purple-600 transition-colors duration-300 ease-out hover:bg-purple-800 text-white py-4 px-6 rounded-lg' onClick={e => {navigate('/DINTestmainv5WithAntiPhasic') }}>Siirry testiin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HalyPuheTestiWithAntiPhasic;