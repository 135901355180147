import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const questions = [
    { id: 1, text: 'Kuinka paljon jouduit ponnistelemaan edellisessä testissä.' },
    { id: 2, text: 'Millaiseksi koette kuulonne?' },
    { id: 3, text: 'Kun keskustelen kotona kahdestaan toisen henkilön kanssa, minun on vaikea kuulla hänen puhettaan.' },
    { id: 4, text: 'Minun täytyy pyytää keskustelukumppaniani toistamaan sanomaansa keskustellessamme kahden hiljaisessa huoneessa.' },
    { id: 5, text: 'Minun on vaikea saada selvää keskustelukumppanini puheesta tilanteissa, joissa muut ihmiset samanaikaisesti keskustelevat ympärillämme.' },
    { id: 6, text: 'Minun on vaikea saada selvää esiintyjien puheesta teatterissa, konsertissa tai muussa vastaavassa tilanteessa.' },
    { id: 7, text: 'Minun on vaikea saada selvää puheesta paikoissa, joissa ääni kaikuu (esim. kirkossa papin saarna).' },
    { id: 8, text: 'Minun täytyy pinnistellä saadakseni selvää, kun kuuntelen jotakin tai jotakuta.' },
    { id: 9, text: 'Pystyn arvioimaan pelkän äänen perusteella, mistä suunnasta ääni kuuluu (esim. koiran haukunta ulkona tai auton ääni).' },
    { id: 10, text: 'Pystyn arvioimaan pelkän äänen perusteella, miltä etäisyydeltä ääni kuuluu (esim. koiran haukunta tai auton ääni).' },
    { id: 11, text: 'Pystyn arvioimaan pelkän äänen perusteella, onko äänilähde (esim. haukkuva koira tai auto) tulossa minua kohti vai menossa minusta poispäin.' },
    { id: 12, text: 'Tarvitsen muiden apua kuulovaikeuksieni vuoksi.' },
    { id: 13, text: 'Kuulovaikeuteni haittaavat sosiaalista elämääni (esim. ystävien/tuttavien tapaamista, osallistumista harrastuksiin tai eri tilaisuuksiin).' },
    { id: 14, text: 'Koen jääväni kuulovaikeuksien vuoksi ulkopuoliseksi ryhmässä.' },
    { id: 15, text: 'Turhaudun kuulovaikeuksieni vuoksi.' },
    { id: 16, text: 'Nolostun kuulovaikeuksieni vuoksi.' },
    ];

const answerDescriptions = {
    1: { 1: 'Erittäin vaikeaa', 10: 'Ei lainkaan vaikeaa' },
    2: { 1: 'Erittäin huonoksi', 10: 'Erittäin hyväksi' },
    3: { 1: 'Erittäin vaikeaa', 10: 'Ei lainkaan vaikeaa' },
    4: { 1: 'Jatkuvasti', 10: 'Ei koskaan' },
    5: { 1: 'Erittäin vaikeaa', 10: 'Ei lainkaan vaikeaa' },
    6: { 1: 'Erittäin vaikeaa', 10: 'Ei lainkaan vaikeaa' },
    7: { 1: 'Erittäin vaikeaa', 10: 'Ei lainkaan vaikeaa' },
    8: { 1: 'Jatkuvasti', 10: 'Ei koskaan' },
    9: { 1: 'En pysty lainkaan', 10: 'Pystyn helposti' },
    10: { 1: 'En pysty lainkaan', 10: 'Pystyn helposti' },
    11: { 1: 'En pysty lainkaan', 10: 'Pystyn helposti' },
    12: { 1: 'Jatkuvasti', 10: 'Ei koskaan' },
    13: { 1: 'Erittäin paljon', 10: 'Ei lainkaan' },
    14: { 1: 'Jatkuvasti', 10: 'En koskaan' },
    15: { 1: 'Jatkuvasti', 10: 'En koskaan' },
    16: { 1: 'Jatkuvasti', 10: 'En koskaan' },
};

const PromResults = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedPatient = location.state?.patient;
    const [expandedSections, setExpandedSections] = useState({});
        console.log('selectedPatient:', selectedPatient);
    
        if (!selectedPatient) {
        return <div>No patient selected</div>;
        }
    
        const questionnaires = selectedPatient.questionnaires;
    
        if (!questionnaires || questionnaires.length === 0) {
        return <div>No questionnaire data available</div>;
        }
    

    
        const toggleExpandSection = (index) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
        };
    
        return (
            <div className="min-h-screen flex flex-col lg:px-32 px-5 pt-0 lg:pt-0">
            <div className="flex flex-col items-center lg:flex-row justify-between pt-16"></div>
        <div className="p-4">
            <button
            onClick={() => navigate('/Dashboard')}
            className="bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md px-6 py-3 mb-4"
            >
            Takaisin
            </button>
            <h2 className="text-2xl font-bold mb-4">
            Kyselylomakkeen tulokset {selectedPatient.firstName} {selectedPatient.lastName}
            </h2>

            {/* Summary Table */}
            <div className="overflow-auto">
            <table className="w-full mt-4 border-collapse border border-gray-300 text-sm">
                <thead>
                <tr className="bg-gray-100">
                    <th className="border border-gray-300 p-2 whitespace-nowrap">Päivämäärä</th>
                    {questions.map((q) => (
                    <th key={q.id} className="border border-gray-300 p-2 whitespace-nowrap">
                        Q {q.id}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>


                {questionnaires.slice().reverse().map((questionnaire, index) => {
                    const testDate = new Date(questionnaire.testStartTimestamp).toLocaleDateString();
                    // Create a map of questionID to answer for quick lookup
                    const answerMap = {};
                    questionnaire.questionHistory.forEach((qHistory) => {
                    answerMap[qHistory.questionID] = qHistory.answer;
                    });
    
                    return (
                    <tr key={questionnaire._id}>
                        <td className="border border-gray-300 p-2 whitespace-nowrap">{testDate}</td>
                        {questions.map((q) => (
                        <td key={q.id} className="border border-gray-300 p-2 text-center">
                            {answerMap[q.id] !== undefined ? answerMap[q.id] : '-'}
                        </td>
                        ))}
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>


    
            {/* Iterate over all questionnaire results */}
            <div className="space-y-6 mt-8">
            {questionnaires.slice().reverse().map((questionnaire, index) => {
                const questionnaireNumber = questionnaires.length - index; // For numbering
                const testDate = new Date(questionnaire.testStartTimestamp).toLocaleDateString();
    
                return (
                <div
                    key={questionnaire._id}
                    className="bg-white shadow-lg rounded-xl hover:shadow-2xl transition-shadow p-8"
                >
                    <div className="flex items-center justify-between">
                    <h3 className="text-xl font-semibold">
                        Kysely {questionnaireNumber} - {testDate}
                    </h3>
                    <button
                        onClick={() => toggleExpandSection(index)}
                        className="flex items-center text-blue-600 font-semibold"
                    >
                        {expandedSections[index] ? 'Piilota tiedot' : 'Näytä tiedot'}
                        <svg
                        className={`w-5 h-5 ml-2 transition-transform ${
                            expandedSections[index] ? 'rotate-180' : 'rotate-0'
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        ></path>
                        </svg>
                    </button>
                    </div>
    
                    {/* Display questionnaire details when expanded */}
                    {expandedSections[index] && (
                    <div className="mt-4">
                        <p>
                        <strong>Testi suoritettu:</strong>{' '}
                        {new Date(questionnaire.testStartTimestamp).toLocaleString()}
                        </p>
                        <p>
                        <strong>Testi päättyi:</strong>{' '}
                        {new Date(questionnaire.testEndTimestamp).toLocaleString()}
                        </p>
    
                        {/* Questions and Answers */}
                        <div className="mt-6">
                        {questionnaire.questionHistory.map((qHistory, qIndex) => {
                            // Find the question text
                            const question = questions.find((q) => q.id === qHistory.questionID);
                            const questionText = question ? question.text : 'Kysymystä ei löydy';
    
                            // Get the answer descriptions
                            const answerDesc = answerDescriptions[qHistory.questionID];
                            const minDesc = answerDesc ? answerDesc[1] : '';
                            const maxDesc = answerDesc ? answerDesc[10] : '';
    
                            return (
                            <div key={qHistory._id} className="mb-4">
                                <p className="font-semibold">
                                {qIndex + 1}. {questionText}
                                </p>
                                <p>
                                <strong>Vastaus:</strong> {qHistory.answer}
                                </p>
                                <p>
                                <strong>Kuvaus:</strong>{' '}
                                1 {`(${minDesc}) 10 (${maxDesc})`}
                                </p>
                            </div>
                            );
                        })}
                        </div>
                    </div>
                    )}
                </div>
                );
            })}
            </div>
        </div>
        </div>
        );
    };
    


export default PromResults;
