// RoleBasedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const RoleBasedRoute = ({ role, requiredRole, children }) => {
  if (role !== requiredRole) {
    // Redirect to a not authorized or default page if the role does not match
    return <Navigate to="/" replace />;
  }

  return children;
};

export default RoleBasedRoute;
