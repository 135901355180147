import React, { useState, useEffect } from "react";
//import { AbortIcon, CornerDownRightIcon, CrossIcon, InfoIcon, PauseIcon, ReplayIcon, ResumeIcon, SoundIcon } from '../../../assets/Icons'; // Assuming SoundIcon is imported correctly
import QuestionTitle from "../PROM/QuestionTitle";
import RatingScale from "../PROM/RatingScale";
import NavigationBar from "../PROM/NavigationBar";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import isValidJSON from "../../../Functions/IsValidJSON";

const questions = [
  {id: 1, text: "Kuinka helppoa oli suorittaa kuulotesti alusta loppuun?" },
  {id: 2, text: "Kohtasitko vaikeuksia edetessäsi testin eri vaiheissa?"},
  {id: 3, text: "Oliko testissä osioita, joita pidit sekavina tai epäselvinä?"},
  {id: 4, text: "Oliko ohjeet ennen kutakin testiä selkeitä ja helposti ymmärrettäviä?"},
  {id: 5, text: "Pystyitkö helposti syöttämään vastauksesi kyselyyn?"},
  {id: 6, text: "Oliko tekstin koko ja painikkeiden koot sopivia helppokäyttöisyyden kannalta?"}
]

const answerDescriptions = {
  1: { 1: "Erittäin vaikeaa", 10: "Erittäin helppoa" },
  2: { 1: "Erittäin vaikeaa", 10: "Erittäin helppoa" },
  3: { 1: "Paljon epäselvää", 10: "Hyvin selkeää" },
  4: { 1: "Vaikeita ymmärtää", 10: "Helppoja ymmärtää" },
  5: { 1: "Erittäin vaikeaa", 10: "Erittäin helppoa" },
  6: { 1: "Kaipaisin muutoksia", 10: "Erittäin helppokäyttöisiä" },

};
function QuestionnairePROM() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedRating, setSelectedRating] = useState(null); // Track selected rating
  const [answers, setAnswers] = useState([]); // Store answers
  const [comment, setComment] = useState(''); // New state for the comment
  const [testStartTimestamp, setTestStartTimestamp] = useState(null); // Store test start time
  // const answersRef = useRef([])

  const tokenFromStorage = localStorage.getItem("token");
  const token =
    useSelector((state) => state.authState.token) ||
    (tokenFromStorage && isValidJSON(tokenFromStorage)
      ? JSON.parse(tokenFromStorage)
      : null);

  // Set the test start time when the component is first mounted
  useEffect(() => {
    setTestStartTimestamp(new Date()); // Set test start time when the component mounts
  }, []);

  const nextQuestion = () => {
    if (selectedRating) {
      // Store the answer
      const updatedAnswers = [
        ...answers,
        {
          questionID: questions[currentQuestionIndex].id,
          answer: selectedRating,
          comment: comment, // Include the comment
        },
      ];
      setAnswers(updatedAnswers);
      //answersRef.current = updatedAnswers

      if (currentQuestionIndex < questions.length - 1) {
        // Move to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedRating(null); // Reset rating for the next question
        setComment(''); // Reset comment for the next question
      }
    } else {
      toast.warning("Valitse .");
    }
  };

  // Submit all answers to the backend once all 15 questions are answered
  const submitAnswers = async (answers) => {
    const testEndTimestamp = new Date(); // Set the test end time when submitting
    const payload = {
      testStartTimestamp: testStartTimestamp, // Adjust as per your logic
      testEndTimestamp: testEndTimestamp, // Adjust as per your logic
      questionHistory: answers,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/questionnaireUsability",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        console.log("Answers submitted successfully");
        toast.success("Answers submitted successfully!");
      } else {
        throw new Error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error("Error submitting answers. Please try again.");
    }
  };

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating);
  };

  const currentQuestion = questions[currentQuestionIndex];


  return (
    //<main className="flex overflow-hidden flex-col items-center px-4 pt-4 pb-12 bg-gray-100">
    //  <section className="flex flex-col items-center mt-[468px] max-md:mt-10 max-md:max-w-full">
    <main className="h-screen bg-gray-100 overflow-y-auto flex flex-col justify-center items-center relative">
      <section className="inline-flex flex-col items-center gap-8 text-center w-full">
        <div className="box-border h-[20vh]flex justify-center items-center">
          <QuestionTitle text={currentQuestion.text} />
        </div>
        <div>
          <RatingScale
            //descriptions={answerDescriptions[currentQuestion.id]}
            onRatingSelect={handleRatingSelect}
            selectedRating={selectedRating}
          />
          {/* Descriptions Below Rating Scale */}
            <div className="flex justify-between w-full mt-8 px-0">
            <span className="text-xl text-gray-700">{answerDescriptions[currentQuestion.id][1]}</span>
            <span className="text-xl text-gray-700">{answerDescriptions[currentQuestion.id][10]}</span>
          </div>

        {/* Comment Box */}
        <div className="mt-4 w-full px-4">
            <textarea
              className="w-full h-32 p-2 border border-gray-300 rounded-md"
              placeholder="Lisää kommentti tähän halutessasi..." // "Add your comment here..." in Finnish
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          
        </div>
      </section>
      <div className="absolute center bottom-10">
      <NavigationBar
        onNext={nextQuestion}
        isNextEnabled={!!selectedRating}
        isLastQuestion={currentQuestionIndex === questions.length - 1}
        testName = {'Kuulokyselyn kysely'}
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        submitAnswers={() =>
          submitAnswers([
            ...answers,
            { questionID: currentQuestion.id, 
                answer: selectedRating, 
                comment: comment, // Include the comment here
              },
          ])
        } // Ensure last answer is submitted
      />
      </div>
      <ToastContainer />
    </main>
  );
}

export default QuestionnairePROM;
