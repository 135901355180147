import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const AudiogramChart = ({ audiogram }) => {
    const desiredFrequencies = [125, 250, 500, 1000, 2000, 4000, 8000];
    const frequencyOccurrenceMap = {
        1000: 2, // Use the second occurrence of 1000Hz
    };
    // Function to normalize ear values
    const normalizeEarValue = (earValue) => {
        if (earValue === '1' || earValue.toLowerCase() === 'right') return 'right';
        if (earValue === '2' || earValue.toLowerCase() === 'left') return 'left';
        return null;
    };

    const getResultForFrequency = (freq, ear, occurrence = 1) => {
    const normalizedEar = normalizeEarValue(ear);
    const resultsForFreqEar = audiogram.results.filter(
        (result) => result.Hz === freq && normalizeEarValue(result.ear) === normalizedEar
        );
        return resultsForFreqEar[occurrence - 1] || null;
    };

    const rightEarLevels = [];
    const leftEarLevels = [];

    desiredFrequencies.forEach((freq) => {
        const occurrence = frequencyOccurrenceMap[freq] || 1;

        // Right Ear
        const rightResult = getResultForFrequency(freq, 'right', occurrence);
        rightEarLevels.push(rightResult ? rightResult.dbLevel : null);

        // Left Ear
        const leftResult = getResultForFrequency(freq, 'left', occurrence);
        leftEarLevels.push(leftResult ? leftResult.dbLevel : null);
    });

    const data = {
        labels: desiredFrequencies,
        datasets: [
        {
            label: 'Oikea korva',
            data: rightEarLevels,
            fill: false,
            borderColor: 'red',
            pointBackgroundColor: 'white',
            pointBorderColor: 'red',
            pointBorderWidth: 2,
            pointRadius: 6,
            pointStyle: 'circle',
        },
        {
            label: 'Vasen korva',
            data: leftEarLevels,
            fill: false,
            borderColor: 'blue',
            pointBackgroundColor: 'white',
            pointBorderColor: 'blue',
            pointBorderWidth: 2,
            pointRadius: 6,
            pointStyle: 'circle',
        },
    ],
    };

    const options = {
        scales: {
        y: {
        reverse: true, // Audiogram convention
        min: -10, // Set minimum value for y-axis
        max: 110, // Set maximum value for y-axis
        title: {
            display: true,
            text: 'Kuulokynnys (dB)',
        },
        ticks: {
            stepSize: 5, // Show ticks every 5 dB
            callback: function (value) {
                return value % 10 === 0 ? value : '';
            },
        },
        grid: {
            color: function (context) {
            if (context.tick.value % 10 === 0) {
                // Solid lines at every 10 dB
                return 'rgba(0, 0, 0, 0.1)'; // Adjust color as needed
            } else if (context.tick.value % 5 === 0) {
                // Dashed lines at every 5 dB
                return 'rgba(0, 0, 0, 0.1)'; // Adjust color as needed
            }
            return 'transparent'; // No gridline for other ticks
            },
            borderDash: function (context) {
            if (context.tick.value % 10 === 0) {
                // Solid lines
                return []; // Solid line
            } else if (context.tick.value % 5 === 0) {
                // Dashed lines
                return [5, 5]; // Dash pattern: 5px dash, 5px gap
            }
            return []; // No gridline for other ticks
            },
            lineWidth: function (context) {
            if (context.tick.value % 5 === 0) {
                return 1; // Line width for both solid and dashed lines
            }
            return 0; // No line for other ticks
            },
        },
        },
        x: {
        title: {
            display: true,
            text: 'Taajuus (Hz)',
        },
        },
    },
    };

    return <Line data={data} options={options} />;
};

export default AudiogramChart;
