import React, { useState, useEffect } from "react";
import CalibrationBox from "./CalibrationBox";
import FrequencyDataBox from "./FrequencyDataBox";
import SettingsNavbar from "../../Settingspage/SettingsNavbar";
import axios from "axios";
import isElectron from "../../../../Functions/isElectron";

const AudiogramCalibrationMain = () => {
  //const navigate = useNavigate();
  const [calibrationData, setCalibrationData] = useState([]);
  const [side, setSide] = useState("-1"); // Default side (Left)
  const [frequency, setFrequency] = useState("125"); // Default frequency (125Hz)

  // Fetch calibration data on side or frequency change
  useEffect(() => {
    fetchCalibrationData(side, frequency);
  }, [side, frequency]);

  // Fetch calibration data function with fallback to server
  const fetchCalibrationData = async (selectedSide, selectedFrequency) => {
    try {
      let data;
      if (isElectron() && window.electron && window.electron.loadAudiometryCalibration) {
        // Create an args object
        const args = {
          side: selectedSide,
          frequency: selectedFrequency,
        };
        // Try to fetch data from local file first
        const localResponse = await window.electron.loadAudiometryCalibration(args);
        if (localResponse.success) {
          data = localResponse.data;
        }
      }
      
      // Fallback to server if no local data or if not in Electron
      if (!data) {
        console.log("No local data found loading from server");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/${selectedSide}/${selectedFrequency}`
        );
        if (response.data.success) {
          data = response.data.data;
        } else {
          data = [];
        }
      }

      setCalibrationData(data || []); // Set data or empty array if no data found
    } catch (error) {
      console.error("Error fetching calibration data:", error);
      setCalibrationData([]); // Clear data if there's an error
    }
  };

  // Callback to refresh data after saving
  const refreshDataAfterSave = () => {
    fetchCalibrationData(side, frequency);
  };

  // Handle side and frequency selection changes in CalibrationBox
  const handleSelectChange = (selectedSide, selectedFrequency) => {
    setSide(selectedSide);
    setFrequency(selectedFrequency);
    // No need to call fetchCalibrationData here, useEffect will handle it
  };

  return (
    <div className="min-h-screen bg-gray-100 flex p-6">
      <div className=""></div>
      <SettingsNavbar />

      {/* Main Content Layout */}
      <div className="flex-grow p-10 bg-white">
        <CalibrationBox
          onSelectChange={handleSelectChange}
          fetchCalibrationData={fetchCalibrationData}
          refreshDataAfterSave={refreshDataAfterSave}
        />
      </div>
      <div className="flex-grow p-10 bg-white">
        <FrequencyDataBox calibrationData={calibrationData} />
      </div>
    </div>
  );
};

export default AudiogramCalibrationMain;
