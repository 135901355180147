import { createSlice } from "@reduxjs/toolkit";

export const sideBarSlice = createSlice({
  name: "sidebar",
  initialState: {
    open: false,
    practionerOpen: false,
    practionerOpenSmallScreen: false,
    practionerMenu: false,
    selectedNavbarNavigationIndex: 0,

  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },

    setPractionerOpen: (state, action) => {
      state.practionerOpen = action.payload;
    },
    setPractionerMenu: (state, action) => {
      state.practionerMenu = action.payload;
    },
    setSelectedNavbarNavigationIndex: (state, action) => {
      state.selectedNavbarNavigationIndex = action.payload;
    },
    setPractionerOpenSmallScreen: (state, action) => {
      state.practionerOpenSmallScreen = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setOpen, setPractionerOpen, setPractionerMenu, setSelectedNavbarNavigationIndex, setPractionerOpenSmallScreen } = sideBarSlice.actions;
