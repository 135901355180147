import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { curveFitLogistic, getAverageAnswers, getUniqueX } from "./LogisticHelper";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LogisticChart = ({ xData, yData, title = "Logistic Fit Data", buttonAction }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [L50, setL50] = useState(0);
    const [slope, setSlope] = useState(0);

    useEffect(() => {
        if (xData.length === 0 || yData.length === 0) return;

        // Get unique X values and corresponding average Y values
        const uniqueX = getUniqueX(xData);
        const averageY = getAverageAnswers(xData, yData, uniqueX);

        // Perform logistic regression and get fitted data
        const { logisticX, logisticY, L50, slope } = curveFitLogistic(uniqueX, averageY);
        setL50(L50);
        setSlope(slope);
        setChartData({
            labels: logisticX, // X-axis data for the logistic fit
            datasets: [
                {
                    label: "Aggregated Data Points ",
                    data: uniqueX.map((x, index) => ({
                        x,
                        y: averageY[index],
                    })),
                    borderColor: "red",
                    backgroundColor: "red",
                    borderWidth: 0,
                    fill: false,
                    pointRadius: 5, // Ensure points are visible
                    pointHoverRadius: 7,
                    showLine: false, // Disable the line between points
                },
                {
                    label: "Logistic Fit",
                    data: logisticX.map((x, index) => ({
                        x,
                        y: logisticY[index],
                    })),
                    borderColor: "orange",
                    backgroundColor: "orange",
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0,
                    tension: 0.4, // Smooth curve for logistic fit
                },
            ],
        });
    }, [xData, yData]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "SNR (dB)",
                },
                type: "linear", // Ensure linear scaling of the X-axis
                min: Math.min(...xData) - 1, // Ensure proper range for X-axis
                max: Math.max(...xData) + 1, // Padding for better display
            },
            y: {
                title: {
                    display: true,
                    text: "Probability of Correct Identification",
                },
                type: "linear",
                min: -0.2,
                max: 1.2,
            },
        },
    };

    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center p-6">
            <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg p-6">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">{title} {`(L50:${L50.toFixed(2)}) `} {`(Slope:${slope.toFixed(2)})`}</h1>
                <div className="relative w-full h-auto mb-6">
                    <Line data={chartData} options={chartOptions} />
                </div>
                <div className="text-center mt-6">
                    <button
                        className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                        onClick={buttonAction}
                    >
                        Download Graph
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LogisticChart;
