import React, { useState, useEffect } from 'react';
import './FullPageModalWrapperKiosk.css';

const FullPageModalWrapperKiosk = ({ isOpen, isClosing, children, className = '' }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    console.log({isOpen,isClosing})
    // Control visibility based on the isOpen prop
    useEffect(() => {
        if (isOpen) {
            setIsModalVisible(true);
        } else if (!isOpen && isModalVisible) {
            // setTimeout(() => setIsModalVisible(false), 300); // Allow closing animation to finish
            setIsModalVisible(false); // Delay closing to allow the closing animation
        }
    }, [isOpen, isModalVisible]);

    if (!isOpen && !isModalVisible) return null;

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 transition-opacity ${isClosing ? 'fade-out' : 'fade-in'}`}>
            <div className={`fullpage-modal ${className} ${isClosing ? 'modal-closing' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default FullPageModalWrapperKiosk;
