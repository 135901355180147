import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { createRoot } from 'react-dom/client';
import { FaHeadphones, FaSpeakerDeck, FaAssistiveListeningSystems } from 'react-icons/fa';
const HierarchicalScatterPlot = ({ data, width = 900, height = 700 }) => {
  const svgRef = useRef();
  const iconRefs = useRef([]); // Use refs to position icons later
  const [iconPositionsUpdated, setIconPositionsUpdated] = useState(false); // State to trigger re-render for icons
  const iconContainerRef = useRef(); // Separate container for icons

  useEffect(() => {
    if (!data || data.length === 0) return;
    // Clear iconRefs on each render to prevent duplication
    iconRefs.current = [];
    // Clear the icons in the container on each render
    iconContainerRef.current.innerHTML = '';
    // Dynamically generate unique styles for each device
    const uniqueDevices = [...new Set(data.map(d => d.device))];
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    const deviceStyles = Object.fromEntries(
      uniqueDevices.map((device, i) => [
        device,
        { color: colorScale(i), dash: `${(i + 1) * 2}, ${(i + 1) * 2}` }
      ])
    );

    const margin = { top: 80, right: 60, bottom: 120, left: 80 };

    d3.select(svgRef.current).selectAll('*').remove();
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

    // Define x and y scales
    const x0 = d3.scaleBand()
      .domain([...new Set(data.map(d => d.date))])
      .range([margin.left, width - margin.right])
      .padding(0.3);

    const x1 = d3.scaleBand()
      .domain(uniqueDevices)
      .range([0, x0.bandwidth()])
      .padding(0.2);

    // Y-axis scale to handle both positive and negative values
    const minY = d3.min(data, d => d.value);
    const maxY = d3.max(data, d => d.value);
    const y = d3.scaleLinear()
      .domain([Math.min(0, minY), Math.max(0, maxY)]) // Ensures centered 0 for positive & negative values
      .nice()
      .range([height - margin.bottom, margin.top]);




    // X-axis with main category (date) and sub-category (device) labels
    const xAxis = (g) => {
      g.attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x0).tickSize(0).tickPadding(15))
        .selectAll('text')
        .attr('font-size', '14px')
        .attr('font-family', 'Arial, sans-serif')
        .style('fill', '#333')
        .attr('y', 10);

      const labelGap = 2; // Controls spacing between labels
      const categoryGap = 1; // Controls spacing between each subcategory in the x-axis

      // Update the x1 scale range to add padding between subcategories
      const x1 = d3.scaleBand()
        .domain(uniqueDevices)
        .range([0, x0.bandwidth() - categoryGap]) // Reduced range to create gaps between subcategories
        .padding(0.3); // Padding between subcategories

      g.selectAll('.tick')
        .append('g')
        .attr('transform', `translate(-60, 20)`)
        // .each(function () {
        //   const subG = d3.select(this);
        //   uniqueDevices.forEach((sub, i) => {
        //     subG.append('text')
        //       .text(sub[0])
        //       .attr('x', x1(sub) + x1.bandwidth() / 2 + i * labelGap) // Add spacing based on index
        //       .attr('font-size', '12px')
        //       .attr('font-family', 'Arial, sans-serif')
        //       .style('fill', '#555')
        //       .attr('dy', '1.5em')
        //       .attr('text-anchor', 'middle');
        //   });
        // });
    };

    // Y-axis with positive and negative values
    const yAxis = (g) => g.attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y)
        .ticks(12)
        .tickSize(-width + margin.left + margin.right)
        .tickPadding(10)
      )
      .selectAll('text')
      .attr('font-size', '14px')
      .attr('font-family', 'Arial, sans-serif')
      .style('fill', '#333');

    svg.append('g').call(xAxis);
    svg.append('g').call(yAxis);

    // Append the x-axis and y-axis
    // svg.append('g').call(d3.axisBottom(x0).tickSize(0).tickPadding(15))
    //   .attr('transform', `translate(0,${height - margin.bottom})`);
    // svg.append('g').call(d3.axisLeft(y).ticks(10).tickSize(-width + margin.left + margin.right))
    //   .attr('transform', `translate(${margin.left},0)`);



    // Adding icons with a calculated position
    const icons = {
      Headphone: FaHeadphones,
      Speaker: FaSpeakerDeck,
      'Hearing Aid': FaAssistiveListeningSystems,
    };
    uniqueDevices.forEach((device, i) => {
      data.filter(d => d.device === device).forEach((d, j) => {
        const xPos = x0(d.date) + x1(device) + x1.bandwidth() / 2 + i * 0;
        const yPos = height - margin.bottom + 40; // Adjust Y for positioning below X-axis labels
        /* Using react */
        iconRefs.current.push({ Icon: icons[device], x: xPos + 2, y: yPos });

        // Append icons to the container with ReactDOM
        const iconElement = document.createElement('div');
        iconElement.style.position = 'absolute';
        iconElement.style.left = `${xPos}px`;
        iconElement.style.top = `${yPos}px`;
        iconElement.style.transform = 'translate(-50%, -50%)';
        iconElement.style.fontSize = '16px';
        iconElement.style.color = '#555';

        const Icon = icons[device];
        createRoot(iconElement).render(<Icon />);
        iconContainerRef.current.appendChild(iconElement);
      });
    });
    // Trigger re-render to display icons after positioning
    setIconPositionsUpdated(prev => true);
    // Vertical grid lines for each sub-category within each date band
    svg.append('g')
      .selectAll('line')
      .data(data)
      .join('line')
      .attr('x1', d => x0(d.date) + x1(d.device) + x1.bandwidth() / 2)
      .attr('x2', d => x0(d.date) + x1(d.device) + x1.bandwidth() / 2)
      .attr('y1', margin.top)
      .attr('y2', height - margin.bottom)
      .attr('stroke', d => deviceStyles[d.device].color)
      .attr('stroke-dasharray', d => deviceStyles[d.device].dash)
      .attr('stroke-opacity', 0.8);

    // Title and Axis Labels
    svg.append('text')
      .attr('x', width / 2)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '22px')
      .attr('font-family', 'Arial, sans-serif')
      .attr('font-weight', '700')
      .style('fill', '#333')
      .text('Device Test Values Across Dates');

    svg.append('text')
      .attr('x', width / 2)
      .attr('y', height - 50)
      .attr('text-anchor', 'middle')
      .attr('font-size', '14px')
      .attr('font-family', 'Arial, sans-serif')
      .style('fill', '#666')
      .text('Dates (Main Categories) and Devices (Sub-Categories)');

    svg.append('text')
      .attr('x', -height / 2)
      .attr('y', margin.left / 2 - 20)
      .attr('transform', 'rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('font-size', '14px')
      .attr('font-family', 'Arial, sans-serif')
      .style('fill', '#666')
      .text('Test Value (dB)');

    // Tooltip for displaying data on hover
    // Tooltip for displaying data on hover
    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', '#ffffff')
      .style('border', '1px solid #ddd')
      .style('padding', '10px')
      .style('border-radius', '8px')
      .style('font-size', '13px')
      .style('box-shadow', '0px 4px 12px rgba(0, 0, 0, 0.2)')
      .style('color', '#333')
      .style('z-index', '10')
      .style('cursor', 'pointer'); // Higher z-index for tooltip
    // Plot data points with shadows
    // Plot data points with glow effects
    svg.append('g')
      .selectAll('circle')
      .data(data)
      .join('circle')
      .attr('cx', d => x0(d.date) + x1(d.device) + x1.bandwidth() / 2)
      .attr('cy', d => y(d.value))
      .attr('r', 7)
      .attr('fill', d => deviceStyles[d.device].color)
      .attr('opacity', 0.85)
      .style('cursor', 'pointer')
      .style('filter', 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2))')
      .on('mouseover', function (event, d) {
        tooltip.style('visibility', 'visible')
          .html(`<strong>Date:</strong> ${d.date}<br><strong>Device:</strong> ${d.device}<br><strong>Value:</strong> ${d.value} dB`);
      })
      .on('mousemove', (event) => {
        tooltip.style('top', `${event.pageY - 10}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));

    // Legend configuration
    const legend = svg.append('g')
      .attr('transform', `translate(${width - margin.right - 180},${margin.top})`)
      .attr('font-family', 'Arial, sans-serif')
      .attr('font-size', '12px');

    legend.append('rect')
      .attr('x', -10)
      .attr('y', -10)
      .attr('width', 140)
      .attr('height', uniqueDevices.length * 25 + 10)
      .attr('rx', 8)
      .attr('fill', 'rgba(255, 255, 255, 0.8)')
      .attr('stroke', '#ddd')
      .style('filter', 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))');

    Object.entries(deviceStyles).forEach(([device, style], i) => {
      const legendItem = legend.append('g')
        .attr('transform', `translate(0, ${i * 25})`);

      legendItem.append('line')
        .attr('x1', 0)
        .attr('x2', 20)
        .attr('y1', 10)
        .attr('y2', 10)
        .attr('stroke', style.color)
        .attr('stroke-dasharray', style.dash)
        .attr('stroke-width', 2);

      legendItem.append('text')
        .attr('x', 30)
        .attr('y', 15)
        .style('fill', '#333')
        .text(device);

      legendItem.append('text')
        .attr('x', 30)
        .attr('y', 15)
        .style('fill', '#333');

    });



  }, [data, width, height]);

  return (
    <div style={{ position: 'relative', width, height, zIndex: 10 }}>
      <svg ref={svgRef} style={{ zIndex: '2' }}></svg> {/* Higher z-index for SVG */}
      {/* {iconPositionsUpdated && iconRefs.current.map(({ Icon, x, y }, idx) => (
        <Icon
          key={idx}
          style={{
            position: 'absolute',
            left: x,
            top: y,
            fontSize: '16px',
            color: '#555',
            transform: 'translate(-50%, -50%)'
          }}
        />
      ))} */}
      <div ref={iconContainerRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', }}></div>
    </div>);

};

export default HierarchicalScatterPlot;
