import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const AudiogramChart = ({
    data1,
    data2,
    width = 500,
    height = 500,
    title = "Audiogram",
    label1 = "Left Ear",
    label2 = "Right Ear",
}) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height + 50)
            .style("overflow", "visible");

        const margin = { top: 60, right: 30, bottom: 50, left: 60 };
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        // Create interpolated domain with extra points for xScale
        const frequencies = data1.map(d => d.frequency);
        const interpolatedFrequencies = [];
        for (let i = 0; i < frequencies.length - 1; i++) {
            interpolatedFrequencies.push(frequencies[i]);
            interpolatedFrequencies.push((frequencies[i] + frequencies[i + 1]) / 2); // Add midpoint
        }
        interpolatedFrequencies.push(frequencies[frequencies.length - 1]);

        // Adjust X scale to use interpolated frequencies for additional grid lines
        const xScale = d3.scalePoint()
            .domain(interpolatedFrequencies)
            .range([0, chartWidth]);

        // Y scale remains the same
        const yScale = d3.scaleLinear()
            .domain([-10, 110])
            .range([0, chartHeight]);

        // Clear existing content
        svg.selectAll("*").remove();

        const chart = svg.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Title
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", 40)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("font-weight", "bold")
            .text(title);

        // Grid lines with interpolated xScale for denser vertical grid lines
        chart.append("g")
            .attr("class", "grid")
            .call(d3.axisLeft(yScale).tickSize(-chartWidth).tickFormat(""))
            .style("stroke-dasharray", "0")
            .style('stroke-opacity', 0.3);

        chart.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0, ${chartHeight})`)
            .call(d3.axisBottom(xScale).tickSize(-chartHeight).tickFormat(""))
            .style("stroke-dasharray", "0")
            .style('stroke-opacity', 0.3);

        // X axis
        chart.append("g")
            .attr("transform", `translate(0, ${chartHeight})`)
            .call(d3.axisBottom(xScale).tickValues(frequencies).tickFormat(d => d + " Hz"));

        // Y axis
        chart.append("g")
            .call(d3.axisLeft(yScale).tickFormat(d => d + " dB"));

        // Tooltip setup remains the same
        const tooltip = d3.select('body')
            .append('div')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', '#ffffff')
            .style('border', '1px solid #ddd')
            .style('padding', '10px')
            .style('border-radius', '8px')
            .style('font-size', '13px')
            .style('box-shadow', '0px 4px 12px rgba(0, 0, 0, 0.2)')
            .style('color', '#333')
            .style('z-index', '10')
            .style('cursor', 'pointer');

        // Function to plot data remains the same
        const plotData = (data, color, shape) => {
            // Add line path
            chart.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", color)
                .attr("stroke-width", 2)
                .attr("d", d3.line()
                    .x(d => xScale(d.frequency))
                    .y(d => yScale(d.db))
                );

            // Plot circles and crosses
            if (shape === 'circle') {
                chart.selectAll(`.${color}-circle`)
                    .data(data)
                    .enter()
                    .append("circle")
                    .attr("class", `${color}-circle`)
                    .attr("cx", d => xScale(d.frequency))
                    .attr("cy", d => yScale(d.db))
                    .attr("r", 5)
                    .attr("fill", color)
                    .style("cursor", 'pointer')
                    .on("mouseover", (event, d) => {
                        tooltip.style("visibility", "visible")
                            .text(`Frequency: ${d.frequency} Hz, dB: ${d.db}`);
                    })
                    .on("mousemove", (event) => {
                        tooltip.style("top", (event.pageY - 10) + "px")
                            .style("left", (event.pageX + 10) + "px");
                    })
                    .on("mouseout", () => {
                        tooltip.style("visibility", "hidden");
                    });
            } else if (shape === 'cross') {
                chart.selectAll(`.${color}-cross`)
                    .data(data)
                    .enter()
                    .append("g")
                    .attr("transform", d => `translate(${xScale(d.frequency)}, ${yScale(d.db)})`)
                    .each(function () {
                        d3.select(this).append("line")
                            .attr("x1", -5)
                            .attr("y1", -5)
                            .attr("x2", 5)
                            .attr("y2", 5)
                            .attr("stroke", color)
                            .attr("stroke-width", 2);

                        d3.select(this).append("line")
                            .attr("x1", -5)
                            .attr("y1", 5)
                            .attr("x2", 5)
                            .attr("y2", -5)
                            .attr("stroke", color)
                            .attr("stroke-width", 2);
                    })
                    .style("cursor", 'pointer')
                    .on("mouseover", (event, d) => {
                        tooltip.style("visibility", "visible")
                            .text(`Frequency: ${d.frequency} Hz, dB: ${d.db}`);
                    })
                    .on("mousemove", (event) => {
                        tooltip.style("top", (event.pageY - 10) + "px")
                            .style("left", (event.pageX + 10) + "px");
                    })
                    .on("mouseout", () => {
                        tooltip.style("visibility", "hidden");
                    });
            }
        };

        // Plot both datasets
        plotData(data1, "red", "circle");
        plotData(data2, "blue", "cross");

        // X-axis label
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height + margin.bottom - 50)
            .attr("text-anchor", "middle")
            .style("font-size", "12px")
            .text("Frequency (Hz)");

        // Y-axis label
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${margin.left - 42}, ${height / 2}) rotate(-90)`)
            .style("font-size", "12px")
            .text("Hearing threshold (dB HL)");

        // Legend
        svg.append("circle").attr("cx", width - 120).attr("cy", 40).attr("r", 6).style("fill", "red");
        svg.append("text").attr("x", width - 110).attr("y", 40).text(label1).style("font-size", "12px").attr("alignment-baseline", "middle");

        svg.append("circle").attr("cx", width - 120).attr("cy", 55).attr("r", 6).style("fill", "blue");
        svg.append("text").attr("x", width - 110).attr("y", 55).text(label2).style("font-size", "12px").attr("alignment-baseline", "middle");

        return () => tooltip.remove();

    }, [data1, data2, width, height, title, label1, label2]);

    return (
        <div style={{ position: "relative" }}>
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default AudiogramChart;
