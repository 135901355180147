import React from 'react';

const FrequencyDataBox = ({ calibrationData }) => {
  return (
    <div className="frequency-data-box  p-4 rounded-md">
      <h3 className="text-lg font-bold mb-4">Tallennetut tasot taajuuksittain</h3>

      {/* Render Calibration Data */}
      <div>
        {calibrationData.length === 0 ? (
          <p>No data available for the selected side and frequency.</p>
        ) : (
          <table className="min-w-1/2  bg-white">
            <thead>
              <tr>
                <th className="py-1 px-2 border">dB Level</th>
                <th className="py-1 px-2 border">Calibration Factor</th>
              </tr>
            </thead>
            <tbody>
              {calibrationData.map((entry, index) => (
                <tr key={index}>
                  <td className="py-1 px-4 border">{entry.dbLevel}</td>
                  <td className="py-1 px-4 border">{entry.calibrationFactor}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default FrequencyDataBox;
