import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEmployee, setEmployeeToken, setToken, setUser } from '../../Slices/authSlice';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Lock_Icon } from '../../../src/assets/Icons'; // Assuming the Lock_Icon is available

const PageZero = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Set default login credentials (preset for demo)
    const [loginInformationStaff, setLoginInformationStaff] = useState({
        userName: '',    // Default username
        password: ''  // Default password
    });

    // Handle login process
    const handleLogin = async () => {
        try {
            const { userName, password } = loginInformationStaff;
            if (!userName || !password) {
                toast.error('All fields are required', { position: 'top-right' });
                return;
            }

            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/login`, {
                userName: userName,
                password: password
            });

            if (data.status === false) {
                throw new Error(data.message);
            }

            toast.success("Login successful");

            // Set user and token in Redux
            /* Set global states */
            dispatch(setEmployee(data.employee));
            dispatch(setEmployeeToken(data.token));
            dispatch(setUser(data.employee));
            dispatch(setToken(data.token));
            toast.success("Please Wait You will redirected soon");

            // Redirect to the settings page after login
            setTimeout(() => {
                navigate('/LandingPage');
            }, 500);
        } catch (error) {
            toast.error(`Login failed: ${error.message}`);
        }
    };

    return (
        <div className='h-screen bg-gray-700 flex items-center justify-center'>
            <div className='bg-white p-6 rounded-lg shadow-lg w-80 relative'>
                <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                    <ToastContainer />
                    <div className='flex justify-center'>
                        <img src={Lock_Icon} alt="Lock Icon" />
                    </div>
                    <h2 className='text-center text-xl text-[#101828] font-bold mt-4 mb-4'>TrueHear</h2>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold'>Käyttäjänimi</label>
                        <input
                            type='text'
                            name='userName'
                            value={loginInformationStaff.userName}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, userName: e.target.value })}
                            className='w-full p-2 border rounded'
                            placeholder='Enter your username'
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold'>salasana</label>
                        <input
                            type='password'
                            name='password'
                            value={loginInformationStaff.password}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, password: e.target.value })}
                            className='w-full p-2 border rounded'
                            placeholder='Enter your password'
                        />
                    </div>

                    <div className='flex flex-col mt-1'>
                        <button
                            type='submit'
                            className='w-full bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md'
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageZero;
