

// Modal for questionnaire!

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AbortIcon } from '../../assets/Icons'; // Assuming SoundIcon is imported correctly
import "./Modal2.css"

const AbortPage = ({ onClose }) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    console.log("Continuing the test");
    onClose(); // Close the modal
  };

  const handleAbort = () => {
    console.log("Aborting test");
    navigate('/TestLandingPage'); // Redirect to home page or wherever you want after abort
  };

  return (
    <div className="sign-out-modal-overlay">
        <div className="sign-out-modal modal-content">
          {/* Abort Icon Display */}
          <div className="flex justify-left mb-4">
            <img src={AbortIcon} alt="Abort Icon" className="w-16 h-16" />
          </div>
            <p className="sign-out-modal-text font-bold text-left">Keskeytä testi</p>
            <p className="sign-out-modal-text2 text-[#101828]  text-2xl text-left">Oletko varma, että haluat keskeyttää testin? </p>
              <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
                <button onClick={handleContinue} className="flex justify-center items-center bg-white text-gray-500 border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-300 gap-x-1 cursor-pointer flex-1">Palaa testiin</button>
                <button onClick={handleAbort} className="flex justify-center items-center bg-red-600 text-gray-100 border border-gray-300 py-3 px-3 rounded-lg transition-colors duration-300 ease-in-out hover:bg-red-800 gap-x-1 cursor-pointer flex-1">Keskeytä</button>
        
              </div>
        </div>
    </div>
  );
};

export default AbortPage;
