import * as math from "mathjs";
import { levenbergMarquardt } from "ml-levenberg-marquardt"; // Use the named export

// Logistic function definition
export const logistic = (x, L50, s) => {
    return 1 / (1 + Math.exp(4 * s * (L50 - x)));
};

// Wrapper function to pass into the fitting algorithm
const logisticWrapper = ([L50, s]) => {
    return (x) => logistic(x, L50, s);
};

// Function to get unique X values from a dataset
export const getUniqueX = (xData) => {
    return Array.from(new Set(xData));
};

// Function to calculate the average Y values for each unique X
export const getAverageAnswers = (xData, yData, uniqueX) => {
    return uniqueX.map((value) => {
        const filteredAnswers = yData.filter((_, index) => xData[index] === value);
        return filteredAnswers.reduce((sum, current) => sum + current, 0) / filteredAnswers.length;
    });
};

// Curve fitting function using nonlinear least squares (levenberg-marquardt)
export const curveFitLogistic = (xData, yData, options = {}) => {
    const initialGuess = options.initialGuess || [math.median(xData), 0.1]; // Default initial guess
    const fitOptions = {
        damping: options.damping || 1.5,
        gradientDifference: options.gradientDifference || 10e-2,
        maxIterations: options.maxIterations || 100,
        errorTolerance: options.errorTolerance || 10e-3,
    };

    // Generate logistic curve data points
    const logisticX = math.range(math.min(xData), math.max(xData), 0.1).toArray();

    // Perform the curve fitting using levenberg-marquardt
    const fittedParams = levenbergMarquardt(
        { x: xData, y: yData },
        logisticWrapper,
        { initialValues: initialGuess },
        fitOptions
    );

    const [L50, slope] = fittedParams.parameterValues;
    const logisticY = logisticX.map((x) => logistic(x, L50, slope));

    return { logisticX, logisticY, L50, slope };
};


export const generateStats = (digitInNoiseArray) => {
    const stats = {};

    digitInNoiseArray.forEach((test) => {
        const testDate = new Date(test.testStartTimestamp).toLocaleDateString('en-GB');
        const { questionHistory } = test;

        if (!questionHistory || questionHistory.length === 0) return; // Check if questionHistory exists and has content

        questionHistory.forEach((round) => {
            const testName = round.name && round.name.includes("Muutumaton") ? "Muutumaton" : "Adaptive";

            // Initialize arrays if not already present
            if (!stats[testDate]) stats[testDate] = { Muutumaton: [], Adaptive: [] };

            if (round.value && Array.isArray(round.value)) {
                const snrValues = round.value.map(q => q.speechDB - 65);
                const correctAnswers = round.value.map(q => (q.correct ? 1 : 0));

                if (testName === "Muutumaton") {
                    const avgCorrect = correctAnswers.reduce((sum, val) => sum + val, 0) / correctAnswers.length;
                    stats[testDate].Muutumaton.push(avgCorrect);
                } else if (testName === "Adaptive") {
                    const uniqueSNR = getUniqueX(snrValues);
                    const avgCorrectForSNR = getAverageAnswers(snrValues, correctAnswers, uniqueSNR);
                    const { L50 } = curveFitLogistic(uniqueSNR, avgCorrectForSNR);
                    console.log({ testDate, L50 });
                    if (L50 !== null)
                        stats[testDate].Adaptive.push(L50);
                }
            }
        });
    });

    // Daily average calculation with two decimal places
    const averagedStats = Object.keys(stats).map(date => ({
        date,
        Muutumaton: stats[date].Muutumaton.length > 0
            ? (stats[date].Muutumaton.reduce((sum, val) => sum + val, 0) / stats[date].Muutumaton.length).toFixed(2)
            : null,
        Adaptive: stats[date].Adaptive.length > 0
            ? (stats[date].Adaptive.reduce((sum, val) => sum + val, 0) / stats[date].Adaptive.length).toFixed(2)
            : null,
    }));

    console.log({ stats, averagedStats });

    return averagedStats;
};
