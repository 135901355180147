import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Modals/InfoModalv1/InfoModal.css'; // Importing the CSS file for animations
import './Modal2.css';

const AbortPage = ({ onClose, resumeTest,  }) => {
  const navigate = useNavigate();
  const EnableContinueButtonRef = useRef(false); // Initially set to false
  const EnableAbortButtonRef = useRef(false); // Initially set to false for the "Keskeytä" button
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // State to trigger re-render

  useEffect(() => {
    // Simulate enabling the buttons after a condition is met (e.g., after 3 seconds)
    const timeout = setTimeout(() => {
      EnableContinueButtonRef.current = true;
      EnableAbortButtonRef.current = true; // Enable "Keskeytä" button as well
      setIsButtonEnabled(true); // Trigger re-render
    }, 3000); // Example: Enable the buttons after 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on unmount
  }, []);

  const handleContinue = () => {
    if (EnableContinueButtonRef.current) {
      console.log('Continuing the test');
      resumeTest(); // Call resumeTest to resume the test
      onClose(); // Close the modal
    }
  };

  const handleAbort = () => {
    if (EnableAbortButtonRef.current) {
      console.log('Aborting test');
      navigate('/TestLandingPage'); // Redirect to the landing page after abort
    }
  };

  return (
    <div className="sign-out-modal-overlay">
      <div className="sign-out-modal modal-content">
        <p className="sign-out-modal-text"></p>
        <p className="sign-out-modal-text2 text-[#101828] font-bold text-2xl text-left">
          Testi on pysäytetty. Jos tarvitsette apua testin tekemiseen, voitte nyt käydä pyytämässä apua.
          Jos haluatte keskeyttää testin, palatkaa testiin alla olevasta painikkesta "Palaa testiin", testi ei jatku automaattisesti.
          Testi jatkuu painamalla "Jatka Testiä" - painiketta seuraavasta ruudusta.
        </p>
        <br />
        <p className="sign-out-modal-text2 text-[#1D2939] text-[22px] text-left">
          Testi jatkuu "Palaa testiin" painikkeen painamisen jälkeen
        </p>
        <br />
        <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
          {/* "Palaa testiin" button */}
          <button
            onClick={handleContinue}
            disabled={!EnableContinueButtonRef.current} // Disable button until enabled
            className={`flex justify-center items-center border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out gap-x-1 flex-1 ${
              EnableContinueButtonRef.current
                ? 'bg-white text-gray-500 hover:bg-gray-300 cursor-pointer' // Normal enabled styling
                : 'bg-gray-200 text-gray-400 cursor-not-allowed' // Grayed-out styling
            }`}
          >
            Palaa testiin
          </button>

          {/* "Keskeytä" button  REMOVED BECAUSE THE DATA IS NOT SAVED WHE GOING THROUGH THIS ROUTE
          <button
            onClick={handleAbort}
            disabled={!EnableAbortButtonRef.current} // Disable button until enabled
            className={`flex justify-center items-center border border-gray-300 py-3 px-3 rounded-lg transition-colors duration-300 ease-in-out gap-x-1 flex-1 ${
              EnableAbortButtonRef.current
                ? 'bg-red-600 text-gray-100 hover:bg-red-800 cursor-pointer' // Red enabled styling
                : 'bg-gray-200 text-gray-400 cursor-not-allowed' // Grayed-out disabled styling
            }`}
          >
            Keskeytä
            
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default AbortPage;
