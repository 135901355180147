import React, { useState } from 'react';
import { Cloud_ELLIPSE, FINFlagIcon, Kela_Card_Icon, Key_Icon, Lock_Icon, UKFlagIcon } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import { toast, ToastContainer } from 'react-toastify';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setEmployee, setEmployeeToken, setToken, setUser } from '../../../Slices/authSlice';
import Modal from '../../Modals/Modal';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../hooks/useLanguage';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';

/* Function to generate clouds dynamically */
const generateCloudsv1 = (positions) => {
    return positions.map((position, index) => {
        return (
            <img
                key={index}
                src={Cloud_ELLIPSE}
                alt="Cloud Ellipse"
                style={{
                    position: 'absolute',
                    top: `${position}%`,
                    right: '-10%',
                    transform: 'translateY(-50%)'  // This will center the cloud vertically
                }}
                className="cloud"
            />
        );
    });
};


const LandingPage = () => {
    /* Cloud positions */
    const largeScreenPositions = [5, 25, 45, 65, 85, 105];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    /* Languate Config */
    const { t } = useTranslation();



    const { language, changeLanguage } = useLanguage();
    /* Staff Login System */
    console.log(language);

    const [showTooltipStaff, setShowTooltipStaff] = useState(false);
    const [showTooltipLanguage, setShowTooltipLanguage] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [loginInformationStaff, setLoginInformationStaff] = useState({
        userName: '',
        password: ''
    });



    const handleLogin = async () => {
        // Handle the staff login logic here
        try {
            const { userName, password } = loginInformationStaff;
            if (!userName || !password)
                toast.error('All fields requried', { position: 'top-right' });
            console.log({ userName, password });
            const { data } = await axios.post(process.env.REACT_APP_BACKEND_URL + '/employee/login',
                {
                    userName: userName,
                    password: password
                }
            );
            console.log(data);
            if (data.status === false)
                throw new Error(data.message);

            toast.success(data.message);
            /* Set global states */
            dispatch(setEmployee(data.employee));
            dispatch(setEmployeeToken(data.token));
            dispatch(setUser(data.employee));
            dispatch(setToken(data.token));
            toast.success("Please Wait You will redirected soon");

            /* Add Employee data to local storage */
            localStorage.setItem('user', JSON.stringify(data.employee));
            localStorage.setItem('token', JSON.stringify(data.token));
            /* Remove these */
            setTimeout(() => {
                navigate('/MainSettings');
            }, 1000);

        } catch (error) {
            // setIsModalVisible(false); // Close the modal after login
            toast.error(error.message);

        }
    };
    /* Make the modal Visible */
    const handleLoginStaffClick = () => {
        setIsModalVisible(true);
    };
    /* Close the modal */
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className='relative'>
            {/* Staff Login Image */}
            <div
                className='absolute z-40 right-2 top-1 cursor-pointer'
                onMouseEnter={() => setShowTooltipStaff(true)}
                onMouseLeave={() => setShowTooltipStaff(false)}
                onClick={handleLoginStaffClick} // Open the modal when clicked
            >
                <img src={Key_Icon} className='size-16 transition-colors duration-300 ease-in-out hover:bg-gray-400 rounded-lg' alt='' />
                {showTooltipStaff && (
                    <div className='absolute mb-0 top-[102%] left-[50%] transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-md py-2 px-2 opacity-100 transition-opacity duration-300 ease-in-out'>
                        Staff Login
                    </div>
                )}
            </div>
            {/* Language component */}
            <LanguageSwitcher />

            <div className='h-screen flex relative bg-gray-100 overflow-hidden z-10'>

                {/* White Background */}
                <div className='
                                cloud-bg hidden cloud-test enforce-cloud  lg:flex md:flex xl:flex 2xl:flex bg-[#FFFFFF] relative'>
                    {generateCloudsv1(largeScreenPositions)}
                </div>



                {/* <div className='h-[100vh]' style={{ width: '20vw', height: '100vh' }}> */}
                {/* <div className='h-[100vh] w-[20vw]' >
                    <img
                        src={CloudImagev1}
                        alt='Cloud Image'
                        style={{ width: '100%', height: '', objectFit: 'str' }}
                    />
                </div> */}
                {/* Centered Kela Card */}
                <div className='absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[300px] sm:w-[300px] md:w-[500px] lg:w-[500px] bouncing-div-kela'>
                    <img src={Kela_Card_Icon} alt='Kela card' />
                </div>

                {/* Text Content Here */}
                <div className='pl-[16px] lg:pl-[300px] sm:pl-[200px] flex-1 flex z-40'>
                    <div className='justify-center flex flex-col gap-y-2'>
                        <div className='px-2'>
                            {/* <p className='text-1xl text-[#1D2939] font-bold' style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>TERVETULOA KUULOTESTIIN</p> */}
                            <p className='text-1xl text-[#1D2939] font-bold' style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)' }}>{t('header')}</p>
                        </div>
                        <div>
                            <div className='text-[#344054] text-6xl sm:text-4xl lg:text-8xl md:text-8xl font-bold'>
                                {t('landingpage_header').split('<br />').map((word, index) => (
                                    <React.Fragment key={index}>
                                        {word}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className='mt-4 text-gray-700'>
                            <div className='text-sm sm:text-[16px] md:text-[16px] lg:text-[18px]'>
                                {t('landingpage_description').split('<br />').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                            <br />
                            <button
                                className="bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400"
                                onClick={() => navigate('/loginpage')}
                            >
                                {t('landingpage_button')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for Staff Login */}
            {isModalVisible && (
                <Modal className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg w-96'>
                        <div className='flex items-center py-1 gap-x-1'>
                            <div>
                                <img src={Lock_Icon} alt='Lock Icon' className='size-16' />
                            </div>
                            <div>
                                <h2 className='text-lg font-semibold '>Staff Login</h2>
                            </div>
                        </div>
                        <input
                            type='text'
                            placeholder='Username'
                            value={loginInformationStaff.userName}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, userName: e.target.value })}
                            className='w-full mb-4 px-3 py-2 border rounded-md'
                        />
                        <input
                            type='password'
                            placeholder='Password'
                            value={loginInformationStaff.password}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, password: e.target.value })}
                            className='w-full mb-4 px-3 py-2 border rounded-md'
                        />
                        <div className='flex justify-end'>
                            <button onClick={handleCancel} className='px-4 py-2 bg-gray-400 text-white rounded-md mr-2'>
                                Cancel
                            </button>
                            <button onClick={handleLogin} className='bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md px-4 py-2'>
                                Login
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            <ToastContainer />
        </div>
    );
};

export default LandingPage;
