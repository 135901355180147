// SliderAudiogram.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCompensationFactor } from '../fetchParam'; // Adjust the import based on your file structure
import { ArrowLeft, Help_Icon } from "../../../../assets/Icons";
import isElectron from '../../../../Functions/isElectron';
import './SliderAudiogram.css'; // Import the CSS file

const SliderAudiogram = () => {
    const frequencies = [1000, 2000, 4000, 8000, 14000, 500, 250, 125]; // Add more frequencies as needed
    const [frequencyIndex, setFrequencyIndex] = useState(0);
    const [dbLevel, setDbLevel] = useState(0); // Start from 0 dB
    const [isPlaying, setIsPlaying] = useState(false);
    const [calibrationFactor, setCalibrationFactor] = useState(0); // Get from calibration data if available
    const [compensationFactor, setCompensationFactor] = useState(0); // Get from compensation data
    const [side] = useState(1); // 1 for right ear, -1 for left ear

    const navigate = useNavigate();

    const headphoneModel = 'DD65v2'; // Or get from settings

    const audioContextRef = useRef(null);
    const isStoppingRef = useRef(false); // To handle stopping the sound

    useEffect(() => {
    // Initialize AudioContext
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    // Fetch the compensation factor when the component mounts or frequency changes
    const fetchCompensation = async () => {
        const freq = frequencies[frequencyIndex];
        const comp = await getCompensationFactor(headphoneModel, freq);
        setCompensationFactor(comp || 0);
    };
    fetchCompensation();

    // Cleanup on unmount
    return () => {
        stopSound();
        if (audioContextRef.current) {
        audioContextRef.current.close();
        }
    };
    }, [frequencyIndex]);

    const startSound = () => {
    if (!audioContextRef.current) return;

    setIsPlaying(true);
    isStoppingRef.current = false;

    const now = audioContextRef.current.currentTime;

    // Schedule three beeps
    for (let i = 0; i < 3; i++) {
        const startTime = now + i * 1.0; // Each beep starts 1 second apart
        playBeep(startTime);
    }

    // Set a timeout to reset isPlaying after the beeps have finished
    setTimeout(() => {
        if (!isStoppingRef.current) {
        setIsPlaying(false);
        }
    }, 3 * 1000); // Total duration is 3 seconds
    };

    const playBeep = (startTime) => {
    if (!audioContextRef.current) return;

    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();

    // Calculate frequency and gain
    const frequency = frequencies[frequencyIndex];
    const dbMultiplier = 10 ** ((dbLevel + calibrationFactor + compensationFactor - 80) / 20);

    // Set oscillator properties
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, startTime);

    // Set gain envelope with 45ms rise time
    const riseTime = 0.045; // 45ms
    const beepDuration = 0.5; // 500ms

    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(dbMultiplier, startTime + riseTime);
    gainNode.gain.setValueAtTime(dbMultiplier, startTime + beepDuration - riseTime);
    gainNode.gain.linearRampToValueAtTime(0, startTime + beepDuration);

    // Panning
    const panner = audioContextRef.current.createStereoPanner();
    panner.pan.setValueAtTime(side, startTime);

    // Connect nodes
    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContextRef.current.destination);

    // Start and stop oscillator
    oscillator.start(startTime);
    oscillator.stop(startTime + beepDuration);

    // Clean up
    oscillator.onended = () => {
        oscillator.disconnect();
        gainNode.disconnect();
        panner.disconnect();
    };
    };

    // New function to play single long sound
    const playSingleSound = () => {
    if (!audioContextRef.current) return;

    setIsPlaying(true);
    isStoppingRef.current = false;

    const now = audioContextRef.current.currentTime;
    const duration = 1.5; // 1.5 seconds
    const riseTime = 0.00045; // 0.45ms
    const fallTime = 0.00045; // 0.45ms

    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();

    // Calculate frequency and gain
    const frequency = frequencies[frequencyIndex];
    const dbMultiplier = 10 ** ((dbLevel + calibrationFactor + compensationFactor - 80) / 20);

    // Set oscillator properties
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, now);

    // Set gain envelope with rise and fall times
    gainNode.gain.setValueAtTime(0, now);
    gainNode.gain.linearRampToValueAtTime(dbMultiplier, now + riseTime);
    gainNode.gain.setValueAtTime(dbMultiplier, now + duration - fallTime);
    gainNode.gain.linearRampToValueAtTime(0, now + duration);

    // Panning
    const panner = audioContextRef.current.createStereoPanner();
    panner.pan.setValueAtTime(side, now);

    // Connect nodes
    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContextRef.current.destination);

    // Start and stop oscillator
    oscillator.start(now);
    oscillator.stop(now + duration);

    // Clean up
    oscillator.onended = () => {
        oscillator.disconnect();
        gainNode.disconnect();
        panner.disconnect();
        if (!isStoppingRef.current) {
        setIsPlaying(false);
        }
    };
    };

    const stopSound = () => {
    isStoppingRef.current = true;
    setIsPlaying(false);

    if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    };

    const handleSliderChange = (e) => {
    setDbLevel(parseInt(e.target.value));
    };

    const handleDecreaseVolume = () => {
    setDbLevel((prevDbLevel) => Math.max(prevDbLevel - 5, -10));
    };

    const handleIncreaseVolume = () => {
    setDbLevel((prevDbLevel) => Math.min(prevDbLevel + 5, 100));
    };

    const handleHelpClick = () => {
        navigate("/TestLandingPage"); // Ensure this route exists
        };

        const handleCancelClick = () => {
            navigate("/TestLandingPage");
        };

    const handleNext = () => {
    if (frequencyIndex < frequencies.length - 1) {
        stopSound(); // Stop sound before moving to next frequency
        setFrequencyIndex(frequencyIndex + 1);
    } else {
        // Navigate to /LandingPage when all frequencies are done
        navigate('/TestLandingPage');
    }
    };

    return (




    <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="fixed top-4 left-4 flex items-center space-x-4 z-30">
        {/* "Peruuta" Button with ArrowLeft Icon */}
        <button onClick={handleCancelClick} className="flex items-center space-x-2">
          <span className="flex items-center gap-3 px-6 py-5 text-lg font-semibold bg-white rounded-xl border border-gray-300 shadow-sm text-slate-700 hover:bg-purple-400 transition-all duration-200">
            <img src={ArrowLeft} className="w-6 h-6" alt="Arrow Left" />
            Peruuta
          </span>
        </button>

        {/* Help Icon Button */}
        <button onClick={handleHelpClick} className="flex items-center">
          <img src={Help_Icon} className="w-20 h-20" alt="Help Icon" />
        </button>
      </div>


        {/* Frequency Display */}
        <p className="text-center text-2xl font-semibold mb-4">
        Frequency: {frequencies[frequencyIndex]} Hz
        </p>
        <br>
        </br>
        <br>
        </br>

        {/* Slider Section */}
        <div className="w-full max-w-4xl px-4 flex items-center">
        {/* Decrease Volume Button */}
        <button
            onClick={handleDecreaseVolume}
            className="px-4 py-8 bg-purple-600 text-white rounded-lg mr-4"
        >
            Make it quieter
        </button>

        {/* Slider */}
        <div className="flex-1">
            <input
            type="range"
            min="-10"
            max="100"
            step="5" // Set step to 5 dB intervals
            value={dbLevel}
            onChange={handleSliderChange}
            className="w-full appearance-none cursor-pointer"
            aria-label="Adjust dB level"
            />
            <div className="flex justify-between mt-2">
            <span>-10 dB</span>
            <span>100 dB</span>
            </div>
            <p className="text-center mt-4 text-lg">Current dB Level: {dbLevel} dB</p>
        </div>

        {/* Increase Volume Button */}
        <button
            onClick={handleIncreaseVolume}
            className="px-4 py-8 bg-purple-600 text-white rounded-lg ml-4"
        >
            Make it louder
        </button>
        </div>

        {/* Control Buttons */}
        <div className="flex flex-col items-center gap-4 mt-6">
        <div className="flex gap-4">
            {!isPlaying ? (
            <button
                onClick={startSound}
                className="px-6 py-3 bg-green-500 text-white rounded-lg"
            >
                Three beeps
            </button>
            ) : (
            <button
                onClick={stopSound}
                className="px-6 py-3 bg-red-500 text-white rounded-lg"
            >
                Stop Sound
            </button>
            )}
            <button
            onClick={handleNext}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg"
            disabled={isPlaying} // Disable while playing
            >
            {frequencyIndex < frequencies.length - 1 ? 'Next Frequency' : 'Finish'}
            </button>
        </div>

        {/* New Button to Play Single Long Sound */}
        <button
            onClick={playSingleSound}
            className="px-3 py-3 bg-purple-600 text-white rounded-lg"
            disabled={isPlaying} // Disable while playing
        >
        Single Beep
        </button>
        </div>
    </div>
    );
};

export default SliderAudiogram;