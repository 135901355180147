import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const LineChartD3 = ({ data, width = 900, height = 600, title = "Frequency vs. dB Chart" }) => {
  const svgRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height + 50) // Extra space for x-axis label
      .style("overflow", "visible");

    // Define margins
    const margin = { top: 40, right: 30, bottom: 50, left: 60 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Define x scale with evenly spaced ticks
    const xScale = d3.scaleLinear()
      .domain([0, data.length - 1]) // for equal spacing
      .range([0, chartWidth]);

    // Define y scale
    const yScale = d3.scaleLinear()
      .domain([d3.max(data, d => d.db), d3.min(data, d => d.db) - 5])
      .range([0, chartHeight]);

    // Clear existing content
    svg.selectAll("*").remove();

    // Add group for margins
    const chart = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Add title
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text(title);

    // Add grid lines
    chart.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickSize(-chartWidth).tickFormat(""))
    //   .style("stroke-dasharray", "3,3");

    chart.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(d3.axisBottom(xScale).tickSize(-chartHeight).tickFormat(""))

    //   .style("stroke-dasharray", "3,3");

    // Add axes
    chart.append("g")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(d3.axisBottom(xScale)
        .ticks(data.length)
        .tickFormat((d, i) => data[i].frequency + " Hz"));

    chart.append("g")
      .call(d3.axisLeft(yScale));

    // Add line path
    chart.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", d3.line()
        .x((d, i) => xScale(i)) // Use index for x position to ensure equal spacing
        .y(d => yScale(d.db))
      );

    // Tooltip setup
    const tooltip = d3.select(tooltipRef.current)
      .style("position", "absolute")
      .style("background", "#fff")
      .style("padding", "5px")
      .style("border", "1px solid #ccc")
      .style("border-radius", "4px")
      .style("visibility", "hidden");

    // Add points with hover interaction for tooltip
    chart.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d, i) => xScale(i))
      .attr("cy", d => yScale(d.db))
      .attr("r", 5)
      .attr("fill", "red")
      .style('cursor','pointer')
      .on("mouseover", (event, d) => {
        tooltip.style("visibility", "visible")
          .text(`Frequency: ${d.frequency} Hz, dB: ${d.db}`);
      })
      .on("mousemove", (event) => {
        tooltip.style("top", (event.pageY - 10) + "px")
          .style("left", (event.pageX + 10) + "px");
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // Add x-axis label
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text("Frequency (Hz)");

    // Add y-axis label
    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${margin.left - 40}, ${height / 2}) rotate(-90)`)
      .style("font-size", "12px")
      .text("dB Level");

  }, [data, width, height, title]);

  return (
    <div style={{ position: "relative" }}>
      <svg ref={svgRef}></svg>
      <div ref={tooltipRef}></div>
    </div>
  );
};

export default LineChartD3;
