// CompensationSettingsBox.js

import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify'; // Toast notifications

const CompensationSettingsBox = ({ onModelChange }) => {
  const [headphoneModels, setHeadphoneModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [newModelName, setNewModelName] = useState('');
  const [compensationFactors, setCompensationFactors] = useState({});
  const [compensationData, setCompensationData] = useState({});
 

  const frequencies = [
    '125',
    '250',
    '500',
    '750',
    '1000',
    '2000',
    '3000',
    '4000',
    '6000',
    '8000',
  ];

   // Default options
   const defaultCompensationData = {
    "headphoneModel": {
      "DD65v2": {
        "frequencies": {
          "125": { "compensationFactor": "30.5" },
          "250": { "compensationFactor": "17" },
          "500": { "compensationFactor": "8" },
          "750": { "compensationFactor": "5.5" },
          "1000": { "compensationFactor": "4.5" },
          "2000": { "compensationFactor": "2.5" },
          "3000": { "compensationFactor": "2" },
          "4000": { "compensationFactor": "9.5" },
          "6000": { "compensationFactor": "21" },
          "8000": { "compensationFactor": "21" }
        }
      },
      "defaultModel": {
        "frequencies": {
          "125": { "compensationFactor": "28" },
          "250": { "compensationFactor": "15" },
          "500": { "compensationFactor": "7" },
          "750": { "compensationFactor": "4.5" },
          "1000": { "compensationFactor": "4.0" },
          "2000": { "compensationFactor": "2.0" },
          "3000": { "compensationFactor": "1.5" },
          "4000": { "compensationFactor": "8.0" },
          "6000": { "compensationFactor": "19" },
          "8000": { "compensationFactor": "19" }
        }
      }
    }
  }

  const initializeDefaultData = () => {
    setCompensationData(defaultCompensationData);
    const models = Object.keys(defaultCompensationData.headphoneModel || {});
    setHeadphoneModels(models);
  };

  useEffect(() => {
    loadCompensationData();
  }, []);

  const loadCompensationData = async () => {
    try {
      if (window.electron && window.electron.loadAudiometryCompensation) {
        const response = await window.electron.loadAudiometryCompensation();
        if (response.success) {
          const data = response.data;
          setCompensationData(data);
          const models = Object.keys(data.headphoneModel || {});
          setHeadphoneModels(models);
        } else {
          toast.error('Failed to load compensation data:', response.message);
          // Use default data if loading fails
          initializeDefaultData();
        }
      } else {
        // Not running in Electron, load from localStorage
        const storedData = localStorage.getItem('compensationData');
        if (storedData) {
          const data = JSON.parse(storedData);
          setCompensationData(data);
          const models = Object.keys(data.headphoneModel || {});
          setHeadphoneModels(models);
        } else {
          // No data in localStorage, initialize with default data
          initializeDefaultData();
        }
      }
    } catch (error) {
      toast.error('Error loading compensation data:', error);
      // Use default data in case of error
      initializeDefaultData();
    }
  };

  const handleModelSelectChange = (e) => {
    const modelName = e.target.value;
    setSelectedModel(modelName);
    setNewModelName('');
    if (compensationData.headphoneModel && compensationData.headphoneModel[modelName]) {
      const frequenciesData = compensationData.headphoneModel[modelName].frequencies || {};
      const loadedCompensationFactors = {};
      frequencies.forEach(freq => {
        loadedCompensationFactors[freq] = frequenciesData[freq]?.compensationFactor || '';
      });
      setCompensationFactors(loadedCompensationFactors);
    } else {
      setCompensationFactors({});
    }
    if (onModelChange) {
      onModelChange(modelName, compensationData);
    }
  };

  const handleNewModelNameChange = (e) => {
    setNewModelName(e.target.value);
    setSelectedModel('');
    setCompensationFactors({});
  };

  const handleCompensationFactorChange = (frequency, value) => {
    setCompensationFactors(prevFactors => ({
      ...prevFactors,
      [frequency]: value,
    }));
  };

  const handleSaveCompensation = async () => {
    const modelName = newModelName || selectedModel;
    if (!modelName) {
      toast.error('Please enter a new headphone model name or select an existing one.');
      return;
    }

    const frequenciesData = {};
    frequencies.forEach(freq => {
      const factor = compensationFactors[freq] || '0';
      frequenciesData[freq] = { compensationFactor: factor };
    });

    const updatedCompensationData = { ...compensationData };

    if (!updatedCompensationData.headphoneModel) {
      updatedCompensationData.headphoneModel = {};
    }

    updatedCompensationData.headphoneModel[modelName] = {
      frequencies: frequenciesData,
    };

    try {
      if (window.electron && window.electron.saveAudiometryCompensation) {
        const dataToSave = {
          modelName,
          frequencies: frequenciesData,
        };
        const response = await window.electron.saveAudiometryCompensation(dataToSave);
        if (response.success) {
          toast.success('Compensation data saved successfully');
          await loadCompensationData();
          if (newModelName) {
            setSelectedModel(newModelName);
            setNewModelName('');
          }
          if (onModelChange) {
            onModelChange(modelName, updatedCompensationData);
          }
        } else {
          toast.error('Failed to save compensation data:', response.message);
        }
      } else {
        // Not running in Electron, save to localStorage
        localStorage.setItem('compensationData', JSON.stringify(updatedCompensationData));
        setCompensationData(updatedCompensationData);
        const models = Object.keys(updatedCompensationData.headphoneModel || {});
        setHeadphoneModels(models);
        if (newModelName) {
          setSelectedModel(newModelName);
          setNewModelName('');
        }
        if (onModelChange) {
          onModelChange(modelName, updatedCompensationData);
        }
        toast.success('Compensation data saved to localStorage');
      }
    } catch (error) {
      toast.error('Error saving compensation data:', error);
    }
  };
  return (
    <div className="border border-gray-300 p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Headphone Calibration</p>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">?</button>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between">
          <label>Valitse kuulokemalli:</label>
          <select
            className="border w-1/3"
            id="headphoneModelSelect"
            value={selectedModel}
            onChange={handleModelSelectChange}
          >
            <option value="">--Valitse--</option>
            {headphoneModels.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-between">
          <label>Uusi kuulokemalli:</label>
          <input
            className="border w-1/3"
            type="text"
            id="calibrationName"
            value={newModelName}
            onChange={handleNewModelNameChange}
          />
        </div>
        <div className="flex flex-col space-y-2">
          {frequencies.map(freq => (
            <div key={freq} className="flex justify-between">
              <label>{freq} Hz:</label>
              <input
                className="border w-1/3"
                type="number"
                value={compensationFactors[freq] || ''}
                step="0.000001"
                onChange={(e) => handleCompensationFactorChange(freq, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSaveCompensation}
          >
            Tallenna kalibrointi
          </button>
        </div>
      </div>
      {/* Toast container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default CompensationSettingsBox;
