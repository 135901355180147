import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SpeechDBChart = ({ questionNumbers, speechDBData }) => {
    const data = {
        labels: questionNumbers, // X-axis values (question numbers)
        datasets: [
            {
                label: 'Speech dB vs Question Number',
                data: speechDBData, // Y-axis values (speech dB)
                borderColor: '#E94E77', // Line color (attractive color for the line)
                backgroundColor: '#FFFFFF', // Marker color (attractive blue)
                pointBorderColor: '#2C3E50', // Border color for markers
                pointBackgroundColor: '#FFFFFF', // Background color for markers
                pointBorderWidth: 2, // Marker border thickness
                pointRadius: 8, // Marker size for visibility
                tension: 0.5, // Line smoothness (similar to Plotly's spline)
                borderWidth: 3, // Line thickness
                showLine: true, // Show the connecting line
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend if not needed
            },
            title: {
                display: true,
                text: 'Speech dB vs Question Number',
                font: {
                    family: 'Arial, sans-serif',
                    size: 22,
                    color: '#2C3E50', // Darker title color
                },
                align: 'start',
                padding: { top: 10, bottom: 10 },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Question Number',
                    font: {
                        family: 'Arial, sans-serif',
                        size: 18,
                        color: '#34495E', // X-axis title color
                    },
                },
                grid: {
                    color: '#D3D3D3', // Subtle gridline color
                },
                ticks: {
                    color: '#34495E', // Tick color
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Speech dB',
                    font: {
                        family: 'Arial, sans-serif',
                        size: 18,
                        color: '#34495E', // Y-axis title color
                    },
                },
                grid: {
                    color: '#D3D3D3', // Subtle gridline color
                },
                ticks: {
                    color: '#34495E', // Tick color
                },
                min: Math.min(...speechDBData) - 5, // Set minimum value for y-axis (adjust as needed)
                max: Math.max(...speechDBData) + 5, // Dynamically set max to fit the data
            },
        },
        layout: {
            padding: {
                top: 40,
                bottom: 60,
                left: 60,
                right: 30,
            },
        },
    };

    return (
        <div className="w-full h-auto border border-black rounded-lg" style={{ backgroundColor: '#FFFFFF' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default SpeechDBChart;
