import React from 'react';

function QuestionTitle({ text }) {
    return (
        <header className="flex flex-col  font-bold text-center text-slate-700 w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
                <h1 className="text-1xl md:text-lg lg:text-4xl uppercase tracking-[.8px] max-md:max-w-full">
                    KuuloKysely
                </h1>
                <h2 className="flex justify-center py-4 text-2xl md:text-lg lg:text-4xl box-border w-[400px] sm:w-[400px] md:w-[400px] lg:w-[900px]  overflow-x-auto">
                    {text}
                </h2>
            </div>
        </header>
    );
}

export default QuestionTitle;
