import { createSlice } from "@reduxjs/toolkit";
/* Making the user Variable globlally accessible */

export const selectedPatientSlice = createSlice({
    name: "selectedpatient",
    initialState: {
        /* This is our normal patient */
        selectedPatient: null,
    },
    reducers: {
        setSelectedPatient: (state, action) => {
            state.selectedPatient = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSelectedPatient } = selectedPatientSlice.actions;