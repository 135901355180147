const testData = [
    { date: '2024-10-01', device: 'Headphone', value: -2.01 },
    { date: '2024-10-01', device: 'Speaker', value: -5.89 },
    { date: '2024-10-01', device: 'Hearing Aid', value: -10.46 },
    { date: '2024-10-02', device: 'Headphone', value: -1.24 },
    { date: '2024-10-02', device: 'Speaker', value: -8.04 },
    { date: '2024-10-02', device: 'Hearing Aid', value: -12.66 },
    { date: '2024-10-03', device: 'Headphone', value: -4.55 },
    { date: '2024-10-03', device: 'Speaker', value: -7.98 },
    { date: '2024-10-03', device: 'Hearing Aid', value: -14.12 },
    { date: '2024-10-04', device: 'Headphone', value: -6.89 }
];

const audiogramData = [
    { frequency: 125, db: 30 },
    { frequency: 250, db: 20 },
    { frequency: 500, db: 25 },
    { frequency: 1000, db: 15 },
    { frequency: 2000, db: 22 },
    { frequency: 3000, db: 18 },
    { frequency: 4000, db: 27 },
    { frequency: 6000, db: 32 },
];

const data = [
    { frequency: 125, db: 30 },
    { frequency: 250, db: 20 },
    { frequency: 500, db: 25 },
    { frequency: 1000, db: 15 },
    { frequency: 2000, db: 22 },
    { frequency: 3000, db: 18 },
    { frequency: 4000, db: 27 },
    { frequency: 6000, db: 32 },
];


const data1 = [
    { frequency: 125, db: 30 },
    { frequency: 250, db: 20 },
    { frequency: 500, db: 25 },
    { frequency: 1000, db: 15 },
    { frequency: 2000, db: 22 },
    { frequency: 3000, db: 18 },
    { frequency: 4000, db: 27 },
    { frequency: 6000, db: 32 },
];

const data2 = [
    { frequency: 125, db: 25 },
    { frequency: 250, db: 28 },
    { frequency: 500, db: 20 },
    { frequency: 1000, db: 18 },
    { frequency: 2000, db: 19 },
    { frequency: 3000, db: 22 },
    { frequency: 4000, db: 26 },
    { frequency: 6000, db: 30 },
];


const adata1 = [
    { frequency: 250, db: 20 },   // Left ear
    { frequency: 500, db: 25 },
    { frequency: 1000, db: 30 },
    { frequency: 2000, db: 40 },
    { frequency: 4000, db: 55 },
    { frequency: 8000, db: 60 }
];

const adata2 = [
    { frequency: 250, db: 15 },   // Right ear
    { frequency: 500, db: 20 },
    { frequency: 1000, db: 35 },
    { frequency: 2000, db: 50 },
    { frequency: 4000, db: 65 },
    { frequency: 8000, db: 70 }
];


export { testData, audiogramData, data1, data2, adata1, adata2 };