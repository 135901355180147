import React, { useEffect, useState } from 'react';
import { LockBlueIcon, LogoMark, SideBarBellIcon, SidebarChevronDownIcon, SideBarHomeIcon, SideBarLogOutIcon, SideBarSettingsIcon, SidebarTukiportaliIcon, SideBarUserIcon, SideBarUserProfileIcon } from '../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import './Practioner.css';
import { setPractionerOpen } from '../../Slices/sideBarSlice';
import isValidJSON from '../../Functions/IsValidJSON';
import FullPageLoader from '../../utils/Loader/FullPageLoader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PractionerSidebar = () => {
    // Retrieve the state of practionerOpen from Redux
    const practionerOpen = useSelector((state) => state.sidebrState.practionerOpen);
    const practionerOpenSmallScreen = useSelector((state) => state.sidebrState.practionerOpenSmallScreen);
    const tokenFromStorage = localStorage.getItem('token');
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    // Local state to manage user info, search input, animations, and sidebar open/close states
    const [user, setUser] = useState({
        firstName: "Pekka",
        lastName: 'Potilas',
        id: '131052-308T'
    });

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [isAnimating, setIsAnimating] = useState(false); // for animations
    const [isSideBarOpening, setIsSideBarOpening] = useState(false);
    const [isSideBarClosing, setIsSideBarClosing] = useState(false);
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    // Sidebar items data
    const sidebarItems = [
        { icon: SideBarBellIcon, label: 'Notifications' },
        { icon: SideBarUserIcon, label: 'Patients' },
        // Add more items here as needed
    ];
    const sidebarBottomItems = [
        { icon: SidebarTukiportaliIcon, label: 'Tukiportaali' },
        { icon: SideBarSettingsIcon, label: 'Asetukset' },
        // Add more items here as needed
    ];
    // Debug logging of sidebar open states
    console.log({ isSideBarOpen, practionerOpen });

    /* Function to open the sidebar */
    const openSideBar = () => {
        setIsSideBarOpening(true);
        dispatch(setPractionerOpen(true)); // Update Redux state to open sidebar

        // Timeout to complete the open animation
        setTimeout(() => {
            setIsSideBarOpen(true);
            setIsSideBarOpening(false);
        }, 300);
    };

    /* Function to close the sidebar */
    const closeSideBar = () => {
        setIsSideBarClosing(true);
        dispatch(setPractionerOpen(false)); // Update Redux state to close sidebar

        // Timeout to complete the close animation
        setTimeout(() => {
            setIsSideBarOpen(false);
            setIsSideBarClosing(false);
        }, 300);
    };


    const handleLogOut = () => {
        /* Log Out */
        return navigate('/MainSettings');

    };


    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                if (!token) {
                    setLoading(false);
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/access/getUserRole`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (response.data && response.data.role) {
                    setUser(response.data.info);
                } else {
                    setUser(null);
                }
            } catch (error) {
                console.error("Error fetching user role:", error);
                setUser(null);
            } finally {
                /* remove this in production environment */
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }
        };
        fetchUserRole();
    }, [token]);

    if (loading) {
        return <FullPageLoader />; // Replace with a proper loading component
    }

    return (
        <>
            <section className="gap-6 relative cursor-pointer hidden md:flex lg:flex">
                {/* Sidebar container with conditional classes for animations */}
                <div className={` ${practionerOpen ? 'open' : 'closed'} ${isAnimating ? 'animating' : ''} rounded-r-[4px] bg-white border-r-[2px] border-gray-200 h-[96vh] overflow-auto `}
                    style={{
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" // Custom shadow style
                    }}>

                    {/* Top section of sidebar with logo and search */}
                    <div className="py-4 px-8 gap-y-6 flex flex-col">
                        <div className='flex gap-x-1 items-center cursor-pointer'>
                            {/* Logo that toggles sidebar open/close */}
                            <img src={LogoMark} alt='Logo' onClick={isSideBarOpen ? closeSideBar : openSideBar} />
                            {/* Sidebar title that shows/hides with sidebar */}
                            <p className={`font-bold text-4xl ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`} onClick={closeSideBar}>TrueHear</p>
                        </div>
                        {/* Search bar input field */}
                        <div className={`w-full ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`}>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)} // Update search state on input change
                                placeholder="Search patients..."
                                className={`p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition-all w-full ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`}
                            />
                        </div>
                    </div>

                    {/* Sidebar content showing user information */}
                    <div className={`py-4 px-6 gap-y-2 flex flex-col text-gray-700 ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}  h-[80vh]`}>
                        {/* Display user information with lock icon */}
                        {user && <div className='py-4 rounded-md px-4 bg-blue-50'>
                            <div className='flex items-center justify-between'>
                                <p className="font-bold text-2xl">
                                    {user.firstName || '...'} {' '} {user.lastName || '..'}
                                </p>
                                <img src={LockBlueIcon} alt='Lock Icon' className='size-7' />
                            </div>
                            <p className="text-1xl">{user.socialSecurityNumber}</p>
                        </div>}

                        {/* Dynamic Sidebar Links */}
                        {sidebarItems.map((item, index) => (
                            <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4'>
                                    <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    <p className='text-1xl lg:text-2xl'>{item.label}</p>
                                </div>
                                <img src={SidebarChevronDownIcon} alt='Chevron Down Icon' className='size-7' />
                            </div>
                        ))}

                        <div className='flex-1' />
                        {/* The Settings and User Profile */}
                        {sidebarBottomItems.map((item, index) => (
                            <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4'>
                                    <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    <p className='text-1xl lg:text-2xl'>{item.label}</p>
                                </div>
                                <img src={SidebarChevronDownIcon} alt='Chevron Down Icon' className='size-7' />
                            </div>
                        ))}
                        <div className='border border-b-1 border-gray-150' />
                        {/* User Profile */}
                        <div className='duration-300 ease-in-out hover:bg-gray-100 rounded-md flex py-4 px-2 items-center gap-x-2'>
                            {/* Image */}
                            <div>
                                <img src={SideBarUserProfileIcon} alt='p' />
                            </div>
                            {/* User name and email */}
                            {user && <div className='w-full'>
                                <div className='flex items-center justify-between' onClick={(e) => handleLogOut()}>
                                    <p className='text-2xl font-bold'>

                                        {user.firstName}{' '} {user.lastName}
                                    </p>
                                    <img src={SideBarLogOutIcon} alt='p' className='size-10' />
                                </div>
                                <p className='text-1xl'>
                                    {user.email}
                                </p>
                            </div>}

                        </div>
                    </div>

                    {/* Content when sidebar is closed */}
                    {!isSideBarOpen && (
                        <div className="py-4 px-6 gap-y-4 flex flex-col text-gray-700 overflow-auto h-[80vh]">
                            {/* User info display with centered lock icon */}
                            <div className='py-4 rounded-md px-4 bg-blue-50'>
                                <div className='flex items-center justify-center'>
                                    <img src={LockBlueIcon} alt='Lock Icon' className='size-7' />
                                </div>
                            </div>
                            {/* Dynamic Sidebar Links      */}
                            {sidebarItems.map((item, index) => (
                                <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                    <div className='flex items-center gap-x-4 w-full justify-center'>
                                        <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    </div>
                                </div>
                            ))}

                            <div className='flex-1' />
                            {/* Dynamic Sidebar Links      */}
                            {sidebarBottomItems.map((item, index) => (
                                <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                    <div className='flex items-center gap-x-4 w-full justify-center'>
                                        <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    </div>
                                </div>
                            ))}

                            <div className='border border-b-1 border-gray-150' />
                            {/* Logout */}
                            <div className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4 w-full justify-center' onClick={(e) => handleLogOut()}>
                                    <img src={SideBarLogOutIcon} alt='p' />
                                </div>
                            </div>
                            {/* UserProfile */}
                            <div className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4 w-full justify-center'>
                                    <img src={SideBarUserProfileIcon} alt='p' />
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </section>

            {/* For Small Screen Size */}
            <section className="gap-6 relative cursor-pointer flex md:hidden lg:hidden">
                {/* Sidebar container with conditional classes for animations */}
                <div className={` ${isAnimating ? 'animating' : ''} rounded-r-[4px] bg-white border-r-[2px] border-gray-200 h-[96vh] overflow-auto  fixed top-0  z-50 transition-all duration-500 ${practionerOpenSmallScreen ? "left-0" : "-left-[120px]"} ${practionerOpen ? 'open' : 'closed'} 
           
           `}
                    style={{
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" // Custom shadow style
                    }}>

                    {/* Top section of sidebar with logo and search */}
                    <div className="py-4 px-8 gap-y-6 flex flex-col">
                        <div className='flex gap-x-1 items-center cursor-pointer'>
                            {/* Logo that toggles sidebar open/close */}
                            <img src={LogoMark} alt='Logo' onClick={isSideBarOpen ? closeSideBar : openSideBar} />
                            {/* Sidebar title that shows/hides with sidebar */}
                            <p className={`font-bold text-4xl ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`} onClick={closeSideBar}>TrueHear</p>
                        </div>
                        {/* Search bar input field */}
                        <div className={`w-full ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`}>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)} // Update search state on input change
                                placeholder="Search patients..."
                                className={`p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition-all w-full ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}`}
                            />
                        </div>
                    </div>

                    {/* Sidebar content showing user information */}
                    <div className={`py-4 px-6 gap-y-2 flex flex-col text-gray-700 ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isSideBarOpening ? 'sidebar-opening-content' : ''} ${isSideBarClosing ? 'sidebar-closing-content' : ''}  h-[80vh]`}>
                        {/* Display user information with lock icon */}
                        {user && <div className='py-4 rounded-md px-4 bg-blue-50'>
                            <div className='flex items-center justify-between'>
                                <p className="font-bold text-2xl">
                                    {user.firstName} {' '} {user.lastName}
                                </p>
                                <img src={LockBlueIcon} alt='Lock Icon' className='size-7' />
                            </div>
                            <p className="text-1xl">{user.socialSecurityNumber}</p>
                        </div>}

                        {/* Dynamic Sidebar Links */}
                        {sidebarItems.map((item, index) => (
                            <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4'>
                                    <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    <p className='text-1xl lg:text-2xl'>{item.label}</p>
                                </div>
                                <img src={SidebarChevronDownIcon} alt='Chevron Down Icon' className='size-7' />
                            </div>
                        ))}

                        <div className='flex-1' />
                        {/* The Settings and User Profile */}
                        {sidebarBottomItems.map((item, index) => (
                            <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4'>
                                    <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    <p className='text-1xl lg:text-2xl'>{item.label}</p>
                                </div>
                                <img src={SidebarChevronDownIcon} alt='Chevron Down Icon' className='size-7' />
                            </div>
                        ))}
                        <div className='border border-b-1 border-gray-150' />
                        {/* User Profile */}
                        <div className='duration-300 ease-in-out hover:bg-gray-100 rounded-md flex py-4 px-2 items-center gap-x-2'>
                            {/* Image */}
                            <div>
                                <img src={SideBarUserProfileIcon} alt='p' />
                            </div>
                            {/* User name and email */}
                            <div className='w-full' onClick={(e) => handleLogOut()}>
                                <div className='flex items-center justify-between '>
                                    <p className='text-2xl font-bold'>

                                        {user.firstName}{' '} {user.lastName}
                                    </p>
                                    <img src={SideBarLogOutIcon} alt='p' className='size-10' />
                                </div>
                                <p className='text-1xl'>
                                    {user.email}
                                </p>
                            </div>

                        </div>
                    </div>

                    {/* Content when sidebar is closed */}
                    {!isSideBarOpen && (
                        <div className="py-4 px-6 gap-y-4 flex flex-col text-gray-700 overflow-auto h-[80vh]">
                            {/* User info display with centered lock icon */}
                            <div className='py-4 rounded-md px-4 bg-blue-50'>
                                <div className='flex items-center justify-center'>
                                    <img src={LockBlueIcon} alt='Lock Icon' className='size-7' />
                                </div>
                            </div>
                            {/* Dynamic Sidebar Links      */}
                            {sidebarItems.map((item, index) => (
                                <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                    <div className='flex items-center gap-x-4 w-full justify-center'>
                                        <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    </div>
                                </div>
                            ))}

                            <div className='flex-1' />
                            {/* Dynamic Sidebar Links      */}
                            {sidebarBottomItems.map((item, index) => (
                                <div key={index} className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                    <div className='flex items-center gap-x-4 w-full justify-center'>
                                        <img src={item.icon} alt={`${item.label} Icon`} className='size-7' />
                                    </div>
                                </div>
                            ))}

                            <div className='border border-b-1 border-gray-150' />
                            {/* Logout */}
                            <div className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md' onClick={(e) => handleLogOut()}>
                                <div className='flex items-center gap-x-4 w-full justify-center'>
                                    <img src={SideBarLogOutIcon} alt='p' />
                                </div>
                            </div>
                            {/* UserProfile */}
                            <div className='py-4 px-2 flex items-center justify-between transition duration-300 ease-in-out hover:bg-gray-100 rounded-md'>
                                <div className='flex items-center gap-x-4 w-full justify-center'>
                                    <img src={SideBarUserProfileIcon} alt='p' />
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default PractionerSidebar;
