
class AudioClass {
    constructor() {
        this.defaultDB = 65.00;
        this.defaultSoundNoise = this.defaultDB;
        this.defaultSpeechNoise = this.defaultDB;
    }
    /* Convert decibels to gain value */
    calculateGainFromDecibelsWithThreshold = (decibels, threshold = 80) => {
        return Math.pow(10, (decibels - threshold) / 20);
    };
    /* Function to set defaults */
    setDefaults = (decibels = 65.00) => {
        this.defaultDB = decibels;
        this.defaultSoundNoise = this.defaultDB;
        this.defaultSpeechNoise = this.defaultDB;
    };
    setDefaultSoundNoise = (defaultSoundDecibel = 65.00) => {
        this.defaultSoundNoise = defaultSoundDecibel;
    };

    calculateArithmeticSum = (n = 0, a = 2, d = 0) => {
        return (n / 2) * (2 * a + (n - 1) * d);
    };
    calculateArithmeticTerm = (n = 0, a = 2, d = 0) => {
        return a + (n - 1) * d;
    };

}

const audioObject = new AudioClass();
export { audioObject };